import { CheckCircle, Error, Info, Warning } from "@mui/icons-material";
import { Box, Button, Modal, Paper, Stack, Typography } from "@mui/material";
import useStyles from "./styles";

export default function InternoModalConfirmacaoSaida(params) {
  const styles = useStyles();
  const {
    openConfirmacao = false,
    setOpenConfirmacao = () => {},
    confirmLoad = false,
    execute = () => {},
    severity = "",
    contentText = "",
    confirmText = "",
    headerText = "",
  } = params;

  return (
    <Modal
      open={openConfirmacao}
      onClose={() => {
        setOpenConfirmacao(false);
      }}
      sx={styles.modal}
    >
      <Paper sx={styles.paper}>
        {/* <Box sx={styles.paperBox}> */}
        {/* Header */}
        <Box sx={styles.header}>
          {severity == "warning" ? (
            <Warning sx={styles.headerIcon} />
          ) : severity == "info" ? (
            <Info sx={styles.headerIcon} />
          ) : severity == "success" ? (
            <CheckCircle sx={styles.headerIcon} />
          ) : (
            <Error sx={styles.headerIcon} />
          )}
          <Typography variant="title" sx={styles.headerText}>
            {headerText ? headerText : "Você está saíndo da página."}
          </Typography>
        </Box>
        {/* Content */}
        <Box sx={styles.content}>
          <Typography sx={styles.contentText}>
            {contentText
              ? contentText
              : "Alterações não salvas serão perdidas, deseja continuar?"}
          </Typography>
        </Box>
        {/* Buttons */}
        <Box sx={styles.boxButtons}>
          <Button
            onClick={() => {
              setOpenConfirmacao(false);
            }}
            variant="outlined"
            sx={{ fontWeight: "bold" }}
          >
            CANCELAR
          </Button>
          <Button
            onClick={execute}
            disabled={confirmLoad}
            variant="contained"
            sx={{
              bgcolor:
                severity == "error"
                  ? "chip.pending"
                  : severity == "warning"
                  ? "secondary.main"
                  : severity == "info"
                  ? "chip.waitingReview"
                  : severity == "success"
                  ? "success.main"
                  : "chip.pending",
              color: "#ffffff",
            }}
          >
            {confirmText ? confirmText : "CONFIRMAR"}
          </Button>
        </Box>
        {/* </Box> */}
      </Paper>
    </Modal>
  );
}
