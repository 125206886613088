const styles = (theme) => ({
  container: { display: "flex", flexDirection: "column", width: 1, gap: 3 },
  header: {
    display: "flex",
    flexDirection: "row",
    width: 1,
    justifyContent: "space-between",
  },
  constentBox: { position: "relative" },
  IconButton: { position: "absolute", top: 2, right: 2 },
});

export default styles;
