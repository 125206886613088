import { io } from "socket.io-client";

// "undefined" means the URL will be computed from the `window.location` object
if (window.location.pathname === "/interno/home") {
}
const URL =
  window?.config?.REACT_APP_BASE_URL || process.env.REACT_APP_BASE_URL || "";
//console.log("TOKEN", JSON.parse(localStorage.getItem("user"))?.token || "");

export const socketInterno = io(URL + "/interno", {
  reconnection: true,
  autoConnect: false,
  /*  auth: {
    token: JSON.parse(localStorage.getItem("user"))?.token || "",
  }, */
});

export const socketCliente = io(URL + "/cliente", {
  reconnection: true,
  autoConnect: false,
  auth: {
    token: JSON.parse(localStorage.getItem("userCliente"))?.token || "",
  },
});

export const connectSocket = (socket, token) => {
  socket.auth = { token };
  socket.connect();
};
