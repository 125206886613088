import { Add, Delete } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  IconButton,
  Paper,
  Snackbar,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import api from "../../../../services/api";
import { useParams } from "react-router-dom";

export default function Wanguard() {
  const [editGroupIds, setEditGroupIds] = useState(false);
  const [groupIds, setGroupIds] = useState([]);
  const [groupdIdManipulation, setGroupIdsManipulation] = useState([]);
  const [snackbar, setSnackbar] = useState(null);
  const [errors, setErrors] = useState({});
  const clienteId = useParams()?.id || "";
  const theme = useTheme();

  useEffect(() => {
    if (groupIds) {
      setGroupIdsManipulation(groupIds);
    }
  }, [groupIds]);

  useEffect(() => {
    handleApiGetGroupIds();
  }, []);

  async function handleApiGetGroupIds() {
    try {
      const response = await api.post("/interno/wanguard/findManyGroupIds", {
        clienteId,
      });

      setGroupIds(response.data);
    } catch (error) {
      setSnackbar({
        children: `Error:${
          error?.message || "Não foi possível se buscar as groupIds"
        } `,
        severity: "error",
      });
    }
  }

  async function handleApiUpdateGroupIds() {
    try {
      const response = await api.put("/interno/wanguard/updateGroupIds", {
        groupIds: groupdIdManipulation,
        clienteId,
      });
      setGroupIdsManipulation(response.data);
      setEditGroupIds(false);
      setSnackbar({
        children: "Comunidades atualizadas com sucesso",
        severity: "success",
      });
    } catch (error) {
      setSnackbar({
        children: `Error:${
          error?.message || "Não foi possível atualizar as groupIds"
        } `,
        severity: "error",
      });
    }
  }

  function deleteGroupId(index) {
    const array = [...groupdIdManipulation];
    array.splice(index, 1);
    setGroupIdsManipulation(array);
  }

  function addGroupId() {
    const array = [...groupdIdManipulation];
    array.push({ id: parseInt(Math.random() * 1000000), nome: "" });
    setGroupIdsManipulation(array);
  }

  function validateFields() {
    const newErrors = {};
    groupdIdManipulation.forEach((groupId, index) => {
      if (groupId.nome.trim() === "") {
        newErrors[index] = true;
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }

  function handleSave() {
    if (validateFields()) {
      handleApiUpdateGroupIds(groupdIdManipulation);
    }
  }

  return (
    <Paper
      sx={{
        padding: "21px",
        display: "flex",
        flexDirection: "column",
        width: "100%",
        gap: "13px",
        bgcolor:
          theme.palette.mode === "dark" ? "transparent" : "background.paper",
      }}
      variant={theme.palette.mode === "dark" ? "outlined" : "elevation"}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography sx={{ fontSize: "21px" }}>
          Configurações de IPs Group
        </Typography>
        {editGroupIds && (
          <Button variant="outlined" endIcon={<Add />} onClick={addGroupId}>
            Adicionar IP Group
          </Button>
        )}
      </Box>
      <Paper
        sx={{
          padding: "21px",
          display: "flex",
          flexDirection: "column",
          gap: "13px",
          bgcolor: "transparent",
        }}
        variant="outlined"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "13px",
            flexWrap: "wrap",
            alignItems: "flex-start",
          }}
        >
          {groupdIdManipulation.map((groupId, index) => (
            <Box
              key={groupId.id}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <TextField
                variant="filled"
                value={groupId.nome}
                label="IP Group"
                onChange={(e) => {
                  const array = [...groupdIdManipulation];
                  array[index].nome = e.target.value;
                  setGroupIdsManipulation(array);
                  setErrors((prev) => ({ ...prev, [index]: false }));
                }}
                size="small"
                autoComplete="off"
                error={!!errors[index]}
                helperText={errors[index] ? "Este campo é obrigatório" : ""}
                disabled={!editGroupIds}
                sx={{ width: "230px" }}
              />
              {editGroupIds ? (
                <IconButton onClick={() => deleteGroupId(index)}>
                  <Delete />
                </IconButton>
              ) : (
                <Box sx={{ width: "40px" }}></Box>
              )}
            </Box>
          ))}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "13px",
            justifyContent: "flex-end",
          }}
        >
          {!editGroupIds ? (
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                setEditGroupIds(true);
                setGroupIds(groupdIdManipulation);
              }}
            >
              Editar
            </Button>
          ) : (
            <>
              <Button
                variant="outlined"
                size="small"
                onClick={() => {
                  setEditGroupIds(false);
                  setGroupIdsManipulation(groupIds);
                  setErrors({});
                }}
              >
                Cancelar
              </Button>
              <Button variant="contained" size="small" onClick={handleSave}>
                Salvar
              </Button>
            </>
          )}
        </Box>
      </Paper>
      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
    </Paper>
  );
}
