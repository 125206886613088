const styles = (props) => ({
  container: {
    minWidth: "100vw",
    minHeight: "100vh",
    display: "flex",
    flexDirection: "row",
  },

  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    flexGrow: 1,
    justifyContent: "center",
    gap: "16px",
    padding: "16px",
    position: "relative",
  },
  buttonNavigate: {
    position: "absolute",
    top: "25px",
    right: "25px",
  },
  boxImg: {
    backgroundRepeat: "no-repeat",
    borderRadius: "0px!important",
    width: "60vw",
    height: "100vh",
    minWidth: "300px",
    minHeight: "500px",
    display: { xs: "none", md: "flex" },
    justifyContent: "center",
    alignContent: "center",
  },
  img: { maxWidth: "500px", height: "auto", width: "80%" },
  textfield: { width: "80%", maxWidth: "500px" },
  button: { width: "80%", maxWidth: "300px", minWidth: "250px" },
  boxForm: { display: "flex", flexDirection: "column", gap: 1, width: "700px" },
  boxRecover: {},
  buttonRecover: { color: "#5F54FD" },
});

export default styles;
