import { IconButton } from "@mui/material";
import { border, display, width } from "@mui/system";

const styles = (props) => ({
  appBar: { top: 0, display: "flex" },
  toolbar: {
    width: "100%",
    height: 80,
    display: "flex",
    justifyContent: "space-between",
  },
  logo: {
    width: "200px",
    height: "auto",
    marginTop: " 7px",
  },
  imgLogo: { width: "200px", height: "auto" },
  IconButtonLogo: {
    padding: 0,
    borderRadius: 0,
    width: "220px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  main: {
    flexGrow: 1,
    p: 3,
    borderTopLeftRadius: 8,
  },
});

export default styles;
