import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  Legend,
  ReferenceLine,
} from "recharts";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import { Box, Typography, useTheme, Stack } from "@mui/material";

/* const calculatePercentile = (data, key, percentile) => {
  const sortedData = data.map((item) => item[key]).sort((a, b) => a - b);
  const index = Math.floor((percentile / 100) * sortedData.length);
  return sortedData[index];
}; */

const LineChartComponent = ({
  data = [],
  type = "limpo",
  range = {},
  maxValuebps = 0,
}) => {
  const theme = useTheme();

  const formatBytes = (bytes) => {
    if (bytes === 0) return "0 B";
    const k = 1024;
    const sizes =
      range?.unidade === "bps"
        ? ["bps", "Kbps", "Mbps", "Gbps", "Tbps", "Pbps", "Ebps"]
        : ["pps", "Kpps", "Mpps", "Gpps", "Tpps", "Ppps", "Epps"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
  };

  // const percentile95 = calculatePercentile(data, "cleanTraffic", 95);
  return (
    <ResponsiveContainer width="100%">
      <AreaChart
        data={data}
        margin={{
          top: 20,
          right: 0,
          left: 10,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="date"
          style={{ fontSize: "12px", fontWeight: 500 }}
          tick={{ fill: theme.palette.primary.main, opacity: 0.7 }}
        />
        <YAxis
          tickFormatter={formatBytes}
          interval={0}
          domain={[0, maxValuebps]}
          tick={{ fill: theme.palette.primary.main, opacity: 0.7 }}
          style={{ fontSize: "12px", fontWeight: 500 }}
        />
        <Tooltip
          content={({ active, payload, label }) => {
            if (active && payload && payload.length) {
              return (
                <Box
                  sx={{
                    bgcolor:
                      theme.palette.mode === "dark" ? "#07070752" : "#ffffffb4",
                    padding: "8px",
                  }}
                >
                  <Typography fontSize={"14px"}>{`Data: ${label}`}</Typography>
                  {type === "limpo" ? (
                    <Stack direction={"row"} alignItems={"center"} gap={"4px"}>
                      <Box
                        sx={{
                          bgcolor: "#00A76F",
                          width: "14px",
                          height: "14px",
                          borderRadius: "2px",
                          border: `1px solid ${theme.palette.primary.main}`,
                        }}
                      />
                      <Typography
                        fontSize={"14px"}
                      >{`Tráfego Limpo: ${formatBytes(
                        payload[0].payload.cleanTraffic
                      )}`}</Typography>
                    </Stack>
                  ) : (
                    <Stack direction={"row"} alignItems={"center"} gap={"4px"}>
                      <Box
                        sx={{
                          bgcolor: "#FA541C",
                          width: "14px",
                          height: "14px",
                          borderRadius: "2px",
                          border: `1px solid ${theme.palette.primary.main}`,
                        }}
                      />
                      <Typography
                        fontSize={"14px"}
                      >{`Tráfego Sujo: ${formatBytes(
                        payload[0].payload.dirtyTraffic
                      )}`}</Typography>
                    </Stack>
                  )}
                </Box>
              );
            }

            return null;
          }}
        />
        {type === "limpo" ? (
          <Area
            type="monotone"
            dataKey="cleanTraffic"
            name="Tráfego Limpo"
            stroke={theme.palette.mode === "dark" ? "#61ffca" : "#027951"}
            strokeWidth={2}
            fill="#00A76F"
            fillOpacity="1"
            stackId={1}
          />
        ) : (
          <Area
            type="monotone"
            dataKey="dirtyTraffic"
            name="Tráfego Sujo"
            stroke={theme.palette.mode === "dark" ? "#fa8a64" : "#8f2400"}
            fill="#FA541C"
            strokeWidth={2}
            fillOpacity="1"
            stackId={1}
          />
        )}
        {/*         <ReferenceLine
          y={percentile95}
          label={{
            value: "95th percentil",
            position: "insideRight",
            fill: "white",
            fontSize: "12px",
            fontWeight: 500,
            color: theme.palette.primary.main,
          }}
          stroke="red"
          strokeWidth={1.5}
          // strokeDasharray="50 4"
        /> */}
      </AreaChart>
    </ResponsiveContainer>
  );
};

export default LineChartComponent;
