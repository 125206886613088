import {
  AddAPhoto,
  AttachMoneyOutlined,
  Close,
  Code,
  MemoryOutlined,
  Person,
  PersonOff,
  SellOutlined,
  SupportAgentOutlined,
} from "@mui/icons-material";
import {
  Alert,
  Autocomplete,
  Avatar,
  Badge,
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  Fade,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Snackbar,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { useEffect, useState } from "react";
import api from "../../../../services/api";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Resizer from "react-image-file-resizer";
//import AlertModal from "../../../componentes/alertModal";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";

const styles = {
  modalPerfil: {
    backgroundColor: "background.paperOutlined",
    width: "530px",

    margin: "auto",
    marginTop: "10vh",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    borderRadius: "8px",
    padding: "10px",
    overflowY: "auto",
    gap: "15px",
    border: "1px solid rgba(255, 255, 255, 0.12)",
    borderRadius: "12px",
  },
  boxAreaModalPerfil: {
    /* background: "red", */
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  boxAreaTituloModalPerfil: {
    /* background: "blue", */
    position: "relative",
    width: "100%",
    height: "80px",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
  },
  boxAreaConteudoModalPerfil: {
    /* background: "green", */
    backgroundColor: "background.default",
    width: "100%",
    minHeight: "388px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: "12px",
    padding: "30px",
    paddingTop: "10px",
  },
  boxAreaInputsModalPerfil: {
    /* background: "purple", */
    width: "470px",
    minHeight: "292px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  boxAreaButtomModalPerfil: {
    /* background: "pink", */
    width: "100%",
    height: "36px",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-end",
  },
  TextCampo: {
    fontSize: "16px",
    fontWeight: "600",
  },
  TextField: {
    fontSize: "16px",
    fontWeight: "600",
    margin: "10px",
  },
  TypographyCampo: {
    fontSize: "14px",
    fontWeight: "400",
  },
  TypographyCampoInput: {
    fontSize: "14px",
    fontWeight: "400",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
  TypographyCampoSetor: {
    fontSize: "14px",
    fontWeight: "400",
    textTransform: "capitalize",
  },
  StackFoto: {
    alignItems: "center",
    height: "220px",
    position: "absolute",
    marginTop: "10px",
    marginRight: "320px",
  },
  BoxAvatar: {
    width: "126px",
    height: "126px",
    borderRadius: "50%",
    alignItems: "center",
    justifyContent: "center",
    display: "flex",
    bgcolor: "background.paperOutlined",
  },
  Avatar: {
    width: "90px",
    height: "90px",
  },
  boxStatusItem: {
    position: "relative",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-start",
    gap: "6px",
    mt: "-1px",
  },
  boxAreaChipsModalPerfil: {
    display: "flex",
    flexWrap: "wrap",
    width: "100%",
    minHeight: "32px",
    maxHeight: "312px",
    overflowY: "auto",
    gap: "4px",
    alignItems: "center",
    mb: "2.2px",
  },
};

export default function ModalPerfil(params) {
  const {
    id = "",
    openModalPerfil = false,
    handleCloseModalPerfil = () => {},
    profileOpenModal = {},
    clientes = [],
    tabValue = 0,
    updateUserInRows = () => {},
    updateUserClienteInRows = () => {},
  } = params;
  const [cliente, setCliente] = useState(
    clientes?.find((c) => c.id === profileOpenModal?.clienteId) || null
  );
  const [snackbar, setSnackbar] = useState(null);
  const [nomePerfil, setNomePerfil] = useState("");
  const [sobrenomePerfil, setSobrenomePerfil] = useState("");
  const [loginPerfil, setLoginPerfil] = useState("");
  const [emailPerfil, setEmailPerfil] = useState("");
  const [codigoPermissao, setCodigoPermissao] = useState("");
  const [statusPerfil, setStatusPerfil] = useState(true);
  const [setorPerfil, setSetorPerfil] = useState("");
  const [fotoPerfil, setFotoPerfil] = useState("");
  const [editModalPerfil, setEditModalPerfil] = useState(false);
  const [checked, setChecked] = useState({});
  const [disableSaveButtom, setDisableSaveButtom] = useState(false);
  const [selectedPermissoes, setSelectedPermissoes] = useState([]);
  const [errorLogin, setErrorLogin] = useState(false);
  const [errorEmail, setErrorEmail] = useState(false);
  const [emailInvalido, setEmailInvalido] = useState(false);
  const [loginInvalido, setLoginInvalido] = useState(false);

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const theme = useTheme();

  useEffect(() => {
    if (openModalPerfil) {
      handleSetInfoPerfil();
      handleSetInfoPerfilSelected();
    }
  }, [openModalPerfil, profileOpenModal]);

  useEffect(() => {
    if (
      !emailRegex.test(emailPerfil) &&
      emailPerfil !== "" &&
      emailPerfil !== null &&
      editModalPerfil
    ) {
      setEmailInvalido(true);
    } else {
      setEmailInvalido(false);
    }
  }, [emailPerfil]);

  useEffect(() => {
    if (editModalPerfil && (loginPerfil === "" || loginPerfil === null)) {
      setLoginInvalido(true);
    } else {
      setLoginInvalido(false);
    }
  }, [loginPerfil]);

  useEffect(() => {
    if (!editModalPerfil) {
      setNomePerfil(profileOpenModal?.nome || "");
      setSobrenomePerfil(profileOpenModal?.sobrenome || "");
    }
  }, [editModalPerfil]);

  async function handleApiUpdatePerfil() {
    try {
      setDisableSaveButtom(true);

      const response = await api.put(
        tabValue == 0
          ? "/administracao/updatePerfil"
          : "/administracao/updatePerfilClient",
        {
          perfilId: profileOpenModal?.id,
          usuarioId: profileOpenModal?.id,
          nome: nomePerfil,
          sobrenome: sobrenomePerfil,
          login: loginPerfil,
          email: emailPerfil,
          codigoPermissao: codigoPermissao,
          status: statusPerfil,
          setor: setorPerfil,
          foto: fotoPerfil,
          clienteId: cliente?.id || "",
          logsLoginId:
            localStorage.getItem("logs") !== "undefined"
              ? JSON.parse(localStorage.getItem("logs")).id
              : "",
        }
      );

      if (response.status === 200) {
        tabValue == 0
          ? updateUserInRows({
              ...profileOpenModal,
              perfilId: profileOpenModal?.id,
              usuarioId: profileOpenModal?.id,
              nome: nomePerfil,
              sobrenome: sobrenomePerfil,
              login: loginPerfil,
              email: emailPerfil,
              codigoPermissao: codigoPermissao,
              status: statusPerfil,
              setor: setorPerfil,
              foto: fotoPerfil,
            })
          : updateUserClienteInRows({
              ...profileOpenModal,
              perfilId: profileOpenModal?.id,
              usuarioId: profileOpenModal?.id,
              nome: nomePerfil,
              sobrenome: sobrenomePerfil,
              login: loginPerfil,
              email: emailPerfil,
              codigoPermissao: codigoPermissao,
              status: statusPerfil,
              setor: setorPerfil,
              foto: fotoPerfil,
              clienteId: cliente?.id || "",
              as: cliente?.as || "",
              cliente: cliente?.nomeFantasia || "",
            });

        setSnackbar({
          children: "Perfil atualizado com sucesso",
          severity: "success",
        });
        handleCloseModalPerfil();
        setSelectedPermissoes([]);
        setErrorEmail(false);
        setErrorLogin(false);
        setChecked((prevState) => {
          const newState = {};
          for (const key in prevState) {
            newState[key] = {
              valor: false,
              titulo: prevState[key]?.titulo,
            };
          }
          return newState;
        });
      }
    } catch (error) {
      let dataError = error?.response?.data;

      if (dataError) {
        if (dataError === "Email já existente") {
          setErrorEmail(true);
          setSnackbar({
            children: "E-mail existente",
            severity: "error",
          });
        } else if (dataError === "Login já existente") {
          setErrorLogin(true);
          setSnackbar({
            children: "Login existente",
            severity: "error",
          });
        } else {
          setSnackbar({
            children: "Erro ao atualizar informações do perfil",
            severity: "error",
          });
        }
      } else {
        setSnackbar({
          children: "Erro ao atualizar informações do perfil",
          severity: "error",
        });
      }
    } finally {
      setDisableSaveButtom(false);
      setEditModalPerfil(false);
    }
  }

  async function handleSetInfoPerfil() {
    setNomePerfil(profileOpenModal?.nome);
    setSobrenomePerfil(profileOpenModal?.sobrenome);
    setLoginPerfil(profileOpenModal?.login);
    setEmailPerfil(profileOpenModal?.email);
    setCodigoPermissao(profileOpenModal?.codigoPermissao || "");
    setStatusPerfil(profileOpenModal?.status);
    setSetorPerfil(profileOpenModal?.setor);
    setFotoPerfil(profileOpenModal?.foto);
    setCliente(
      clientes?.find((c) => c.id === profileOpenModal?.clienteId) || null
    );
  }

  async function handleSetInfoPerfilSelected() {
    if (openModalPerfil && profileOpenModal?.codigoPermissao) {
      const permissoes = profileOpenModal.codigoPermissao.split(",");
      setSelectedPermissoes(permissoes);
      const initialChecked = permissoes.reduce((acc, permissao) => {
        acc[permissao] = { valor: true, titulo: permissao };
        return acc;
      }, {});
      setChecked(initialChecked);
    }
  }

  const handleChangeStatusPerfil = (event) => {
    let value = event.target.value;
    if (value === "ATIVO") {
      setStatusPerfil(true);
    } else {
      setStatusPerfil(false);
    }
  };

  async function handleCancelEditPerfil() {
    if (openModalPerfil) {
      setChecked((prevState) => {
        const newState = {};
        for (const key in prevState) {
          newState[key] = {
            valor: false,
            titulo: prevState[key]?.titulo,
          };
        }
        return newState;
      });
      setErrorEmail(false);
      setErrorLogin(false);
      setSelectedPermissoes([]);
      handleSetInfoPerfil();
      handleSetInfoPerfilSelected();
      setDisableSaveButtom(false);
      setEditModalPerfil(false);
    }
  }

  return (
    <Box key={id}>
      <Modal
        open={openModalPerfil}
        onClose={handleCloseModalPerfil}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        closeAfterTransition
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openModalPerfil}>
          <Paper sx={styles.modalPerfil}>
            <Stack sx={styles.StackFoto}>
              <Box sx={styles.BoxAvatar}>
                <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  badgeContent={
                    editModalPerfil ? (
                      <Tooltip title={"adicionar imagem"}>
                        <IconButton
                          //variant="outlined"
                          component="label"
                          color="primary"
                          sx={{
                            color: "text.textContrast",
                            backgroundColor:
                              theme.palette.mode === "dark"
                                ? "#949494"
                                : "#979797",
                            "&:hover": {
                              backgroundColor:
                                theme.palette.mode === "dark"
                                  ? "#aaaaaa"
                                  : "#7a7a7a",
                            },
                          }}
                        >
                          <AddAPhoto />
                          <input
                            hidden
                            accept=".png, .jpg, .jpeg"
                            type="file"
                            onChange={(e) => {
                              const file = e.target.files[0];
                              if (file?.size < 1024 * 300) {
                                Resizer.imageFileResizer(
                                  file,
                                  500,
                                  500,
                                  "JPEG",
                                  80,
                                  0,
                                  (uri) => {
                                    setFotoPerfil(uri);
                                  },
                                  "base64",
                                  500,
                                  500
                                );
                                const fileReader = new FileReader();
                                fileReader.readAsDataURL(file);
                                fileReader.onload = () => {};
                                fileReader.onerror = (error) => {
                                  console.error("Error:", error);
                                };
                              } else {
                                setSnackbar({
                                  children: "Tamanho máximo 300kb",
                                  severity: "error",
                                });
                              }
                            }}
                          />{" "}
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <></>
                    )
                  }
                >
                  <Avatar
                    src={fotoPerfil}
                    color="secondary"
                    sx={styles.Avatar}
                  />
                </Badge>
              </Box>
            </Stack>
            <Box sx={styles.boxAreaModalPerfil}>
              <Box sx={styles.boxAreaTituloModalPerfil}>
                {/*                 <IconButton
                  onClick={() => {
                    if (!disableSaveButtom) {
                      setOpenModalAlertSave(true);
                    } else {
                      handleCloseModalPerfil();
                      setEditModalPerfil(false);
                      setSelectedPermissoes([]);
                      setErrorEmail(false);
                      setErrorLogin(false);
                      setChecked((prevState) => {
                        const newState = {};
                        for (const key in prevState) {
                          newState[key] = {
                            valor: false,
                            titulo: prevState[key]?.titulo,
                          };
                        }
                        return newState;
                      });
                    }
                  }}
                  sx={{
                    position: "absolute",
                    right: 4,
                    top: 1,
                  }}
                >
                  <Close />
                </IconButton> */}
                <Typography
                  variant="h4"
                  sx={{ mb: "16px", color: "primary.main" }}
                >
                  Perfil
                </Typography>
              </Box>
              <Box sx={styles.boxAreaConteudoModalPerfil}>
                <Box sx={styles.boxAreaButtomModalPerfil}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      mr: "-21px",
                      mt: "-6px",
                      gap: "10px",
                    }}
                  >
                    {editModalPerfil && (
                      <Button
                        onClick={() => handleCancelEditPerfil()}
                        sx={{ color: "secondary.main" }}
                        variant="text"
                      >
                        {" "}
                        Cancelar
                      </Button>
                    )}
                    {profileOpenModal?.nome !== null && (
                      <Button
                        disabled={disableSaveButtom}
                        onClick={() => {
                          setEditModalPerfil(true);
                          if (editModalPerfil) {
                            handleApiUpdatePerfil();
                          }
                        }}
                        sx={{ color: "primary.main" }}
                        variant="text"
                      >
                        {editModalPerfil ? "Salvar" : "Editar"}{" "}
                      </Button>
                    )}
                  </Box>
                </Box>
                <Box sx={styles.boxAreaInputsModalPerfil}>
                  <Stack
                    direction={"column"}
                    padding={"10px"}
                    gap={"30px"}
                    justifyContent={"flex-start"}
                    alignItems={"flex-start"}
                    sx={{
                      marginTop: "20px",
                    }}
                  >
                    <Stack direction={"row"} spacing={"10px"}>
                      {" "}
                      <Stack
                        direction={"column"}
                        width={"220px"}
                        spacing={"10px"}
                        justifyContent={"center"}
                        // alignItems={"center"}
                      >
                        <TextField
                          id="nome"
                          variant="filled"
                          size="small"
                          disabled={!editModalPerfil}
                          label="Nome"
                          value={nomePerfil}
                          sx={styles.TextField}
                          onChange={(event) => {
                            setNomePerfil(event.target.value);
                          }}
                        />
                      </Stack>
                      <Stack
                        direction={"column"}
                        width={"220px"}
                        spacing={"10px"}
                      >
                        <TextField
                          variant="filled"
                          disabled={!editModalPerfil}
                          size="small"
                          id="sobrenome"
                          label="Sobrenome"
                          value={sobrenomePerfil}
                          sx={styles.TextField}
                          onChange={(event) => {
                            setSobrenomePerfil(event.target.value);
                          }}
                        />
                      </Stack>
                    </Stack>
                    <Divider orientation="horizontal" sx={{ width: "450px" }} />
                    <Stack direction={"row"} spacing={"10px"}>
                      {" "}
                      <Stack
                        direction={"column"}
                        width={"220px"}
                        spacing={"10px"}
                      >
                        <TextField
                          error={errorLogin || loginInvalido}
                          helperText={errorLogin ? "Login existente" : false}
                          variant="filled"
                          size="small"
                          id="Login"
                          label="login"
                          disabled={!editModalPerfil}
                          value={loginPerfil}
                          sx={styles.TextField}
                          onChange={(event) => {
                            setLoginPerfil(event.target.value);
                          }}
                        />
                      </Stack>
                      <Stack
                        direction={"column"}
                        width={"220px"}
                        spacing={"10px"}
                      >
                        <TextField
                          error={errorEmail || emailInvalido}
                          helperText={errorEmail ? "Email existente" : false}
                          id="email"
                          size="small"
                          variant="filled"
                          disabled={!editModalPerfil}
                          label="Email"
                          value={emailPerfil}
                          sx={styles.TextField}
                          onChange={(event) => {
                            setEmailPerfil(event.target.value);
                          }}
                        />
                      </Stack>
                    </Stack>{" "}
                    <Divider orientation="horizontal" sx={{ width: "450px" }} />
                    <Stack direction={"row"} spacing={"10px"}>
                      <Stack
                        direction={"column"}
                        width={"220px"}
                        spacing={"10px"}
                      >
                        <FormControl
                          variant="filled"
                          size="small"
                          sx={styles.TextField}
                          disabled={!editModalPerfil}
                          fullWidth
                        >
                          <InputLabel>Status</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            value={statusPerfil ? "ATIVO" : "DESATIVADO"}
                            onChange={(event) => {
                              handleChangeStatusPerfil(event);
                            }}
                            name="status"
                            color="primary"
                          >
                            <MenuItem value={"ATIVO"}>
                              <Box sx={styles.boxStatusItem}>
                                <Person
                                  sx={{
                                    color: "primary.main",
                                    mt: "-3px",
                                    fontSize: "22px",
                                  }}
                                />
                                <Typography>ATIVO</Typography>
                              </Box>
                            </MenuItem>
                            <MenuItem value={"DESATIVADO"}>
                              <Box sx={styles.boxStatusItem}>
                                <PersonOff
                                  sx={{
                                    color: "secondary.main",
                                    mt: "-3px",
                                    fontSize: "22px",
                                  }}
                                />
                                <Typography>DESATIVADO</Typography>
                              </Box>
                            </MenuItem>
                          </Select>
                        </FormControl>
                      </Stack>
                      <Stack
                        direction={"column"}
                        width={"220px"}
                        spacing={"10px"}
                      >
                        {tabValue === 0 && (
                          <FormControl
                            variant="filled"
                            size="small"
                            sx={styles.TextField}
                            disabled={!editModalPerfil}
                            fullWidth
                          >
                            <InputLabel>Permissão</InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              value={codigoPermissao}
                              label="Permissão"
                              onChange={(event) => {
                                setCodigoPermissao(event.target.value);
                              }}
                              name="status"
                              color="primary"
                            >
                              <MenuItem value="">Nenhuma</MenuItem>
                              <MenuItem value="adm">ADM</MenuItem>
                            </Select>
                          </FormControl>
                        )}
                      </Stack>
                    </Stack>
                    {tabValue == 1 && (
                      <>
                        <Divider
                          orientation="horizontal"
                          sx={{ width: "450px" }}
                        />
                        <Stack
                          direction={"row"}
                          spacing={"10px"}
                          width={"100%"}
                        >
                          <Stack
                            direction={"column"}
                            width={"100%"}
                            spacing={"10px"}
                            justifyContent={"center"}
                          >
                            <Autocomplete
                              fullWidth
                              value={cliente}
                              disabled={!editModalPerfil}
                              onChange={(event, newValue) => {
                                setCliente(newValue);
                              }}
                              size="small"
                              options={clientes}
                              getOptionLabel={(option) => option.nomeFantasia}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="filled"
                                  label="Cliente"
                                  color="primary"
                                />
                              )}
                            />
                          </Stack>
                        </Stack>{" "}
                      </>
                    )}
                  </Stack>
                </Box>
              </Box>
            </Box>
          </Paper>
        </Fade>
      </Modal>
      {/*  <AlertModal
        openModal={openModalAlertSave}
        setOpenModal={setOpenModalAlertSave}
        textContent={
          "Se você prosseguir com a operação as alterações serão perdidas permanentemente!"
        }
        handleButton={handleSairSemSalvar}
        severity={"warning"}
      /> */}
      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2500}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
    </Box>
  );
}
