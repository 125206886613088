import { React, useState } from "react";
import { Box, Tabs, Tab, Typography } from "@mui/material";
import PrefixosIpv4 from "./ipv4";
import PrefixosIpv6 from "./ipv6";
import useStyles from "./styles";
import InternoModalConfirmacaoSaida from "../../../../../components/modalSaindoDaPagina";

export default function Prefixos(params) {
  const { changed = false, setChanged = () => {}, clienteInfo = {} } = params;
  const styles = useStyles();
  const [openConfirmacao, setOpenConfirmacao] = useState(false);
  const [newTabValue, setNewTabValue] = useState(null);

  //Change tabs handler
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    if (changed) {
      // const res = window.confirm(
      //   "As alterações não foram salvas, deseja continuar?"
      // );
      // if (res === true) {
      //   setChanged(false);
      //   setValue(newValue);
      // }
      setNewTabValue(newValue);
      setOpenConfirmacao(true);
    } else {
      setValue(newValue);
    }
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <Box>
      <Box sx={styles.container}>
        <Typography variant="mySubtitle">Configurações de Prefixos</Typography>

        <Box>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basics tabs config"
          >
            <Tab label="IPV4" {...a11yProps(0)} sx={styles.tabLabel} />
            <Tab label="IPV6" {...a11yProps(1)} sx={styles.tabLabel} />
          </Tabs>
        </Box>
        {value === 0 && (
          <PrefixosIpv4 setChanged={setChanged} clienteInfo={clienteInfo} />
        )}
        {value === 1 && (
          <PrefixosIpv6 setChanged={setChanged} clienteInfo={clienteInfo} />
        )}
      </Box>
      <InternoModalConfirmacaoSaida
        openConfirmacao={openConfirmacao}
        confirmLoad={false}
        setOpenConfirmacao={setOpenConfirmacao}
        execute={() => {
          // Mudança de aba a partir da confirmação
          setValue(newTabValue);
          setChanged(false);
          setOpenConfirmacao(false);
        }}
        severity={"warning"}
        contentText={
          "As alterações não salvas serão perdidas, deseja continuar?"
        }
        headerText={"Você está saíndo da página."}
        confirmText={"Continuar"}
      />
    </Box>
  );
}
