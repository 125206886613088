import { Box, Paper, Tooltip, Typography, Stack } from "@mui/material";
import dayjs from "dayjs";

import { useState } from "react";
import LineChartComponent from "./line";

function gerarDadosUltimos90Dias() {
  const ultimos90Dias = [];
  for (let i = 0; i < 90; i++) {
    const data = new Date();
    data.setDate(data.getDate() - i);
    const objeto = {
      data: data.toISOString().split("T")[0], // Formato YYYY-MM-DD
      valor: Math.floor(Math.random() * 100), // Valor inteiro aleatório entre 0 e 99
    };
    ultimos90Dias.unshift(objeto); // Adiciona ao início do array para manter a ordem cronológica
  }
  return ultimos90Dias;
}

export default function Status() {
  const [upTime, setUpTime] = useState(gerarDadosUltimos90Dias);

  return (
    <Box
      sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}
    >
      <Typography
        sx={{ fontSize: "20px", fontWeight: 600, mb: "16px", mt: "16px" }}
      >
        MÉTRICAS DO SISTEMA
      </Typography>
      <Paper
        sx={{ padding: "24px", display: "inline-block", gap: "8px" }}
        variant="outlined"
      >
        <Typography sx={{ fontSize: "16px", fontWeight: 600, mb: "8px" }}>
          API Nexus
        </Typography>
        <Box
          sx={{ display: "flex", flexDirection: "row", gap: "6px", mb: "16px" }}
        >
          {upTime.map((up, index) => {
            return (
              <Box key={index}>
                <Tooltip
                  title={
                    <Stack>
                      <Typography>
                        {dayjs(up.data).format("DD/MM/YYYY")}
                      </Typography>
                      <Typography>Requisições {up.valor} </Typography>
                    </Stack>
                  }
                  sx={{
                    "& .MuiTooltip-tooltip": {
                      backgroundColor: "blue !important", // Substitua "blue" pela cor desejada
                      color: "white !important", // Substitua "white" pela cor do texto desejada
                    },
                    cursor: "pointer",
                  }}
                  arrow
                >
                  <Box
                    sx={{
                      height: "36px",
                      width: "6px",
                      backgroundColor: "green",
                    }}
                  />
                </Tooltip>
              </Box>
            );
          })}
        </Box>
        <Typography sx={{ fontSize: "16px", fontWeight: 600, mb: "8px" }}>
          API BrByte
        </Typography>
        <Box sx={{ display: "flex", flexDirection: "row", gap: "6px" }}>
          {upTime.map((up, index) => {
            return (
              <Box key={index}>
                <Tooltip
                  title={
                    <Stack>
                      {dayjs(up.data).format("DD/MM/YYYY")}:{up.valor}%{" "}
                    </Stack>
                  }
                  sx={{
                    cursor: "pointer",
                  }}
                  arrow
                >
                  <Box
                    sx={{
                      height: "36px",
                      width: "6px",
                      backgroundColor: "green",
                    }}
                  />
                </Tooltip>
              </Box>
            );
          })}
        </Box>
        <Typography
          sx={{ fontSize: "16px", fontWeight: 600, mb: "8px", mt: "16px" }}
        >
          Tempo de Resposta API
        </Typography>
        <Box sx={{ display: "flex", width: "100%", height: "200px" }}>
          <LineChartComponent />
        </Box>
      </Paper>
    </Box>
  );
}
