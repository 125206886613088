import { borderColor, minHeight } from "@mui/system";

const styles = (props) => ({
  container: {
    bgcolor: "background.paper",
    display: "flex",
    padding: "16px 24px 24px 24px",
    borderRadius: "8px",
    maxWidth: "748px",
    maxHeight: "89vh",
    overflow: "auto",
    flexDirection: "column",
    gap: 2,
  },
  form: { display: "flex", flexDirection: "row", gap: "64px" },
  textfield: {
    width: "340px",
    "@media (max-width: 920px)": {
      width: "100%",
    },
  },
  asTextField: {
    width: "340px",
    "@media (max-width: 920px)": {
      width: "100%",
    },
    "& .MuiInputLabel-root.Mui-error": {
      color: "secondary.main",
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      border: "3px solid",
      borderColor: "secondary.main",
    },
    "& .MuiFormHelperText-root.Mui-error": {
      color: "secondary.main",
    },
  },
  boxForm: { display: "flex", flexDirection: "column", gap: 1, width: "100%" },
});

export default styles;
