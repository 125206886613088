const styles = (theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    padding: "16px",
    borderRadius: "10px",
    bgcolor: "background.paper",
  },
  boxPaper: { display: "flex", flexDirection: "column", gap: "16px" },
  boxContent: {
    display: "flex",
    flexDirection: "column",
    gap: 1,
    width: "100%",
  },
  textField: {
    width: "340px",
  },
  asTextField: {
    width: "340px",
    "& .MuiInputLabel-root.Mui-error": {
      color: "secondary.main",
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      border: "3px solid",
      borderColor: "secondary.main",
    },
    "& .MuiFormHelperText-root.Mui-error": {
      color: "secondary.main",
    },
  },
});

export default styles;
