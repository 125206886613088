import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import apiCliente from "../../../../services/apiCliente";
import { Box, palette } from "@mui/system";
import { CircularProgress, CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
export default function Recibo() {
  const [recibo, setRecibo] = useState("");
  const [loading, setLoading] = useState(false);
  const id = useParams();

  useEffect(() => {
    getRecibo();
  }, []);

  const getRecibo = async () => {
    setLoading(true);
    try {
      const response = await apiCliente.post(
        "/brbyte/cliente/contrato/cobranca/recibo",
        {
          invoice_pk: id?.invoice_pk,
        }
      );

      setRecibo(response?.data);
      return response?.data;
    } catch (error) {
      if (error?.response?.status == 404) {
        /* setSnackbar({
          children: `${
            error?.response?.data?.error ||
            "Error: Não foi possivel encontrar contratos"
          }`,
          severity: "error",
        }); */
      } else {
        /* setSnackbar({
          children: "Error: Não foi possivel se conectar ao servidor",
          severity: "error",
        }); */
      }
    } finally {
      setLoading(false);
    }
  };

  const lightTheme = createTheme({
    palette: {
      mode: "light",
    },
  });

  const markup = {
    __html: recibo?.replace(
      "/public/assets/uploaddir/logo/office/2/logo.png",
      "http://cliente.computize.network:8081/public/assets/uploaddir/logo/office/2/logo.png"
    ),
  };
  return (
    <ThemeProvider theme={createTheme(lightTheme)}>
      <Box
        sx={{
          backgroundColor: "#ffffff",
          height: "100vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CssBaseline />
        {loading ? (
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "center",
            }}
          >
            <CircularProgress size={60} />
          </Box>
        ) : (
          <div dangerouslySetInnerHTML={markup} />
        )}
      </Box>
    </ThemeProvider>
  );
}
