import {
  BarChart,
  HomeOutlined,
  SupervisorAccountOutlined,
} from "@mui/icons-material";
import {
  Box,
  Drawer,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tab,
  Tabs,
  useTheme,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";

const styles = {
  tab: {
    minWidth: "60px",
    minHeight: "48px",
    maxWidth: "200px",
    justifyContent: "start",
    color: "drawer.text",
    fontSize: "0.875 rem",
    "&:hover": {
      backgroundColor: "#00000021",
    },
  },
};

export default function MobileDrawer(params) {
  const { open = false, setOpen = () => {} } = params;
  const [value, setValue] = useState(0);
  const [administrador, setAdministrador] = useState(false);
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  useEffect(() => {
    let result = localStorage.getItem("user");
    result = JSON.parse(result);

    const pageName = window.location.pathname;
    const pages = ["/interno/home", "/interno/dash", "/interno/administracao"];
    pages.map((page, index) => {
      if (pageName === page) {
        setValue(index);
      }
    });
    const handleUsuarioId = () => {
      // Verifica se a propriedade rlPermissoesUsuario existe e é um array
      if (Array.isArray(result?.usuario?.rlPermissoesUsuario)) {
        // Itera sobre o array para verificar se algum objeto contém a propriedade codigoPermissao com valor "ADM"
        for (let permissao of result?.usuario?.rlPermissoesUsuario) {
          if (permissao?.permissao?.codigoPermissao.toUpperCase() === "ADM") {
            setAdministrador(true);
          }
        }
        return false;
      }
    };

    handleUsuarioId();
  }, []);
  const theme = useTheme();

  const navigate = useNavigate();

  const DrawerList = (
    <Box sx={{ width: 200 }} role="presentation" onClick={toggleDrawer(false)}>
      <Tabs
        orientation="vertical"
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
        textColor={"secondary"}
        TabIndicatorProps={{
          style: {
            background: theme.palette.secondary.main,
          },
        }}
      >
        <Tab
          icon={<HomeOutlined />}
          label={"Home"}
          iconPosition="start"
          value={0}
          sx={styles.tab}
          onClick={(event) => {
            navigate("/interno/home");
          }}
        />

        <Tab
          icon={<BarChart />}
          label={"DASHBOARD"}
          iconPosition="start"
          value={1}
          sx={styles.tab}
          onClick={() => {
            navigate("/interno/dash");
          }}
        />
      </Tabs>
      {administrador && (
        <Tab
          icon={<SupervisorAccountOutlined />}
          label={open ? "ADMINISTRAÇÃO" : ""}
          iconPosition="start"
          value={2}
          sx={styles.tab}
          onClick={() => {
            navigate("/interno/administracao");
          }}
        />
      )}

      {/* <List>
          {["HOME", "DASHBOARD"].map((text, index) => (
            <ListItem key={text} disablePadding>
              <ListItemButton
                onClick={() => {
                  index === 0
                    ? navigate("/interno/home")
                    : navigate("/interno/dash");
                }}
              >
                <ListItemIcon>
                  {index === 0 ? (
                    <HomeOutlined color={"secondary"} />
                  ) : (
                    <BarChart />
                  )}
                </ListItemIcon>
                <ListItemText>{text}</ListItemText>
              </ListItemButton>
            </ListItem>
          ))}
        </List> */}
      {/* <List>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemIcon>
                <HomeOutlined />
              </ListItemIcon>
              <ListItemText primary={"HOME"} />
            </ListItemButton>
            <ListItemButton>
              <ListItemIcon>
                <BarChart />
              </ListItemIcon>
              <ListItemText primary={"HOME"} />
            </ListItemButton>
          </ListItem>
        </List> */}
    </Box>
  );

  return (
    <Box>
      <Drawer
        open={open}
        onClose={toggleDrawer(false)}
        PaperProps={{
          elevation: 0,
          sx: {
            backgroundColor: "background.paper",
          },
        }}
      >
        {DrawerList}
      </Drawer>
    </Box>
  );
}
