import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  Legend,
  ReferenceLine,
  Line,
  LineChart,
} from "recharts";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import { Box, Typography, useTheme, Stack } from "@mui/material";
import { date } from "zod";

/* const calculatePercentile = (data, key, percentile) => {
  const sortedData = data.map((item) => item[key]).sort((a, b) => a - b);
  const index = Math.floor((percentile / 100) * sortedData.length);
  return sortedData[index];
}; */

const LineChartComponent = ({
  data = [
    { value: 1, date: "2021-10-01" },
    { value: 2, date: "2021-10-02" },
    { value: 3, date: "2021-10-03" },
    { value: 4, date: "2021-10-04" },
    { value: 5, date: "2021-10-05" },
    { value: 6, date: "2021-10-06" },
    { value: 4, date: "2021-10-07" },
    { value: 6, date: "2021-10-08" },
    { value: 3, date: "2021-10-09" },
    { value: 2, date: "2021-10-10" },
    { value: 1, date: "2021-10-11" },
    { value: 2, date: "2021-10-12" },
    { value: 3, date: "2021-10-13" },
    { value: 4, date: "2021-10-14" },
    { value: 5, date: "2021-10-15" },
    { value: 6, date: "2021-10-16" },
    { value: 4, date: "2021-10-17" },
    { value: 6, date: "2021-10-18" },
    { value: 3, date: "2021-10-19" },
    { value: 2, date: "2021-10-20" },
    { value: 1, date: "2021-10-21" },
    { value: 2, date: "2021-10-22" },
    { value: 3, date: "2021-10-23" },
    { value: 4, date: "2021-10-24" },
    { value: 5, date: "2021-10-25" },
    { value: 6, date: "2021-10-26" },
    { value: 4, date: "2021-10-27" },
    { value: 6, date: "2021-10-28" },
    { value: 3, date: "2021-10-29" },
    { value: 2, date: "2021-10-30" },
    { value: 1, date: "2021-10-31" },
  ],
  range = {},
}) => {
  const theme = useTheme();

  // const percentile95 = calculatePercentile(data, "cleanTraffic", 95);
  return (
    <ResponsiveContainer width="100%">
      <LineChart
        data={data}
        margin={{
          top: 20,
          right: 0,
          left: 10,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis
          dataKey="date"
          style={{ fontSize: "12px", fontWeight: 500 }}
          tick={{ fill: theme.palette.primary.main, opacity: 0.7 }}
        />
        <YAxis
          interval={0}
          tick={{ fill: theme.palette.primary.main, opacity: 0.7 }}
          style={{ fontSize: "12px", fontWeight: 500 }}
        />
        <Tooltip
          content={({ active, payload, label }) => {
            if (active && payload && payload.length) {
              return (
                <Box
                  sx={{
                    bgcolor:
                      theme.palette.mode === "dark" ? "#07070752" : "#ffffffb4",
                    padding: "8px",
                  }}
                >
                  <Typography fontSize={"14px"}>{`Data: ${label}`}</Typography>

                  <Stack direction={"row"} alignItems={"center"} gap={"4px"}>
                    <Box
                      sx={{
                        bgcolor: "#00A76F",
                        width: "14px",
                        height: "14px",
                        borderRadius: "2px",
                        border: `1px solid ${theme.palette.primary.main}`,
                      }}
                    />
                    <Typography
                      fontSize={"14px"}
                    >{`Tempo da requisição: ${payload[0].payload.value}`}</Typography>
                  </Stack>
                </Box>
              );
            }

            return null;
          }}
        />

        <Line
          type="monotone"
          dataKey="value"
          name="Tempo"
          stroke={theme.palette.mode === "dark" ? "#61ffca" : "#027951"}
          strokeWidth={2}
          fill="#00A76F"
          fillOpacity="1"
          stackId={1}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default LineChartComponent;
