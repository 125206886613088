import { bgcolor, color } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import clsx from "clsx";
import { Chip } from "@mui/material";
export default function Prefix({
  rows = [],
  pageSize = 5,
  hideFooter = false,
  load,
}) {
  /*const teste = rows[0].data.split(" - ");
  console.log(teste);*/
  //console.log("dados", rows.status);

  const columnsRecentEvetns = [
    { field: "id", headerName: "ID", width: 100 },

    {
      field: "prefixSession",
      headerName: "Prefixos Recebidos",
      flex: 1,
      minWidth: 150,
    },
    {
      field: "ipSession",
      headerName: "IP da sessão",
      flex: 1,
      minWidth: 150,
    },
  ];

  return (
    <>
      <DataGrid
        rows={rows}
        columns={columnsRecentEvetns}
        autoHeight={true}
        getRowHeight={() => "auto"}
        autosizeOptions={{ includeOutliers: true }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: pageSize,
            },
          },
          columns: {
            columnVisibilityModel: {
              id: false,
              attack_type: false,
              alertId: false,
            },
          },
        }}
        loading={load}
        sx={{
          display: "grid",
          height: "100%",
          "&.MuiDataGrid-root": {
            borderStyle: "none",
          },
          "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
            outline: "none !important",
          },
          "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
            py: "5px",
          },
          "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
            py: "15px",
          },
          "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
            py: "22px",
          },

          '&.MuiDataGrid-root .MuiDataGrid-container--top [role="row"], & .MuiDataGrid-root .MuiDataGrid-container--bottom [role="row"]':
            {
              backgroundColor: "background.paper", // Mudando o background para vermelho
            },

          "& .super-app.positivo_ataque": {
            fontWeight: "600",
            animation: "myEffect 2.5s infinite",
          },

          "@keyframes myEffect": {
            "0%": {
              color: "#FA541C",
              fontWeight: "600",
            },
            "50%": {
              color: "#ffffff",
              fontWeight: "600",
            },
            "100%": {
              color: "#FA541C",
              fontWeight: "600",
            },
          },
        }}
        density="compact"
        disableColumnMenu
        disableRowSelectionOnClick
        //hideFooter={hideFooter}
      />
    </>
  );
}
