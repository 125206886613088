import {
  Alert,
  Box,
  Button,
  FormControl,
  Icon,
  FormHelperText,
  InputLabel,
  OutlinedInput,
  Paper,
  Link,
  Snackbar,
  Stack,
  Typography,
  CircularProgress,
  useTheme,
  TextField,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState, useRef, createRef } from "react";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import logoDark from "../../../assets/imgs/logos/logocomputizeDark.webp";
import logoLigth from "../../../assets/imgs/logos/logoComputizeLigth.webp";
import Code from "./textFieldCode";
import Interval from "./contador";
import ReCAPTCHA from "react-google-recaptcha";

export default function SolicitarRedefinirSenha() {
  const [rev, setRev] = useState(false);
  const [email, setEmail] = useState("");
  const [Null, setNull] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertServiceOpen, setAlertServiceOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [captcha, setCaptcha] = useState(false);
  const [sended, setSended] = useState(false);
  const [user, setUser] = useState("");
  const [code, setCode] = useState("");
  const [codeAlert, setCodeAlert] = useState(false);
  const [id, setId] = useState("");
  const [emailValid, setEmailValid] = useState(false);
  const intervalRef = useRef(null);
  const recaptchaRef = createRef();
  const theme = useTheme();
  const url = `${
    window?.config?.REACT_APP_BASE_URL || process.env.REACT_APP_BASE_URL || ""
  }`;
  const navigate = useNavigate();

  const styles = {
    LoadingLogo: {
      // m: 1,
      position: "relative",

      margin: "50px",
    },
    CircularProgress: {
      color: "primary",
      position: "absolute",
      top: -9,
      left: -6,
      zIndex: 1,
    },
    TextCampo: {
      mt: 1,
      mb: 2,
      width: "200px",
    },
  };

  useEffect(() => {
    if (email.includes("@") && email.includes(".")) setEmailValid(true);
    else setEmailValid(false);
  }, [email]);

  useEffect(() => {
    setCaptcha(null);
  }, []);

  const handleNextUpdatePassword = async () => {
    try {
      const response = await axios.post(
        `${url}/usuarioCliente/resetPassword/validate`,
        {
          code,
          usuarioId: id,
        }
      );

      navigate(`/cliente/redefinirSenha/${response.data.token}/${id}`);
    } catch (error) {
      if (error?.response?.status == 401) {
        setCodeAlert(true);
      } else {
        setAlertServiceOpen(true);
      }
    }
  };

  const handleSend = async () => {
    if (!email || !emailValid) {
      setNull(true);
      return;
    }
    setLoading(true);
    try {
      const response = await axios.post(
        `${url}/usuarioCliente/resetPassword/request`,
        {
          email: email.toLowerCase(),
        }
      );
      setUser(response.data.login);
      setId(response.data.usuarioId);

      setSended(true);
    } catch (error) {
      console.log(error);
      if (error?.response?.status == 404) {
        setAlertOpen(true);
      } else {
        setAlertServiceOpen(true);
      }
    }
    setLoading(false);
  };
  function handleStartCounter() {
    if (intervalRef.current) {
      intervalRef.current.startCounter();
    }
  }

  return (
    <>
      <Box
        sx={{
          zIndex: -1,
          height: "100vh",
          width: "98vw",
          position: "absolute",
          top: 0,
        }}
      />
      <Paper
        sx={{
          minWidth: "350px",
          padding: "20px 50px",
          width: { xs: "95vw", sm: "500px" },
          margin: "auto",

          marginTop: "15vh",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          display: "flex",
        }}
      >
        <Button
          variant="contained"
          disabled={loading}
          href="/interno/login"
          sx={{
            display: "flex",
            minWidth: "0px",
            marginLeft: "-90%",
            fontSize: "11px",
            height: "35px",
          }}
        >
          <ArrowBackIcon />
          Voltar
        </Button>

        <Stack
          direction={"column"}
          gap={"15px"}
          alignItems={"center"}
          sx={{ padding: "0px", width: "100%", mt: 2 }}
        >
          <img
            src={theme.palette.mode === "light" ? logoLigth : logoDark}
            style={{ height: "auto", width: "280px" }}
            alt="logo mw"
          />
          {loading && (
            <Box sx={styles.LoadingLogo}>
              <CircularProgress size={100} thickness={2.5} />
            </Box>
          )}
          {!loading && !sended && (
            <>
              <Typography
                sx={{
                  fontSize: "1em",
                  textAlign: "center",
                }}
              >
                Digite o endereço de e-mail verificado da sua conta de usuário e
                lhe enviaremos um link de redefinição de senha.
              </Typography>
              <TextField
                sx={{ m: 1, width: "100%", marginTop: "0px" }}
                variant="filled"
                label="Email"
                type="email"
                id="emailredefine"
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
                error={Null && !emailValid}
                helperText={Null && !emailValid ? "Email inválido" : ""}
              />

              <ReCAPTCHA
                id="recaptcha"
                key={"recaptcha"}
                size={"normal"}
                sitekey={
                  window?.config?.REACT_APP_RECAPTCHA ||
                  process.env.REACT_APP_RECAPTCHA ||
                  ""
                }
                onChange={setCaptcha}
              />

              <Button
                variant="contained"
                disabled={!captcha}
                onClick={() => {
                  handleSend();
                }}
                sx={{
                  width: "90%",
                  borderRadius: "5px",
                  marginTop: "10px",
                  height: "40px",
                  marginBottom: "20px",
                }}
              >
                Enviar email de redefinição
              </Button>
            </>
          )}
          {!loading && sended && (
            <>
              <Typography
                sx={{
                  fontSize: "1.1em",
                  textAlign: "center",
                  mb: "20px",
                  padding: "10px",
                  backgroundColor: "rgba(0, 0, 0,0.1)",
                }}
              >
                Olá {user}.
                <br />
                Enviamos um email com um código para {email.toLowerCase()},
                acesse e escreva-o abaixo:
                <br />
              </Typography>

              <Code onChange={(value) => setCode(value.join(" "))} />
              {codeAlert && (
                <Typography
                  sx={{
                    fontSize: "13px",
                    marginTop: "-10px",
                    color: "#e83750",
                    marginBottom: "-20px",
                  }}
                >
                  O código não está correto
                </Typography>
              )}

              <Button
                variant="contained"
                disabled={!captcha}
                onClick={handleNextUpdatePassword}
                sx={{
                  width: "50%",
                  borderRadius: "5px",
                  marginTop: "20px",
                  height: "40px",
                  marginBottom: "20px",
                }}
              >
                Verificar
              </Button>

              <Interval onTimeout={(value) => setRev(true)} ref={intervalRef} />
              {rev && (
                <Link
                  onClick={() => {
                    handleSend();
                    handleStartCounter();
                    setRev(false);
                  }}
                  href="#"
                  sx={{
                    width: "100%",
                    textDecoration: "none!important",
                    marginBottom: "20px",
                    textAlign: "center",
                  }}
                >
                  Reenviar email de redefinição
                </Link>
              )}
            </>
          )}
        </Stack>
      </Paper>

      <Snackbar
        autoHideDuration={6000}
        open={alertOpen}
        onClose={() => {
          setAlertOpen(false);
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert severity="error">Erro: Esse email não está cadastrado</Alert>
      </Snackbar>

      <Snackbar
        autoHideDuration={6000}
        open={alertServiceOpen}
        onClose={() => {
          setAlertServiceOpen(false);
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert severity="error">
          Erro: Não foi possível se conectar ao Servidor
        </Alert>
      </Snackbar>
    </>
  );
}
