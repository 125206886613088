import { useEffect, useState } from "react";
import {
  Box,
  Paper,
  Stack,
  Typography,
  TextField,
  Button,
  Snackbar,
  Alert,
  Avatar,
  IconButton,
  InputAdornment,
  Autocomplete,
  DialogTitle,
  DialogContent,
  DialogActions,
  Chip,
  Tooltip,
  Dialog,
  Tabs,
  Tab,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  useTheme,
} from "@mui/material";
import "dayjs/locale/pt-br";

import {
  Add,
  Clear,
  Close,
  Delete,
  Edit,
  EmailRounded,
  Person,
  PersonOff,
  Search,
  Settings,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import { DataGrid } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import ModalPerfil from "./modalPerfil";
import AccessTimeFilledIcon from "@mui/icons-material/AccessTimeFilled";
import api from "../../../services/api";
import InternoModalDeleteCliente from "../../../components/internoModalDeleteCliente";

const usStyles = (theme) => ({
  ContainerBox: {
    padding: "1vw",
    width: "100%",
    alignItems: "center",

    display: "flex",
    flexDirection: "column",
  },
  paper: {
    alignItems: "center",
    width: "80%",
    padding: "20px",
    justifyContent: "center",
  },
  CabecalhoBox: {
    alignItems: "center",
    width: "80%",
    padding: "20px 0px",
    justifyContent: "center",
  },

  TextCampo: {
    width: "100%",
  },
  BoxStack: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    mb: "20px",
  },
  dataGrid: {
    "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
      py: "5px",
    },
    "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
      py: "5px",
    },
    "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
      py: "22px",
    },
    fontSize: 15,
    "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
      outline: "none !important",
    },
  },

  buttomAddUser: {
    fontSize: "16px",
    marginRight: "10px",
    height: "46px",
  },

  textDataGrid: {
    fontSize: "15px",
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
  },
});

export default function AdminPanel() {
  const [snackbar, setSnackbar] = useState(null);
  const [perfisDefault, setPerfisDefault] = useState([]);
  const [perfisClientesDefault, setPerfisClientesDefault] = useState([]);
  const [loadingPage, setLoadingPage] = useState(true);
  const [login, setLogin] = useState("");
  const [senha, setSenha] = useState("");
  const [showPass, setShowPass] = useState(false);
  const [clientes, setClientes] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [email, setEmail] = useState("");
  const [setor, setSetor] = useState("");
  const [cliente, setCliente] = useState(null);
  const [clienteFilter, setClienteFilter] = useState(null);
  const [habilitarCriar, setHabilitarCriar] = useState(false);
  const [openModalAddUser, setOpenModalAddUser] = useState(false);
  const [openModalPerfil, setOpenModalPerfil] = useState(false);
  const [profileOpenModal, setProfileOpenModal] = useState({});
  const [buscarUsuario, setBuscarUsuario] = useState("");
  const [tabValue, setTabValue] = useState(0);
  const [selectedRow, setSelectedRow] = useState({});
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const navigate = useNavigate();
  const theme = useTheme();
  const styles = usStyles(theme);

  const open = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleUsuarioId = () => {
    let result = JSON.parse(localStorage.getItem("user"));
    // Verifica se a propriedade rlPermissoesUsuario existe e é um array
    if (Array.isArray(result?.usuario?.rlPermissoesUsuario)) {
      // Itera sobre o array para verificar se algum objeto contém a propriedade codigoPermissao com valor "ADM"
      for (let permissao of result.usuario.rlPermissoesUsuario) {
        if (permissao?.permissao?.codigoPermissao.toUpperCase() === "ADM") {
          return true;
        }
      }
      return false;
    }
  };

  useEffect(() => {
    apigetPerfis();
    apiGetClientes();
  }, []);

  useEffect(() => {
    handleUsuarioId();

    if (handleUsuarioId() === false) {
      navigate("/interno/login");
    }
  }, []);

  useEffect(() => {
    if (login !== "" && senha !== "" && EmailRounded !== "") {
      setHabilitarCriar(true);
    } else {
      setHabilitarCriar(false);
    }
  }, [login, senha, setor]);

  async function apigetPerfis() {
    try {
      const responsePerfis = await api.get("/administracao/perfis");
      setPerfisDefault(responsePerfis?.data?.usuarios || []);
      setPerfisClientesDefault(responsePerfis?.data?.usuariosCliente || []);
      setLoadingPage(true);
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possivel se conectar ao servidor",
        severity: "error",
      });
    }
  }

  async function apiGetClientes() {
    try {
      const response = await api.get("/cliente/home/getClient");
      setClientes(response.data);

      setLoadingPage(true);
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possivel se conectar ao servidor",
        severity: "error",
      });
    }
  }

  async function handleApiNovoUsuario() {
    try {
      const response = await api.post(
        tabValue === 0 ? "/usuario/create" : "/create/userCliente",
        {
          login: login,
          senha: senha,
          email: email,
          status: 1,
          clienteId: cliente?.id,
        }
      );
      let responsePerfil = {};
      if (
        response?.data?.status === "Error" ||
        responsePerfil?.data?.status === "Error"
      ) {
        setSnackbar({
          children: "Error: não foi possivel salvar dados",
          severity: "error",
        });
      } else {
        tabValue === 0
          ? addUserInRows(response.data)
          : addUserClienteInRows(response.data);

        handleCloseModalAddUser();
        setLogin("");
        setSenha("");
        setSetor("");
        setEmail("");
        setSnackbar({
          children: "Dados salvo com sucesso",
          severity: "success",
        });
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: `Error:${
          error?.response?.data?.error ||
          error?.message ||
          "Não foi possível se conectar com o servidor"
        } `,
        severity: "error",
      });
    }
  }

  async function handleDeleteUser() {
    try {
      setLoadingDelete(true);
      const response = await api.post(`/administracao/deleteUser`, {
        id: selectedRow.id,
      });
      if (response?.data?.status === "Error") {
        setSnackbar({
          children: "Error: não foi possivel deletar o usuário",
          severity: "error",
        });
      } else {
        deleteUserInRows(selectedRow.id);
        setSnackbar({
          children: "Usuário deletado com sucesso",
          severity: "success",
        });
      }
      setOpenDeleteModal(false);
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: `Error: ${
          error?.response?.status === 404
            ? error?.response?.data?.error
            : "Não foi possivel se conectar com o servidor"
        }`,
        severity: "error",
      });
    } finally {
      setLoadingDelete(false);
    }
  }

  async function handleDeleteUserCliente() {
    try {
      setLoadingDelete(true);
      const response = await api.post("/administracao/deleteUserCliente", {
        id: selectedRow.id,
      });
      if (response?.data?.status === "Error") {
        setSnackbar({
          children: "Error: não foi possivel deletar o usuário",
          severity: "error",
        });
      } else {
        deleteUserClienteInRows(selectedRow.id);
        setSnackbar({
          children: "Usuário deletado com sucesso",
          severity: "success",
        });
      }
      setOpenDeleteModal(false);
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: `Error: ${
          error?.response?.status === 404
            ? error?.response?.data?.error
            : "Não foi possivel se conectar com o servidor"
        }`,
        severity: "error",
      });
    } finally {
      setLoadingDelete(false);
    }
  }

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    setSnackbar({
      children: "Campo copiado!",
      severity: "info",
    });
  };

  const filteredRows = perfisDefault?.filter((row, index) => {
    row.numero = index;

    return (
      row.login?.toLowerCase()?.includes(buscarUsuario?.toLowerCase()) ||
      row.nome?.toLowerCase()?.includes(buscarUsuario?.toLowerCase()) ||
      row.email?.toLowerCase()?.includes(buscarUsuario?.toLowerCase()) ||
      row.sobrenome?.toLowerCase()?.includes(buscarUsuario?.toLowerCase()) ||
      row.codigoPermissao?.toLowerCase()?.includes(buscarUsuario?.toLowerCase())
    );
  });

  const filteredClientesRows = perfisClientesDefault?.filter((row, index) => {
    row.numero = index;

    return (row.login?.toLowerCase()?.includes(buscarUsuario?.toLowerCase()) ||
      row.nome?.toLowerCase()?.includes(buscarUsuario?.toLowerCase()) ||
      row.email?.toLowerCase()?.includes(buscarUsuario?.toLowerCase()) ||
      row.sobrenome?.toLowerCase()?.includes(buscarUsuario?.toLowerCase()) ||
      row.codigoPermissao
        ?.toLowerCase()
        ?.includes(buscarUsuario?.toLowerCase())) &&
      clienteFilter?.id
      ? row?.clienteId == clienteFilter?.id
      : true;
  });

  function handleOpenModalAddUser() {
    setOpenModalAddUser(true);
  }

  function handleCloseModalAddUser() {
    setOpenModalAddUser(false);
    setLogin("");
    setSenha("");
    setSetor("");
  }

  function handleOpenModalPerfil() {
    setOpenModalPerfil(true);
  }

  function handleCloseModalPerfil() {
    setOpenModalPerfil(false);
    setProfileOpenModal({});
  }

  const addUserInRows = (newUser) => {
    const newRows = [...perfisDefault];
    newRows.push(newUser);
    setPerfisDefault(newRows);
  };

  const deleteUserInRows = (id) => {
    const newRows = perfisDefault.filter((row) => row.id !== id);
    setPerfisDefault(newRows);
  };
  const updateUserInRows = (newUser) => {
    const newRows = perfisDefault.map((row) => {
      if (row.id === newUser.id) {
        return newUser;
      }
      return row;
    });
    setPerfisDefault(newRows);
  };

  const addUserClienteInRows = (newUser) => {
    const newRows = [...perfisClientesDefault];
    newRows.push(newUser);
    setPerfisClientesDefault(newRows);
  };

  const deleteUserClienteInRows = (id) => {
    const newRows = perfisClientesDefault.filter((row) => row.id !== id);
    setPerfisClientesDefault(newRows);
  };

  const updateUserClienteInRows = (newUser) => {
    const newRows = perfisClientesDefault.map((row) => {
      if (row.id === newUser.id) {
        return newUser;
      }
      return row;
    });
    setPerfisClientesDefault(newRows);
  };

  const userCellView = (params) => {
    return (
      <Box
        sx={{
          cursor: "pointer",
          width: "100%",
          display: "flex",
          alignItems: "center",
          height: "100%",
        }}
      >
        <Tooltip title="Clique para copiar">
          <span
            onClick={() => {
              handleCopy(params.row.login);
            }}
          >
            <Typography sx={styles.textDataGrid}>{params.row.login}</Typography>
          </span>
        </Tooltip>
      </Box>
    );
  };

  const nomeCellView = (params) => {
    return (
      <>
        {params.row.nome !== null ? (
          <Box
            sx={{
              cursor: "pointer",
              width: "100%",
              display: "flex",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Tooltip title="Clique para copiar">
              <span
                onClick={() => {
                  handleCopy(`${params.row.nome} ${params.row.sobrenome}`);
                }}
              >
                <Typography sx={styles.textDataGrid}>
                  {params.row.nome} {params.row.sobrenome}
                </Typography>
              </span>
            </Tooltip>
          </Box>
        ) : (
          " "
        )}
      </>
    );
  };

  const emailCellView = (params) => {
    return (
      <>
        {params.row.email !== null ? (
          <Box
            sx={{
              cursor: "pointer",
              width: "100%",
              display: "flex",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Tooltip title="Clique para copiar">
              <span
                onClick={() => {
                  handleCopy(params.row.email);
                }}
              >
                <Typography sx={styles.textDataGrid}>
                  {params.row.email}
                </Typography>
              </span>
            </Tooltip>
          </Box>
        ) : (
          " "
        )}
      </>
    );
  };

  const asCellView = (params) => {
    return (
      <>
        {params.row.as !== null ? (
          <Box
            sx={{
              cursor: "pointer",
              width: "100%",
              display: "flex",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Tooltip title="Clique para copiar">
              <span
                onClick={() => {
                  handleCopy(params.row.as);
                }}
              >
                <Typography sx={styles.textDataGrid}>
                  {params.row.as}
                </Typography>
              </span>
            </Tooltip>
          </Box>
        ) : (
          " "
        )}
      </>
    );
  };

  const clienteCellView = (params) => {
    return (
      <>
        {params.row.cliente !== null ? (
          <Box
            sx={{
              cursor: "pointer",
              width: "100%",
              display: "flex",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Tooltip title="Clique para copiar">
              <span
                onClick={() => {
                  handleCopy(params.row.cliente);
                }}
              >
                <Typography sx={styles.textDataGrid}>
                  {params.row.cliente}
                </Typography>
              </span>
            </Tooltip>
          </Box>
        ) : (
          " "
        )}
      </>
    );
  };

  const permissoesCellView = (params) => {
    return (
      <>
        {params?.row?.codigoPermissao ? (
          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: "6px",
              alignItems: "center",
              justifyContent: "flex-start",
              overflow: "hidden",
              height: "100%",
            }}
          >
            {params?.row?.codigoPermissao
              ?.split(",")
              ?.map((permissao, index) => (
                <Chip
                  key={index}
                  label={permissao}
                  sx={{
                    backgroundColor: "chip.waitingReview",
                    height: "22px",
                    fontWeight: 500,
                    color: "#ffffff",
                  }}
                />
              ))}
          </Box>
        ) : params?.row?.ultimoAcesso !== undefined ? (
          ""
        ) : (
          " "
        )}
      </>
    );
  };

  const statusCellView = (params) => {
    return (
      <>
        {params?.row?.status == true && params?.row?.nome ? (
          <Box
            sx={{
              cursor: "pointer",
              display: "flex",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Tooltip title="Perfil ativo">
              <Person sx={{ color: "primary.main" }} />
            </Tooltip>
          </Box>
        ) : params?.row?.status === 0 ? (
          <Box sx={{ cursor: "pointer" }}>
            <Tooltip title="Perfil desativado">
              <PersonOff sx={{ color: "secondary.main" }} />
            </Tooltip>
          </Box>
        ) : (
          <Box
            sx={{
              cursor: "pointer",
              position: "relative",
              display: "flex",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Tooltip title="Aguardando primeiro login">
              <Box>
                <Person sx={{ color: "chip.inProgress" }} />
                <AccessTimeFilledIcon
                  sx={{
                    fontSize: "15px",
                    color: "chip.inProgress",
                    zIndex: 1,
                    position: "absolute",
                    bottom: 4,
                    left: 12,
                    bottom: 12,
                    borderRadius: "50%",
                    backgroundColor: "background.paper",
                  }}
                />
              </Box>
            </Tooltip>
          </Box>
        )}
      </>
    );
  };

  const lastLoginCellView = (params) => {
    function formatarData(dataISO) {
      // Crie um objeto Date a partir da string de data
      const data = new Date(dataISO);
      // Extraia o dia, mês e ano da data
      const dia = data?.getDate()?.toString()?.padStart(2, "0");
      const mes = (data?.getMonth() + 1)?.toString()?.padStart(2, "0"); // Os meses são indexados de 0 a 11
      const ano = data?.getFullYear();
      // Extraia a hora, minuto e segundo da data
      const hora = data?.getHours()?.toString()?.padStart(2, "0");
      const minuto = data?.getMinutes()?.toString()?.padStart(2, "0");
      const segundo = data?.getSeconds()?.toString()?.padStart(2, "0");
      // Retorne a data formatada no padrão brasileiro seguida pela hora

      return (
        <>
          {params?.row?.ultimoAcesso !== undefined ? (
            <Typography sx={{ textAlign: "center" }}>
              {dia}/{mes}/{ano} {hora}:{minuto}:{segundo}
            </Typography>
          ) : (
            " "
          )}
        </>
      );
    }
    return formatarData(params.row.ultimoAcesso);
  };

  const perfilCellView = (params) => {
    return (
      <Tooltip title="Visualizar perfil">
        <IconButton
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
            setSelectedRow(params.row);
          }}
          id="basic-button"
          disabled={
            JSON.parse(localStorage.getItem("user"))?.usuario?.id ===
            params.row.id
          }
        >
          <Settings
            mode="view"
            params={params}
            sx={{
              color:
                JSON.parse(localStorage.getItem("user"))?.usuario?.id ===
                params.row.id
                  ? "chip.arquive"
                  : "primary.main",
            }}
          />
        </IconButton>
      </Tooltip>
    );
  };

  const colunas = [
    {
      headerAlign: "center",
      align: "center",
      sortable: false,
      field: "foto",
      headerName: "",
      width: 60,
      renderCell: (params) => {
        const foto = params.row.foto ? params.row.foto : "";
        return (
          <Avatar
            alt={params.row.foto ? params.row.nome : ""}
            src={foto ? foto : "/"}
            sx={{ width: "34px", height: "34px", margin: "5px 0px" }}
          />
        );
      },
    },
    {
      editable: false,
      field: "login",
      headerName: "Login",
      width: 180,
      renderCell: userCellView,
    },
    {
      editable: false,
      field: "nome",
      headerName: "Nome",
      flex: 1,
      renderCell: nomeCellView,
    },
    {
      editable: false,
      field: "email",
      headerName: "E-mail",
      flex: 1,
      renderCell: emailCellView,
    },
    ...(tabValue === 0
      ? [
          {
            editable: false,
            field: "codigoPermissao",
            headerName: "Permissoes",
            flex: 1,
            renderCell: permissoesCellView,
          },
        ]
      : [
          {
            editable: false,
            field: "cliente",
            headerName: "Cliente",
            flex: 1,
            renderCell: clienteCellView,
          },
          {
            editable: false,
            field: "as",
            headerName: "ASN",
            flex: 1,
            renderCell: asCellView,
          },
        ]),

    {
      headerAlign: "center",
      align: "center",

      editable: false,
      field: "status",
      headerName: "Status",
      width: 80,
      renderCell: statusCellView,
    },
    {
      headerAlign: "center",
      align: "center",
      sortable: false,
      editable: false,
      field: "ultimoAcesso",
      headerName: "Último acesso",
      width: 140,
      renderCell: lastLoginCellView,
    },
    {
      headerAlign: "center",
      align: "center",
      sortable: false,
      editable: false,
      field: "Detalhes",
      headerName: "Detalhes",
      width: 90,
      renderCell: perfilCellView,
    },
  ];

  return (
    <>
      <Box sx={styles.ContainerBox}>
        <Box sx={styles.CabecalhoBox}>
          <Typography width="100%" variant="h5">
            Painel de Administração
          </Typography>
        </Box>
        <Paper sx={{ mb: 2, borderRadius: "10px" }}>
          <Tabs
            value={tabValue}
            onChange={(event, newValue) => {
              setTabValue(newValue);
            }}
          >
            <Tab label="Computize" value={0} />
            <Tab label="Clientes" value={1} />
          </Tabs>
        </Paper>
        <Paper
          sx={{
            ...styles.paper,
            backgroundColor: theme.palette.mode === "dark" && "transparent",
          }}
          variant={theme.palette.mode === "dark" ? "outlined" : "elevation"}
          elevation={3}
        >
          {!loadingPage && <>{/* tela de carregamento */}</>}

          {loadingPage && (
            <Stack>
              {tabValue === 0 && (
                <>
                  <Stack sx={styles.BoxStack}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <TextField
                        variant="filled"
                        label="Buscar usuário"
                        value={buscarUsuario}
                        size="small"
                        onChange={(event) => {
                          setBuscarUsuario(event.target.value);
                        }}
                        sx={{ width: "280px" }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Search />
                            </InputAdornment>
                          ),
                          endAdornment: buscarUsuario ? (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => setBuscarUsuario("")}
                                edge="end"
                              >
                                <Clear />
                              </IconButton>
                            </InputAdornment>
                          ) : null,
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        endIcon={<Add sx={{ marginTop: "-3px" }} />}
                        onClick={handleOpenModalAddUser}
                        sx={styles.buttomAddUser}
                        variant="contained"
                      >
                        Novo Usuário
                      </Button>
                    </Box>
                  </Stack>

                  <Stack spacing={2} alignItems={"center"} width={"100%"}>
                    {tabValue === 0 && (
                      <Box sx={{ width: "100%" }} key={"user-datagrid"} s>
                        <DataGrid
                          key={"cliente-datagrid"}
                          rowsPerPageOptions={[25, 50, 100]}
                          getRowHeight={() => "auto"}
                          rows={filteredRows}
                          columns={colunas}
                          initialState={{
                            pagination: { paginationModel: { pageSize: 25 } },
                          }}
                          disableColumnMenu
                          disableColumnFilter
                          disableSelectionOnClick
                          autoHeight={true}
                          sx={styles.dataGrid}
                        />
                      </Box>
                    )}
                  </Stack>
                </>
              )}
              {tabValue === 1 && (
                <>
                  <Stack sx={styles.BoxStack}>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <TextField
                        label="Buscar Usuário Cliente"
                        variant="filled"
                        value={buscarUsuario}
                        size="small"
                        onChange={(event) => {
                          setBuscarUsuario(event.target.value);
                        }}
                        sx={{ width: "280px" }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <Search />
                            </InputAdornment>
                          ),
                          endAdornment: buscarUsuario ? (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => setBuscarUsuario("")}
                                edge="end"
                              >
                                <Clear />
                              </IconButton>
                            </InputAdornment>
                          ) : null,
                        }}
                      />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 2,
                      }}
                    >
                      <Autocomplete
                        sx={{ width: "280px" }}
                        value={clienteFilter}
                        onChange={(event, newValue) => {
                          setClienteFilter(newValue);
                        }}
                        size="small"
                        options={clientes}
                        getOptionLabel={(option) => option.nomeFantasia}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="filled"
                            label="Cliente"
                            color="primary"
                          />
                        )}
                      />
                      <Button
                        endIcon={<Add sx={{ marginTop: "-3px" }} />}
                        onClick={handleOpenModalAddUser}
                        sx={styles.buttomAddUser}
                        variant="contained"
                      >
                        Novo Usuário Cliente
                      </Button>
                    </Box>
                  </Stack>

                  <Stack spacing={2} alignItems={"center"}>
                    <Box sx={{ width: "100%" }}>
                      <DataGrid
                        editMode="row"
                        rowsPerPageOptions={[25, 50, 100]}
                        getRowHeight={() => "auto"}
                        rows={filteredClientesRows}
                        columns={colunas}
                        initialState={{
                          pagination: { paginationModel: { pageSize: 25 } },
                        }}
                        disableColumnMenu
                        disableColumnFilter
                        disableSelectionOnClick
                        autoHeight={true}
                        sx={styles.dataGrid}
                      />
                    </Box>
                  </Stack>
                </>
              )}
            </Stack>
          )}
        </Paper>
        <Dialog
          open={openModalAddUser}
          onClose={handleCloseModalAddUser}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Paper sx={{ width: "450px" }}>
            <DialogTitle color="primary.main">
              Cadastro de Usuários {tabValue === " Clientes"}
            </DialogTitle>
            <DialogContent
              sx={{ display: "flex", flexDirection: "column", gap: 1.5 }}
            >
              {tabValue === 1 && (
                <Autocomplete
                  sx={styles.TextCampo}
                  value={cliente}
                  onChange={(event, newValue) => {
                    setCliente(newValue);
                  }}
                  options={clientes}
                  getOptionLabel={(option) => option.nomeFantasia}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="filled"
                      label="Cliente"
                      color="primary"
                    />
                  )}
                />
              )}
              <TextField
                sx={styles.TextCampo}
                label="Login"
                id="login"
                variant="filled"
                color="primary"
                value={login}
                size="small"
                onChange={(event) => {
                  setLogin(event.target.value);
                }}
                autoComplete="off"
              />
              <TextField
                sx={styles.TextCampo}
                label="Email"
                id="email"
                variant="filled"
                type="email"
                size="small"
                color="primary"
                value={email}
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
                autoComplete="off"
              />
              <TextField
                sx={styles.TextCampo}
                autoComplete="off"
                name="senha"
                label="Senha"
                size="small"
                variant="filled"
                color="primary"
                type={showPass ? "text" : "password"}
                value={senha}
                onChange={(event) => {
                  setSenha(event.target.value);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        onClick={() => setShowPass((event) => !event)}
                      >
                        {showPass ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleCloseModalAddUser}
                sx={{ color: "secondary.main" }}
                variant="text"
              >
                Cancelar
              </Button>
              <Button
                disabled={!habilitarCriar}
                variant="text"
                onClick={handleApiNovoUsuario}
              >
                Cadastrar
              </Button>
            </DialogActions>
          </Paper>
        </Dialog>
        <ModalPerfil
          key={selectedRow.id}
          id={selectedRow.id}
          openModalPerfil={openModalPerfil}
          handleCloseModalPerfil={handleCloseModalPerfil}
          profileOpenModal={profileOpenModal}
          clientes={clientes}
          updateUserInRows={updateUserInRows}
          updateUserClienteInRows={updateUserClienteInRows}
          tabValue={tabValue}
        />
      </Box>
      <Menu
        sx={{ zIndex: 999 }}
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        disableScrollLock={true}
      >
        <MenuItem
          onClick={() => {
            setProfileOpenModal(selectedRow);
            handleOpenModalPerfil();
            setAnchorEl(null);
          }}
        >
          <ListItemIcon>
            <Edit />
          </ListItemIcon>
          <ListItemText>Editar</ListItemText>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpenDeleteModal(true);
            setAnchorEl(null);
          }}
          disabled={loadingDelete}
        >
          <ListItemIcon>
            <Delete color="error" />
          </ListItemIcon>
          <ListItemText>Excluir</ListItemText>
        </MenuItem>
      </Menu>
      <InternoModalDeleteCliente
        openDeleteModal={openDeleteModal}
        load={loadingDelete}
        setOpenDeleteModal={setOpenDeleteModal}
        execute={() => {
          tabValue === 0 ? handleDeleteUser() : handleDeleteUserCliente();
          setAnchorEl(null);
        }}
        severity={"error"}
        // contentText={}
        // confirmText={}
      />
      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2500}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
    </>
  );
}
