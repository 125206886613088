import React, { useContext, useEffect, useState } from "react";
import {
  Alert,
  Box,
  IconButton,
  LinearProgress,
  Modal,
  Paper,
  Snackbar,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import Mapa from "./mapa";
import LineChart from "./charts/line";
import CountriesTable from "./tables/countries";
import RecentEventsTable from "./tables/recentEvents";
import useStyles from "./styles";
import PieChart from "./charts/pie";
import Grid from "@mui/material/Unstable_Grid2";
import api from "../../../services/apiCliente";

import dayjs from "dayjs";
import { RangeContext } from "../range";
import { OpenInFull } from "@mui/icons-material";
import Prefix from "./prefix";
import { set } from "react-hook-form";

export default function HomeClient() {
  const [rows, setRows] = useState(initialRows());
  const [openModal, setOpenModal] = useState(false);
  const [snackbar, setSnackbar] = useState(null);
  const { Range, setRange } = useContext(RangeContext);
  const [tableSelected, setTableSelected] = useState("country");
  const [loading, setLoading] = useState(false);
  const clienteId =
    JSON.parse(localStorage.getItem("userCliente"))?.usuarioCliente
      ?.clienteId || "";

  const [loadingEventsList, setLoadingEventsList] = useState(false);
  const [loadingOpTrafficPps, setLoadingOpTrafficPps] = useState(false);
  const [loadingPrefix, setLoadingPrefix] = useState(false);
  const [loadingRecentEvents, setLoadingRecentEvents] = useState(false);
  const [loadingNetworkSummary, setLoadingNetworkSummary] = useState(false);
  const [loadingOpTraffic, setLoadingOpTraffic] = useState(false);
  const [fistRender, setFistRender] = useState(false);
  const [data, setData] = useState({
    mitigation_bps: [],
    mitigation_pps: [],
  });
  const [networkSummary, setNetworkSummary] = useState([
    { networkSummary: [], networkSummaryPps: [] },
  ]);
  const [prefix, setPrefix] = useState([]);
  const [recentEvents, setRecentEvents] = useState([]);
  const [eventsList, setEventsList] = useState([]);
  const [controller, setController] = useState(null);

  const theme = useTheme();
  const styles = useStyles();
  const handleSelectedTable = (table) => {
    setTableSelected(table);
  };

  const maxValuebps = Math.max(
    ...(data[
      `${Range.unidade === "bps" ? "mitigation_bps" : "mitigation_pps"}`
    ]?.clean?.map((d) => d?.value || 0) || [0]),
    ...(data[
      `${Range.unidade === "bps" ? "mitigation_bps" : "mitigation_pps"}`
    ].bot?.map((d) => d?.value || 0) || [0])
  );

  useEffect(() => {
    if (clienteId) {
      setFistRender(true);
    } else {
      setFistRender(false);
    }
  }, [Range.range, Range.cliente]);

  useEffect(() => {
    if (fistRender) {
      fetchData("sem timer");
      setFistRender(false);
    }

    //const intervalId = setInterval(fetchData, 60000); // Call every 1 minute

    const timer = setInterval(() => {
      if (Math.round(Range.timer) >= 60 && !loading) {
        fetchData("com timer");
      }
      if (!loading) {
        setRange((prevRange) => ({
          ...prevRange,
          timer: prevRange.timer + 1,
        }));
      }
    }, 1000);

    //return () => clearInterval(intervalId); // Clear interval on component unmount
    return () => {
      //clearInterval(intervalId);
      clearInterval(timer);
    };
  }, [fistRender, loading, Range.timer]);

  async function apiHandleOpTraffic(signal) {
    try {
      setLoadingOpTraffic(true);
      const response = await api.post(
        "/cliente/zabbix/findHistory",
        {
          clienteId: clienteId,
          range: Range.range || "hour",
        },
        { signal }
      );
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível carregar os valores",
          severity: "error",
        });
      } else {
        if (response.data) {
          setData((prevData) => ({
            ...prevData,
            mitigation_bps: response.data || [],
          }));
        }
      }
    } catch (error) {
      if (error.name === "CanceledError") {
        console.log("Requisição de tráfego bps cancelada");
      } else {
        setData((prevData) => ({
          ...prevData,
          mitigation_bps: [],
        }));
        setSnackbar({
          children: `Error:${
            error?.response?.data?.error ||
            "Não foi possível se buscar valores de tráfego"
          } `,
          severity: "error",
        });
      }
    } finally {
      setLoadingOpTraffic(false);
    }
  }

  async function apiHandleOpTrafficPps(signal) {
    try {
      setLoadingOpTrafficPps(true);
      const response = await api.post(
        "/cliente/zabbix/findHistoryPps",
        {
          clienteId: clienteId,
          range: Range.range || "hour",
        },
        { signal }
      );
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível carregar os valores",
          severity: "error",
        });
      } else {
        if (response.data) {
          setData((prevData) => ({
            ...prevData,
            mitigation_pps: response.data || [],
          }));
        }
      }
    } catch (error) {
      if (error.name === "CanceledError") {
        console.log("Requisição de tráfego bps cancelada");
      } else {
        setSnackbar({
          children: `Error:${
            error?.response?.data?.error ||
            "Não foi possível se buscar valores de tráfego"
          } `,
          severity: "error",
        });
        setData((prevData) => ({
          ...prevData,
          mitigation_pps: [],
        }));
      }
    } finally {
      setLoadingOpTrafficPps(false);
    }
  }
  async function apiHandleNetworkSummary(signal) {
    try {
      setLoadingNetworkSummary(true);
      const response = await api.post(
        "/cliente/nexus/networkSummary",
        {
          clienteId: clienteId || "",
          range: Range.range || "hour",
        },
        { signal }
      );
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível carregar os valores",
          severity: "error",
        });
      } else {
        if (response.data) {
          setNetworkSummary(() => ({
            ...networkSummary,
            networkSummary: response.data.networkSummary || [],
            networkSummaryPps: response.data.networkSummaryPps || [],
          }));
        }
      }
    } catch (error) {
      if (error.name === "CanceledError") {
        console.log("Requisição resumo de rede cancelada");
      } else {
        setSnackbar({
          children: `Error:${
            error?.message || "Não foi possível buscar informações da rede"
          } `,
          severity: "error",
        });
        setNetworkSummary(() => ({
          ...networkSummary,
          networkSummary: [],
          networkSummaryPps: [],
        }));
      }
    } finally {
      setLoadingNetworkSummary(false);
    }
  }
  async function apiHandleRecentEvents(signal) {
    try {
      setLoadingRecentEvents(true);
      const response = await api.post(
        "/cliente/wanguard/findTable",
        {
          clienteId: clienteId || "",
          range: Range.range || "hour",
        },
        { signal }
      );
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível carregar os valores",
          severity: "error",
        });
      } else {
        if (response.data) {
          setRecentEvents(response.data);
        }
      }
    } catch (error) {
      if (error.name === "CanceledError") {
        console.log("Requisição de eventos cancelada");
      } else {
        setSnackbar({
          children: `Error:${
            error?.message || "Não foi possível buscar eventos Recentes"
          } `,
          severity: "error",
        });
        setRecentEvents([]);
      }
    } finally {
      setLoadingRecentEvents(false);
    }
  }
  async function apiHandleEventsList() {
    try {
      setLoadingEventsList(true);
      const response = await api.post("/cliente/nexus/eventsList", {
        clienteId: clienteId || "",
        range: Range.range || "hour",
      });
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível carregar os valores",
          severity: "error",
        });
      } else {
        if (response.data) {
          setEventsList(response.data);
        }
      }
    } catch (error) {
      setSnackbar({
        children: `Error:${"Não foi possível buscar Lista de Eventos"} `,
        severity: "warning",
      });
    } finally {
      setLoadingEventsList(false);
    }
  }
  async function apiHandlePrefix(signal) {
    try {
      setLoadingPrefix(true);
      const response = await api.post(
        "/cliente/nexus/prefix",
        {
          clienteId: clienteId || "",
          //range: Range.range || "hour",
        },
        { signal }
      );
      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: Não foi possível carregar os valores",
          severity: "error",
        });
      } else {
        if (response.data) {
          setPrefix(() => response.data || []);
        }
      }
    } catch (error) {
      if (error.name === "CanceledError") {
        console.log("Requisição de tráfego bps cancelada");
      } else {
        setSnackbar({
          children: `Error:${
            error?.message || "Não foi possível buscar prefixos"
          } `,
          severity: "error",
        });
        setPrefix([]);
      }
    } finally {
      setLoadingPrefix(false);
    }
  }

  const fetchData = async (verbose) => {
    if (controller) {
      controller.abort(); // Abortar a requisição anterior
    }

    const newController = new AbortController(); // Criar novo AbortController
    const signal = newController.signal; // Obter o sinal

    setController(newController); // Guardar o novo controller no estado
    try {
      if (clienteId) {
        setLoading(true);
        setRange({ ...Range, timerStatus: true, timer: -100 });
        await Promise.all([
          apiHandleOpTraffic(signal),
          apiHandleNetworkSummary(signal),
          apiHandleRecentEvents(signal),
          apiHandleOpTrafficPps(signal),
          // apiHandleEventsList(),
          apiHandlePrefix(signal),
        ]);
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: `Error:${
          error?.message || "Erro durante a solicitação de rotas"
        } `,
        severity: "error",
      });
    } finally {
      //   { ...Range, timerStatus: true, timer: 0 }
      setRange((prevRange) => ({
        ...prevRange,
        timerStatus: true,
        timer: 0,
      }));
      setLoading(false);
      setFistRender(false);
    }
  };

  const formatBytes = (bytes) => {
    if (bytes === 0) return "0 bps";
    const k = 1000;
    const sizes = ["bps", "Kbps", "Mbps", "Gbps", "Tbps", "Pbps", "Ebps"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
  };
  const formatPackge = (bytes) => {
    if (bytes === 0) return "0 pps";
    const k = 1000;
    const sizes = ["pps", "Kpps", "Mpps", "Gbps", "Tpps", "Ppps", "Epps"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
  };

  const formatPackgeAcumulate = (bytes) => {
    if (bytes === 0) return "0 pps";
    const k = 1000;
    const sizes = ["M", "K", "M", "G", "T", "P", "E"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
  };

  const totalSize = networkSummary?.[
    `${Range.unidade === "bps" ? "networkSummary" : "networkSummaryPps"}`
  ]?.country?.reduce((acc, country) => acc + (country.size || 0), 0);

  return (
    <Box sx={styles.container}>
      {(loadingOpTraffic || loadingOpTrafficPps) && (
        <Box sx={{ width: "100%", position: "absolute" }}>
          <LinearProgress />
        </Box>
      )}
      {/* <Box sx={{ display: "flex", width: "100%" }}></Box>*/}
      <Grid
        container
        spacing={2}
        columns={{ xs: 2, sm: 4, md: 6, lg: 6, xl: 6, xxl: 6 }}
        flexGrow={1}
      >
        <Grid xs={2} sm={4} md={6} lg={6} xl={3}>
          <Paper
            sx={styles.paper}
            elevation={theme.palette.mode === "dark" ? 0 : 3}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                width: "100%",
              }}
            >
              <Typography
                sx={{ ...styles.cardSubText, color: "secondary.main" }}
              >
                Tráfego DDOS
              </Typography>
              <LineChart
                type={"sujo"}
                range={Range}
                maxValuebps={maxValuebps}
                data={
                  data?.[
                    `${
                      Range.unidade === "bps"
                        ? "mitigation_bps"
                        : "mitigation_pps"
                    }`
                  ]?.bot?.map((bot, index) => {
                    let result = {};

                    (result = {
                      date: dayjs(bot.date).format(
                        Range === "hour"
                          ? "HH:mm"
                          : Range === "day"
                          ? "HH:mm"
                          : Range === "week"
                          ? "DD/MM HH:mm"
                          : "DD/MM HH:mm"
                      ),
                      dirtyTraffic: bot.value,
                    }) || (result = { date: 0, dirtyTraffic: 0 });

                    return result;
                  }) || []
                }
              />
            </Box>
          </Paper>
        </Grid>

        <Grid xs={2} sm={4} md={6} lg={6} xl={3}>
          <Paper
            sx={styles.paper}
            elevation={theme.palette.mode === "dark" ? 0 : 3}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                width: "100%",
              }}
            >
              <Typography sx={{ ...styles.cardSubText, color: "#00A76F" }}>
                Tráfego Limpo
              </Typography>
              <LineChart
                load={loading}
                type={"limpo"}
                maxValuebps={maxValuebps}
                range={Range}
                data={
                  data?.[
                    `${
                      Range.unidade === "bps"
                        ? "mitigation_bps"
                        : "mitigation_pps"
                    }`
                  ]?.clean?.map((clean, index) => {
                    let result = {};

                    (result = {
                      date: dayjs(clean.date).format(
                        Range === "hour"
                          ? "HH:mm"
                          : Range === "day"
                          ? "HH:mm"
                          : Range === "week"
                          ? "DD/MM HH:mm"
                          : "DD/MM HH:mm"
                      ),
                      cleanTraffic: clean.value,
                    }) || (result = { date: 0, cleanTraffic: 0 });

                    return result;
                  }) || []
                }
              />
            </Box>
          </Paper>
        </Grid>

        <Grid xs={2} sm={4} md={6} lg={6}>
          <Grid
            container
            spacing={2}
            columns={{ xs: 2, sm: 4, md: 8, lg: 8, xl: 8, xxl: 8 }}
            flexGrow={1}
          >
            <Grid xs={2} sm={4} md={8} lg={4}>
              <Paper
                sx={{ ...styles.paper }}
                elevation={theme.palette.mode === "dark" ? 0 : 3}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 1,
                    width: "100%",
                    position: "relative",
                    height: "100%",
                  }}
                >
                  <Typography sx={styles.cardSubText}>
                    Prefixos Recebidos
                  </Typography>
                  <Prefix
                    rows={
                      prefix?.map((prefix, index) => ({
                        ...prefix,
                        id: index,
                        timestamp: dayjs(prefix.timestamp).format(
                          "DD/MM/YYYY HH:mm:ss"
                        ),
                      })) || []
                    }
                    hideFooter={true}
                    load={loadingPrefix}
                  />
                </Box>
              </Paper>
            </Grid>
            <Grid xs={2} sm={4} md={8} lg={4}>
              <Paper
                sx={styles.paper}
                elevation={theme.palette.mode === "dark" ? 0 : 3}
              >
                <Box sx={{ width: "100%" }} overflow={"auto"}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      //flexGrow: 1,
                      justifyContent: "space-between",
                      width: "97.6%",
                    }}
                  >
                    <Typography sx={styles.cardSubText}>
                      Informações da Rede
                    </Typography>
                    <ToggleButtonGroup
                      color="primary"
                      value={tableSelected}
                      exclusive
                      //onChange={handleChange}
                      aria-label="Platform"
                      size="small"
                    >
                      <ToggleButton
                        value="country"
                        onClick={() => {
                          handleSelectedTable("country");
                        }}
                      >
                        Países
                      </ToggleButton>
                      <ToggleButton
                        value="proto"
                        onClick={() => {
                          handleSelectedTable("proto");
                        }}
                      >
                        Protocolos
                      </ToggleButton>
                      <ToggleButton
                        value="as_num"
                        onClick={() => {
                          handleSelectedTable("as_num");
                        }}
                      >
                        ASN
                      </ToggleButton>
                      <ToggleButton
                        value="ip"
                        onClick={() => {
                          handleSelectedTable("ip");
                        }}
                      >
                        IP Atacado
                      </ToggleButton>
                    </ToggleButtonGroup>
                  </Box>
                  <CountriesTable
                    load={loadingNetworkSummary}
                    tableSelected={tableSelected}
                    rows={
                      networkSummary?.networkSummary?.country &&
                      Array.from(networkSummary?.networkSummary?.country)
                        ? networkSummary?.[
                            `${
                              Range.unidade === "bps"
                                ? "networkSummary"
                                : "networkSummaryPps"
                            }`
                          ]?.[`${tableSelected}`].map((country, index) => ({
                            ...country,
                            id: index,
                            quantidade: formatPackgeAcumulate(
                              country?.size || 0
                            ),
                            code: country.code,
                            pais: country.country,
                            porcentagem:
                              ((country.size || 0) / totalSize) * 100,
                          }))
                        : []
                    }
                  />
                </Box>
              </Paper>
            </Grid>

            {/*columns={{ xs: 2, sm: 4, md: 6, lg: 6, xl: 6, xxl: 6 }} */}
            {/* <Grid xs={2} sm={4} md={4} lg={2}>
              <Paper
                sx={styles.littlePaper}
                elevation={theme.palette.mode === "dark" ? 0 : 3}
              >
                <Box
                  sx={styles.boxPieChart}
                  name={"country"}
                  onClick={() => {
                    handleSelectedTable("country");
                  }}
                >
                  <Box loading={true} sx={{ position: "absolute" }}>
                    <Typography sx={styles.cardSubTextPie}>Países</Typography>
                  </Box>
                  <PieChart
                    data={
                      networkSummary?.networkSummary?.country &&
                      Array.from(networkSummary?.networkSummary?.country)
                        ? networkSummary?.[
                            `${
                              Range.unidade === "bps"
                                ? "networkSummary"
                                : "networkSummaryPps"
                            }`
                          ]?.country.map((country) => ({
                            name: country.country,
                            size: country.size,
                          }))
                        : []
                    }
                  />
                </Box>
              </Paper>
            </Grid>

            <Grid xs={2} sm={4} md={4} lg={2}>
              <Paper
                sx={styles.littlePaper}
                elevation={theme.palette.mode === "dark" ? 0 : 3}
              >
                <Box
                  sx={styles.boxPieChart}
                  onClick={() => {
                    handleSelectedTable("proto");
                  }}
                >
                  <Box sx={{ position: "absolute" }}>
                    <Typography sx={styles.cardSubTextPie}>
                      Protocolos
                    </Typography>
                  </Box>
                  <PieChart
                    data={
                      networkSummary?.networkSummary?.app &&
                      Array.from(networkSummary?.networkSummary?.proto)
                        ? networkSummary?.[
                            `${
                              Range.unidade === "bps"
                                ? "networkSummary"
                                : "networkSummaryPps"
                            }`
                          ]?.proto?.map((proto) => ({
                            name: proto.proto,
                            size: proto.size,
                          }))
                        : []
                    }
                  />
                </Box>
              </Paper>
            </Grid>
            <Grid xs={2} sm={4} md={4} lg={2}>
              <Paper
                sx={styles.littlePaper}
                elevation={theme.palette.mode === "dark" ? 0 : 3}
              >
                <Box
                  sx={styles.boxPieChart}
                  onClick={() => {
                    handleSelectedTable("as_num");
                  }}
                >
                  <Box sx={{ position: "absolute" }}>
                    <Typography sx={styles.cardSubTextPie}>ASN</Typography>
                  </Box>
                  <PieChart
                    data={
                      networkSummary?.networkSummary?.as_num &&
                      Array.from(networkSummary?.networkSummary?.as_num)
                        ? networkSummary?.[
                            `${
                              Range.unidade === "bps"
                                ? "networkSummary"
                                : "networkSummaryPps"
                            }`
                          ]?.as_num?.map((as_num) => ({
                            name: as_num.as,
                            size: as_num.size,
                          }))
                        : []
                    }
                  />
                </Box>
              </Paper>
            </Grid>
            <Grid xs={2} sm={4} md={4} lg={2}>
              <Paper
                sx={styles.littlePaper}
                elevation={theme.palette.mode === "dark" ? 0 : 3}
              >
                <Box
                  sx={styles.boxPieChart}
                  onClick={() => {
                    handleSelectedTable("ip");
                  }}
                >
                  <Box sx={{ position: "absolute" }}>
                    <Typography sx={styles.cardSubTextPie}>
                      IP Atacado
                    </Typography>
                  </Box>
                  <PieChart
                    data={
                      networkSummary?.networkSummary?.ip &&
                      Array.from(networkSummary?.networkSummary?.ip)
                        ? networkSummary?.[
                            `${
                              Range.unidade === "bps"
                                ? "networkSummary"
                                : "networkSummaryPps"
                            }`
                          ]?.ip?.map((ip) => ({
                            name: ip.ip,
                            size: ip.size,
                          }))
                        : []
                    }
                  />
                </Box>
              </Paper>
            </Grid> */}
          </Grid>
        </Grid>
        <Grid xs={2} sm={4} md={6} lg={6} xl={6}>
          <Paper
            sx={{ ...styles.paper, height: "auto" }}
            elevation={theme.palette.mode === "dark" ? 0 : 3}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1,
                width: "100%",
                position: "relative",
              }}
            >
              <Typography sx={styles.cardSubText}>Eventos Recentes</Typography>

              <Tooltip
                title="Ver todos os eventos"
                placement="top"
                sx={{ position: "absolute", right: -8, top: -12 }}
                onClick={(event) => {
                  event.stopPropagation();
                  setOpenModal(true);
                  apiHandleEventsList();
                }}
              ></Tooltip>
              <RecentEventsTable
                rows={
                  recentEvents.map((event) => {
                    return {
                      dataInicial: parseInt(event?.from?.unixtime) || "",
                      dataFinal: parseInt(event?.until?.unixtime) || "",
                      impact: formatBytes(event?.bits / 1000 || 0),
                      impactPps: formatPackge(event?.packets / 100 || 0),
                      ...event,
                    };
                  }) || []
                }
                load={loadingRecentEvents}
              />
            </Box>
          </Paper>
        </Grid>
      </Grid>
      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
    </Box>
  );
}
const initialRows = () => {
  const dados = [];
  for (let i = 0; i < 100; i++) {
    const randomValue = Math.random() * 100;
    dados.push({
      id: i,
      pais: `Brasil`,
      code: `BR`,
      porcentagem: randomValue,
      quantidade: `${randomValue.toFixed(2)} Gbps`,
    });
  }
  return dados;
};
