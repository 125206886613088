const styles = (props) => ({
  container: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    "@media (max-width: 1300px)": {
      width: "100%",
    },
  },
  containerPaper: {
    display: "flex",
    width: "52vw",
    flexDirection: "column",
    padding: "10px",
    bgcolor: "background.default",
    "@media (max-width: 1300px)": {
      width: "100%",
    },
  },
  tabPainel: {
    display: "flex",

    "@media (max-width: 1300px)": {
      flexWrap: "wrap",
      justifyContent: "center",
    },
  },
  boxLeft: {
    width: "50%",
    marginRight: "10px",
    "@media (max-width: 1300px)": {
      width: "100%",
      margin: 0,
    },
  },
  boxInforClients: {
    display: "flex",
    justifyContent: "space-between",
    margin: "15px 0",
    "@media (max-width: 1300px)": {
      flexDirection: "column",
    },
  },
  textFieldNameClient: {
    width: "220px",
    marginRight: "10px",
    "@media (max-width: 1300px)": {
      width: "100%",
    },
  },
  textFieldcnpj: {
    width: "220px",
    "@media (max-width: 1300px)": {
      width: "100%",
      marginTop: "15px",
    },
  },
  boxConfigIP: {
    display: "flex",
    flexDirection: "column",
    margin: "15px 0",
  },
  boxRight: {
    width: "50%",
    "@media (max-width: 1300px)": {
      width: "100%",
    },
  },
  boxConfigTunel: {
    display: "flex",
    flexDirection: "column",
    margin: "15px 0",
  },
  boxPlano: {
    display: "flex",
    flexDirection: "column",
    margin: "15px 0",
  },
  boxPrefixo: {
    display: "flex",
    flexDirection: "column",
  },
  boxPrefixos: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    marginTop: "10px",
    "@media (max-width: 660px)": {
      flexDirection: "column",
    },
  },

  containerPrefixo: {
    width: "50%",
    display: "flex",
    alignItems: "center",
    margin: "5px 0",
    "@media (max-width: 660px)": {
      width: "100%",
    },
  },
  boxButtons: {
    display: "flex",
    justifyContent: "end",
    margin: "0 25px",
  },
});

export default styles;
