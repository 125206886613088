const validarIp = (ip, options = "") => {
  const ipv6Regex =
    /^(?:(?:[A-F0-9]{1,4}(?::[A-F0-9]{1,4})*)?::)?(?:[A-F0-9]{1,4}(?::[A-F0-9]{1,4})*)?$/i;
  const ipv6RegexWithMask =
    /^(?:(?:[A-F0-9]{1,4}(?::[A-F0-9]{1,4})*)?::)?(?:[A-F0-9]{1,4}(?::[A-F0-9]{1,4})*)?\/(12[0-8]|1[01][0-9]|[1-9]?[0-9])$/i;
  const ipv6RegexWithPort =
    /^(?:(?:[A-F0-9]{1,4}(?::[A-F0-9]{1,4})*)?::)?(?:[A-F0-9]{1,4}(?::[A-F0-9]{1,4})*)?:\d{1,5}$/i;
  const ipv4Regex = /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\.|$)){4}$/;
  const ipv4RegexWithMask =
    /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\/(3[0-2]|[1-2]?[0-9])$/;
  const ipv4RegexWithPort =
    /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?):(\d{1,5})$/;

  switch (options) {
    case "justMask":
      if (ipv6RegexWithMask.test(ip)) {
        return 1;
      }
      if (ipv4RegexWithMask.test(ip)) {
        return 1;
      }
      return 0;

    case "justPort":
      if (ipv6RegexWithPort.test(ip)) {
        return 1;
      }
      if (ipv4RegexWithPort.test(ip)) {
        return 1;
      }
      return 0;

    case "justIp": {
      if (ipv6Regex.test(ip)) {
        return 1;
      }
      if (ipv4Regex.test(ip)) {
        return 1;
      }
      return 0;
    }

    case "justIpv6": {
      if (ipv6Regex.test(ip)) {
        return 1;
      }
      return 0;
    }

    case "justIpv4": {
      if (ipv4Regex.test(ip)) {
        return 1;
      }
      return 0;
    }

    case "justIpv4Mask": {
      if (ipv4RegexWithMask.test(ip)) {
        return 1;
      }
      return 0;
    }

    case "justIpv6Mask": {
      if (ipv6RegexWithMask.test(ip)) {
        return 1;
      }
      return 0;
    }

    default:
      if (
        ipv6Regex.test(ip) ||
        ipv6RegexWithMask.test(ip) ||
        ipv6RegexWithPort.test(ip)
      ) {
        return 1;
      }
      if (
        ipv4Regex.test(ip) ||
        ipv4RegexWithMask.test(ip) ||
        ipv4RegexWithPort.test(ip)
      ) {
        return 1;
      }
      return 0;
  }
};

module.exports = validarIp;
