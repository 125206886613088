import { Box, Tab, Tabs, Typography } from "@mui/material";
import { useState } from "react";
import SolicitacoesIpv4 from "./ipv4";
import SolicitacoesIpv6 from "./ipv6";

export default function Solicitacoes(params) {
  //Change tabs handler
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }
  return (
    <Box>
      <Typography variant="mySubtitle">Solicitações de Prefixos</Typography>
      <Box>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basics tabs config"
        >
          <Tab label="IPV4" {...a11yProps(0)} sx={{ fontSize: "14px" }} />
          <Tab label="IPV6" {...a11yProps(1)} sx={{ fontSize: "14px" }} />
        </Tabs>
        {value === 0 && <SolicitacoesIpv4 />}
        {value === 1 && <SolicitacoesIpv6 />}
      </Box>
    </Box>
  );
}
