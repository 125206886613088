import {
  color,
  display,
  fontSize,
  margin,
  padding,
  textAlign,
} from "@mui/system";
const styles = (theme) => ({
  accordion: {
    backgroundColor: theme.palette.mode === "dark" ? "transparent" : "none",
    margin: "10px 0",
  },
  containerContrato: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "130px",
    height: "50px",
    marginLeft: "-16px",
    marginTop: "-12px",
    borderRadius: "0 0 20px 0",
    backgroundColor: "#FA541C",
    color: "white",
    padding: "5px",
  },
  accordionSummaryBox: { display: "flex", width: "100%", flexDirection: "row" },
  accordionSummaryItemContrato: {
    display: "flex",
    width: "200px",
    flexDirection: "column",
  },
  accordionSummaryItem: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },
  itemTitle: {
    fontWeight: 600,
    mr: 1,
    fontSize: "14px",
    display: "flex",
    alingItens: "center",
    flexDirection: "row",
  },

  itemTitleContrato: {
    fontWeight: 600,
    mr: 1,
    fontSize: "14px",
    display: "flex",
    alingItens: "center",
    flexDirection: "row",
    fontSize: "16px",
  },

  iconTitle: {
    fontSize: "16px",
    mr: "2px",
    mb: -0.3,
  },
  iconTitleContrato: {
    fontSize: "20px",
    mr: "2px",
    mb: -0.3,
  },

  itemText: {
    display: "flex",
    flexDirection: "row",
  },
  itemTextContrato: {
    display: "flex",
    flexDirection: "row",
    fontSize: "16px",
  },
  title: {
    fontSize: "24px",
    fontWeight: 900,
  },
  containerContratoNumber: {
    display: "flex",
    flexDirection: "row",
    width: "125px",
    height: "40px",
    borderRight: "1px solid #9e9e9e",
    borderBottom: "1px solid #9e9e9e",
    marginTop: "-12px",
    marginLeft: "-16px",
    borderRadius: "0 0 20px 0",
  },
});

export default styles;
