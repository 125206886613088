const styles = (props) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    gap: 3,
    width: "100%",
  },
  tabLabel: { fontSize: "14px" },
});

export default styles;
