import { borderRadius, padding } from "@mui/system";

const styles = (props) => ({
  modal: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  paper: {
    padding: "16px",
    borderRadius: "10px",
    bgcolor: "background.paper",
  },
  boxPaper: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  },
  button: { "@media (max-width: 340px)": { width: "100%" } },
});

export default styles;
