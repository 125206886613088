import { Visibility, VisibilityOff } from "@mui/icons-material";

import {
  Alert,
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  Paper,
  Snackbar,
  Stack,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import logoDark from "../../../assets/imgs/login/loginInterno/logocomputizeDark.webp";
import logoLigth from "../../../assets/imgs/login/loginInterno/logocomputizeLigth.webp";
import useStyles from "./styles";
export default function RedefinirSenha() {
  const [textFeedback, setTextFeedback] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [senha, setSenha] = useState("");
  const [confirmSenha, setConfirmSenha] = useState("");
  const [Null, setNull] = useState(false);
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertServiceOpen, setAlertServiceOpen] = useState(false);
  const [senhaValid, setSenhaValid] = useState(false);
  const [feedback, setFeedback] = useState(false);
  const [snackbar, setSnackbar] = useState(null);
  const theme = useTheme();
  const styles = useStyles();

  const url = `${
    window?.config?.REACT_APP_BASE_URL || process.env.REACT_APP_BASE_URL || ""
  }`;
  const { id, token } = useParams();

  useEffect(() => {
    if (senha.length >= 6 && /[a-zA-Z]/.test(senha) && /\d/.test(senha))
      setSenhaValid(true);
    else setSenhaValid(false);
  }, [senha]);
  useEffect(() => {
    async function permissionValidate() {
      try {
        await axios.get(`${url}/authenticationTokenUpdatePassword`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
      } catch (error) {
        if (error?.response?.status === 401) {
          setTextFeedback(
            "Infelizmente o link expirou, solicite outro link no site da MW e tente novamente!"
          );
          setFeedback(true);
        } else {
          setAlertServiceOpen(true);
        }
      }
    }
    permissionValidate();
  }, []);

  const handleUpdate = async () => {
    if (!senha || !confirmSenha || confirmSenha !== senha || !senhaValid) {
      setNull(true);
      return;
    }

    try {
      await axios.put(
        `${url}/usuario/resetPassword/update`,
        {
          password: senha,
          usuarioId: id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      setTextFeedback("Senha atualizada com sucesso!");
      setFeedback(true);
    } catch (error) {
      if (error?.response?.status === 404) {
        setAlertOpen(true);
      } else {
        setAlertServiceOpen(true);
      }
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      handleUpdate();
    }
  };

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    setSnackbar({
      children: "Campo copiado!",
      severity: "info",
    });
  };
  return (
    <>
      <Box sx={styles.container} />
      <Paper sx={styles.paper}>
        <Stack direction={"column"} alignItems={"center"} sx={styles.content}>
          <img
            src={theme.palette.mode === "light" ? logoLigth : logoDark}
            style={styles.logo}
            alt="logo mw"
          />
          {!feedback && (
            <>
              <Typography sx={styles.title}>
                Digite a nova senha e confirme em seguida.
              </Typography>

              <TextField
                sx={styles.textField}
                variant="filled"
                label="Senha"
                type={showPassword ? "text" : "password"}
                error={(!senha || !senhaValid) && Null}
                onChange={(event) => {
                  setSenha(event.target.value);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword((show) => !show)}
                        onMouseDown={(event) => {
                          event.preventDefault();
                        }}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                helperText={
                  (!senha || !senhaValid) && Null
                    ? "A senha precisa ter no mínimo 6 digítos, uma letra e um número."
                    : ""
                }
              />
              <TextField
                sx={styles.textField}
                variant="filled"
                label="Confirmar Senha"
                error={(!senha && Null) || (Null && senha !== confirmSenha)}
                type={showPassword ? "text" : "password"}
                onKeyDown={handleKeyDown}
                onChange={(event) => {
                  setConfirmSenha(event.target.value);
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword((show) => !show)}
                        onMouseDown={(event) => {
                          event.preventDefault();
                        }}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                helperText={
                  (!senha || !senhaValid) && Null
                    ? "A senha precisa ter no mínimo 6 digítos, uma letra e um número."
                    : ""
                }
              />

              <Button
                variant="contained"
                onClick={() => {
                  handleUpdate();
                }}
                sx={styles.button}
              >
                Redefinir a Senha
              </Button>
            </>
          )}
          {feedback && (
            <>
              <Typography sx={styles.feedBackText}>{textFeedback}</Typography>
              <Button
                variant="contained"
                href="/interno/login"
                sx={styles.feedBackButton}
              >
                Página de login
              </Button>
            </>
          )}
          <Divider orientation="horizontal" flexItem sx={styles.divider} />
          <Typography sx={styles.helperText}>
            Precisa de ajuda?
            <br />
            <Typography sx={styles.contato}>
              Entre em contato com nossa equipe:
            </Typography>
            <Tooltip title="Clique para copiar" arrow>
              <span
                onClick={() => {
                  handleCopy("contato@cactusoft.com.br");
                }}
                style={{ cursor: "pointer" }}
              >
                <Typography sx={styles.email}>
                  contato@cactusoft.com.br
                </Typography>
              </span>
            </Tooltip>
          </Typography>
        </Stack>
      </Paper>

      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2500}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}

      <Snackbar
        autoHideDuration={6000}
        open={alertServiceOpen}
        onClose={() => {
          setAlertServiceOpen(false);
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert severity="error">
          Erro: Não foi possível se conectar ao Servidor
        </Alert>
      </Snackbar>
    </>
  );
}
