const styles = (theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: 6,
  },
  accordion: {
    backgroundColor: theme.palette.mode === "dark" ? "transparent" : "none",
  },

  accordionPendente: {
    backgroundColor: theme.palette.mode === "dark" ? "#b71c1c" : "#e57373",
  },
  accordionConcluido: {
    backgroundColor: theme.palette.mode === "dark" ? "#1b5e20" : "#81c784",
  },
  accordionSummaryBox: { display: "flex", width: "100%", flexDirection: "row" },
  accordionSummaryItem: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },

  boxForm: {
    display: "flex",
    flexDirection: "column",
    gap: 3,
    width: "100%",
  },
  boxPrefixos: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: 2,
  },
  iconButtonPrefixos: {
    height: "40px",
    width: "40px",
    mt: "8px",
  },
  textFieldPrefixos: { width: "calc(100% - 40px)" },
  stackButtons: {
    gap: 1,
    justifyContent: "flex-end",
    width: "100%",
    flexWrap: "wrap",
    mt: "10px",
  },
  container: { display: "flex", flexDirection: "column", gap: 6 },
  header: {
    display: "flex",
    width: "100%",
    justifyContent: "space-between",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    bgcolor: "transparent",
    gap: 2,
    borderRadius: "10px",
    padding: "20px",
  },
  cardHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  cardRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  cardContent: { display: "flex", flexDirection: "column", gap: "4px" },
  cardStack: { width: "160px" },
  cardSubTitle: { opacity: 0.6, fontSize: "13px", fontWeight: 500 },
  cardText: {
    fontSize: "15px",
    fontWeight: 500,
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    cursor: "pointer",
  },
});

export default styles;
