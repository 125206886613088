import { width } from "@mui/system";

const styles = (props) => ({
  container: {
    bgcolor: "background.default",
    minWidth: "100vw",
    minHeight: "100vh",
  },
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  boxForm: { display: "flex", flexDirection: "column", gap: 1, width: "700px" },
});

export default styles;
