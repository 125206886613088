import {
  fontSize,
  fontWeight,
  height,
  minWidth,
  textAlign,
  width,
} from "@mui/system";

const styles = (props) => ({
  container: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    position: "relative",
    mt: 2,
  },

  paper: {
    padding: "16px",
    display: "flex",
    flexDirection: "row",
    gap: 1,
    justifyContent: "space-between",
    height: "310px",
    width: "100%",
  },
  cardTitle: {
    fontWeight: 500,
    fontSize: "1rem",
  },
  cardText: {
    fontWeight: 500,
    fontSize: "24px",
    "@media (max-width: 600px)": {
      fontSize: "20px",
    },
    textWrap: "nowrap",
  },
  cardSubText: {
    fontWeight: 500,
    fontSize: "16px",
    mb: 0.4,
    "@media (max-width: 600px)": {
      fontSize: "16px",
      mb: 0.1,
    },
  },
  cardSubTextPie: {
    fontWeight: 500,
    fontSize: "12px",
    width: "100px",
    textWrap: "wrap",
    textAlign: "start",
    mb: 0.4,
    "@media (max-width: 600px)": {
      fontSize: "16px",
      mb: 0.1,
    },
  },
  cardSubText: {
    fontWeight: 500,
    fontSize: "16px",

    mb: 0.4,
    "@media (max-width: 600px)": {
      fontSize: "16px",
      mb: 0.1,
    },
  },
  littlePaper: {
    width: "100%",
    height: "180px",
    padding: "16px",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    justifyContent: "center",
    gap: 1,
  },
  littleChart: {
    height: "50px",
    width: "180px",
  },
  boxPieChart: {
    display: "flex",

    width: "100%",
    height: "100%",
    position: "relative",
  },
});

export default styles;
