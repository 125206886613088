import { bgcolor, color } from "@mui/system";
import { DataGrid } from "@mui/x-data-grid";
import clsx from "clsx";
import { Box, Chip, Rating, useTheme } from "@mui/material";
import { type } from "@testing-library/user-event/dist/type";
import dayjs from "dayjs";
import { Square, SquareOutlined } from "@mui/icons-material";
export default function RecentEventsTable({
  rows = [],
  pageSize = 10,
  hideFooter = false,
  load,
}) {
  /*const teste = rows[0].data.split(" - ");
  console.log(teste);*/
  //console.log("dados", rows.status);

  const theme = useTheme();

  const columnsRecentEvetns = [
    { field: "id", headerName: "ID", width: 100 },
    {
      field: "alertId",
      headerName: "ID Alerta",
      flex: 1,
      cellClassName: (host_ongoing) => {
        return clsx("super-app", {
          positivo_ataque: host_ongoing.row.status === "Active",
        });
      },
      minWidth: 150,
    },

    {
      field: "prefix",
      headerName: "Prefixo",
      flex: 1,
      cellClassName: (host_ongoing) => {
        return clsx("super-app", {
          positivo_ataque: host_ongoing.row.status === "Active",
        });
      },
      minWidth: 150,
    },

    {
      field: "anomaly",
      headerName: "Tipo",
      flex: 1,
      cellClassName: (host_ongoing) => {
        return clsx("super-app", {
          positivo_ataque: host_ongoing.row.status === "Active",
        });
      },
      minWidth: 110,
    },

    {
      field: "link_severity",
      headerName: "Severidade",
      width: 175,
      flex: 1,
      cellClassName: (host_ongoing) => {
        return clsx("super-app", {
          positivo_ataque: host_ongoing.row.status === "Active",
        });
      },
      minWidth: 100,
      renderCell: (params) => {
        const thisRow = params.row;
        return (
          <Rating
            name="read-only"
            value={parseInt(thisRow?.link_severity / 10) || 0}
            max={10}
            icon={
              <Box
                sx={{
                  width: "12px",
                }}
              >
                <Box
                  sx={{
                    width: "8px",
                    height: "16px",

                    bgcolor: "secondary.main",
                  }}
                />
              </Box>
            }
            emptyIcon={
              <Box
                sx={{
                  width: "12px",
                }}
              >
                <Box
                  sx={{
                    width: "8px",
                    height: "16px",
                    borderColor: "#73777D",
                    border: "solid 1px",
                  }}
                />
              </Box>
            }
            readOnly
          />
        );
      },
    },
    {
      field: "dataInicial",
      headerName: "Data Inicial",

      width: 175,
      flex: 1,
      cellClassName: (host_ongoing) => {
        return clsx("super-app", {
          positivo_ataque: host_ongoing.row.status === "Active",
        });
      },
      minWidth: 170,

      renderCell: (params) => {
        const thisRow = params.row;
        return `${
          thisRow?.from?.unixtime
            ? dayjs.unix(thisRow?.from?.unixtime).format("DD/MM/YYYY HH:mm:ss")
            : ""
        }`;
      },
    },
    {
      field: "dataFinal",
      headerName: "Data Final",
      width: 175,
      flex: 1,
      cellClassName: (host_ongoing) => {
        return clsx("super-app", {
          positivo_ataque: host_ongoing.row.status === "Active",
        });
      },
      minWidth: 170,
      renderCell: (params) => {
        const thisRow = params.row;
        return `${
          thisRow?.until?.unixtime
            ? dayjs.unix(thisRow?.until?.unixtime).format("DD/MM/YYYY HH:mm:ss")
            : "Ongoing"
        }`;
      },
    },
    {
      field: "duration",
      headerName: "Duração",
      width: 175,
      flex: 1,
      cellClassName: (host_ongoing) => {
        return clsx("super-app", {
          positivo_ataque: host_ongoing.row.status === "Active",
        });
      },
      minWidth: 100,
      renderCell: (params) => {
        const duration = parseInt(params.row?.duration, 10);
        if (isNaN(duration)) {
          return "Ongoing"; // Retorna um valor padrão se a duração não for um número válido
        }
        if (duration < 60) {
          return `${duration}s`;
        } else if (duration < 3600) {
          // Corrigido para 3600 segundos (1 hora)
          return `${(duration / 60).toFixed(2)}m`;
        } else {
          return `${(duration / 3600).toFixed(2)}h`; // Corrigido para 3600 segundos (1 hora)
        }
      },
    },
    {
      field: "impact",
      headerName: "Impacto (bps)",
      width: 175,
      flex: 1,
      cellClassName: (host_ongoing) => {
        return clsx("super-app", {
          positivo_ataque: host_ongoing.row.status === "Active",
        });
      },
      minWidth: 100,
    },
    {
      field: "impactPps",
      headerName: "Impacto (pps)",
      width: 175,
      flex: 1,
      cellClassName: (host_ongoing) => {
        return clsx("super-app", {
          positivo_ataque: host_ongoing.row.status === "Active",
        });
      },
      minWidth: 100,
    },
  ];

  return (
    <>
      <DataGrid
        rows={rows}
        columns={columnsRecentEvetns}
        autoHeight={true}
        //getRowHeight={() => "auto"}
        autosizeOptions={{ includeOutliers: true }}
        pageSizeOptions={[10, 50, 100]}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
          columns: {
            columnVisibilityModel: {
              id: false,
              //attack_type: false,
              alertId: false,
            },
          },
        }}
        loading={load}
        sx={{
          display: "grid",
          "&.MuiDataGrid-root": { borderStyle: "none" },
          "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
            outline: "none !important",
          },
          "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": { py: "5px" },
          "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
            py: "15px",
          },
          "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
            py: "22px",
          },
          '&.MuiDataGrid-root .MuiDataGrid-container--top [role="row"], & .MuiDataGrid-root .MuiDataGrid-container--bottom [role="row"]':
            {
              backgroundColor: "background.paper",
            },
          "& .super-app.positivo_ataque": {
            fontWeight: "600",
            animation: `${
              theme.palette.mode === "dark" ? "myEffectDark" : "myEffectLight"
            } 2.5s infinite`,
          },
          "@keyframes myEffectDark": {
            "0%, 100%": { color: "#FA541C", fontWeight: "600" },
            "50%": { color: "#ffffff", fontWeight: "600" },
          },
          "@keyframes myEffectLight": {
            "0%, 100%": { color: "#FA541C", fontWeight: "600" },
            "50%": { color: "#000000", fontWeight: "600" },
          },
        }}
        density="compact"
        disableColumnMenu
        disableRowSelectionOnClick
        hideFooter={hideFooter}
      />
    </>
  );
}
