const styles = (theme) => ({
  modal: { display: "flex", justifyContent: "center", alignItems: "center" },
  paper: {
    padding: "16px",
    borderRadius: "10px",
    bgcolor: "background.paper",
  },
  boxPaper: { display: "flex", flexDirection: "column", gap: "16px" },
  cardStack: { gap: 1, flexWrap: "wrap" },
  buttonStack: {
    gap: 1,
    justifyContent: "flex-end",
    width: "100%",
    flexWrap: "wrap",
  },
  asTextField: {
    "& .MuiInputLabel-root.Mui-error": {
      color: "secondary.main",
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      border: "3px solid",
      borderColor: "secondary.main",
    },
    "& .MuiFormHelperText-root.Mui-error": {
      color: "secondary.main",
    },
  },
});

export default styles;
