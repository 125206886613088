const styles = (props) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: 6,
  },
  paper: {
    alignItems: "center",
    width: "100%",
    padding: "20px",
    justifyContent: "center",
    backgroundColor: "transparent",
    minWidth: "200px",
    borderRadius: "10px",
  },
  boxForm: {
    display: "flex",
    flexDirection: "column",
    gap: 3,
    width: "100%",
  },
  boxPrefixos: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    gap: 2,
  },
  iconButtonPrefixos: {
    height: "40px",
    width: "40px",
    mt: "8px",
  },
  textFieldPrefixos: { width: "calc(100% - 40px)" },
  stackButtons: {
    gap: 1,
    justifyContent: "flex-end",
    width: "100%",
    flexWrap: "wrap",
    mt: "10px",
  },
});

export default styles;
