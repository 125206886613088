import { display, textAlign } from "@mui/system";

const styles = (theme) => ({
  accordion: {
    backgroundColor: theme.palette.mode === "dark" ? "transparent" : "none",
  },
  accordionSummaryBox: { display: "flex", width: "100%", flexDirection: "row" },
  accordionSummaryItem: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },
  itemTitle: {
    fontWeight: 600,
    mr: 1,
    fontSize: "14px",
    display: "flex",
    alingItens: "center",
    flexDirection: "row",
  },
  iconTitle: {
    fontSize: "16px",
    mr: "2px",
    mb: -0.3,
  },
  itemText: {
    display: "flex",
    flexDirection: "row",
  },
  title: {
    fontSize: "24px",
    fontWeight: 900,
  },
});

export default styles;
