import React, { useContext, useEffect, useState } from "react";
import { io } from "socket.io-client";
import {
  AppBar,
  Autocomplete,
  Avatar,
  Box,
  CircularProgress,
  Container,
  CssBaseline,
  FormControl,
  Icon,
  IconButton,
  InputLabel,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  Stack,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  Breadcrumbs,
  Link,
  Fade,
  Fab,
  Drawer,
  Paper,
  LinearProgress,
} from "@mui/material";
import { socketInterno as socket } from "../../../services/socket";
import { connectSocket as socketConnect } from "../../../services/socket";

import {
  Outlet,
  useNavigate,
  useLocation,
  useOutletContext,
} from "react-router-dom";
import useStyles from "./styles";

import logoComputizeDark from "../../../assets/imgs/logos/logocomputizeDark.webp";

import {
  DarkModeOutlined,
  LightModeOutlined,
  LogoutOutlined,
  MenuOutlined,
  PersonOutline,
  FilterAltOutlined,
  ArrowBackIosNewOutlined,
  SupervisorAccountOutlined,
} from "@mui/icons-material";
import ModalPerfil from "../../../components/internoModalPerfil";
import SliderBar from "./sliderBar";
import MobileSliderBar from "./mobileDrawer";
import { RangeContext } from "../../client/range";
import api from "../../../services/api";

function CircularProgressWithLabel({ value = 0 }) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        variant={value <= 0 || value >= 60 ? "indeterminate" : "determinate"}
        value={value * (5 / 3)}
        sx={{ color: "#fff" }}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="caption" component="div" color="#ffffff">
          {value <= 0 || value >= 60 ? 0 : `${Math.round(value)}`}
        </Typography>
      </Box>
    </Box>
  );
}

function LinearProgressWithLabel({ value = 0 }) {
  return (
    <Box>
      <LinearProgress
        variant={value <= 0 || value >= 60 ? "buffer" : "determinate"}
        value={value * (5 / 3)}
        sx={{ color: "#fff" }}
      />
    </Box>
  );
}

/* CircularProgressWithLabel.propTypes = {
  /**
   * @default 0
  
  value: PropTypes.number.isRequired,
}; */

function Layout(params) {
  const { colorMode, setColorMode } = params;
  const [anchorEl, setAnchorEl] = useState(null);
  const [baseImage, setBaseImage] = useState(
    JSON.parse(localStorage.getItem("perfil"))?.foto || ""
  );
  const [administrador, setAdministrador] = useState(false);
  const [openModalPerfil, setOpenModalPerfil] = useState(false);
  const { Range, setRange } = useContext(RangeContext);
  const [clientes, setClientes] = useState([]);
  const [cliente, setCliente] = useState(null);
  const [windowWith, setWindowWith] = useState(window.innerWidth);
  const [openCollapsem, setOpenCollapse] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [isConnected, setIsConnected] = useState(socket.connected);

  useEffect(() => {
    function onConnect() {
      setIsConnected(true);
      socket.emit(
        "first_connection",
        `Servidor juntou-se ao grupo id conection: ${socket.id}`
      );
    }
    function onDisconnect() {
      setIsConnected(false);
    }

    function connectErrorEvent(error) {
      console.log("Socket error - erro de conexão:", error);
    }
    function reconnectAttemptEvent(attempt) {
      console.log(
        `Socket warn - o servido esta tentando ser reconectar. N de tentativas: ${attempt}`
      );
    }
    function reconnectErrorEvent(error) {
      console.log("Socket error - erro de reeconexão:", error);
    }
    function reconnectEvent(attempt) {
      console.log("Socket success: servidor reeconectado com sucesso");
    }

    //socket.connect();
    socketConnect(socket, JSON.parse(localStorage.getItem("user"))?.token);
    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    socket.on("connect_error", (data) => {
      connectErrorEvent();
      console.log("dados", data);
    });
    socket.on("update_prefix", (data) => {
      // console.log(data);
    });
    socket.io.on("reconnect_attempt", reconnectAttemptEvent);
    socket.io.on("reconnect_error", reconnectErrorEvent);
    socket.io.on("reconnect", reconnectEvent);
    return () => {
      socket.off("connect", onConnect);

      socket.off("disconnect", onDisconnect);
      socket.off("connect_error", connectErrorEvent);
      socket.io.off("reconnect_attempt", reconnectAttemptEvent);
      socket.io.off("reconnect_error", reconnectErrorEvent);
      socket.io.off("reconnect", reconnectEvent);
      socket.disconnect();
    };
  }, []);

  const handleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  useEffect(() => {
    const handleResize = () => {
      setWindowWith(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (windowWith > 760 && drawerOpen) {
      handleDrawer();
    }
  }, [windowWith]);

  useEffect(() => {
    const handleUsuarioId = () => {
      let result = localStorage.getItem("user");
      result = JSON.parse(result);
      // Verifica se a propriedade rlPermissoesUsuario existe e é um array
      if (Array.isArray(result?.usuario?.rlPermissoesUsuario)) {
        // Itera sobre o array para verificar se algum objeto contém a propriedade codigoPermissao com valor "ADM"
        for (let permissao of result?.usuario?.rlPermissoesUsuario) {
          if (permissao?.permissao?.codigoPermissao.toUpperCase() === "ADM") {
            setAdministrador(true);
          }
        }
        return false;
      }

      if (result) {
        setBaseImage(result.foto);
      } else {
        return "";
      }
    };
    handleUsuarioId();
  }, []);

  useEffect(() => {
    setBaseImage(
      localStorage.getItem("perfil") &&
        JSON.parse(localStorage.getItem("perfil")) != "undefined"
        ? JSON.parse(localStorage.getItem("perfil"))?.foto
        : ""
    );
  }, [localStorage.getItem("perfil")]);

  const styles = useStyles();
  const navigate = useNavigate();

  //funcoes de controle do menu
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate("/interno/login");
  };

  const handleAdm = () => {
    navigate("/interno/administracao");
    setAnchorEl(null);
  };

  const handleCloseModalPerfil = () => {
    setOpenModalPerfil(false);
  };

  async function apiGetClientes() {
    try {
      const response = await api.get("/cliente/home/getClient");
      setClientes(response.data);

      // setLoadingPage(true);
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    apiGetClientes();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      setWindowWith(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
  }, []);

  const handleOpenCollapsem = () => {
    setOpenCollapse(!openCollapsem);
  };
  const clientesUnicos = new Map();
  clientes.forEach((cliente) => {
    if (!clientesUnicos.has(cliente.as)) {
      clientesUnicos.set(cliente.as, cliente);
    }
  });
  const clientesFiltrado = Array.from(clientesUnicos.values());

  const routerNow = useLocation().pathname;

  return (
    <Box display={"flex"}>
      <CssBaseline />

      <Box>
        <AppBar
          position="fixed"
          elevation={0}
          // color="primary"
        >
          <Container maxWidth="100vw">
            <Toolbar sx={styles.toolbar}>
              {windowWith <= 760 && (
                <IconButton
                  sx={{
                    padding: "0px",
                    transform: "translateX(-20px)",
                    color: "#ffffff",
                  }}
                  onClick={handleDrawer}
                >
                  <MenuOutlined fontSize="large" />
                </IconButton>
              )}

              <IconButton href={"/interno/home"} sx={styles.IconButtonLogo}>
                <Icon style={styles.logo}>
                  <img
                    src={logoComputizeDark}
                    style={styles.logo}
                    alt="logo-computize-home"
                  />
                </Icon>
              </IconButton>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 1,
                  alignItems: "center",
                }}
              >
                {window.location.pathname === "/interno/dash" && (
                  <>
                    {windowWith < 1050 ? (
                      <>
                        <Tooltip title={"Filtro"} placement="top-start">
                          <Fab
                            onClick={handleOpenCollapsem}
                            size="small"
                            fontSize="large"
                            color="primary"
                            sx={{
                              position: "fixed",
                              bottom: "30px",
                              right: "30px",
                            }}
                          >
                            <FilterAltOutlined />
                          </Fab>
                        </Tooltip>
                      </>
                    ) : (
                      <>
                        {Range?.timerStatus && Range?.cliente?.id && (
                          <CircularProgressWithLabel
                            value={parseInt(Range?.timer) || 0}
                          />
                        )}

                        <Autocomplete
                          sx={{
                            width: "280px",
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#4a5057",
                              color: "#4a5057",
                            },
                            "& .MuiInputLabel-root": {
                              color: "#afb9b8",
                            },
                            svg: {
                              color: "#fff",
                            },
                            input: {
                              color: "#fff",
                            },
                          }}
                          value={Range.cliente}
                          onChange={(event, newValue) => {
                            setRange({ ...Range, cliente: newValue });
                          }}
                          size="small"
                          options={clientesFiltrado}
                          getOptionLabel={(option) => option.nomeFantasia}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Cliente"
                              color="primary"
                            />
                          )}
                        />
                        <FormControl
                          sx={{
                            width: "120px",
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#4a5057",
                              color: "#4a5057",
                            },
                            "& .MuiInputLabel-root": {
                              color: "#afb9b8",
                            },
                            svg: {
                              color: "#fff",
                            },
                            input: {
                              color: "#fff",
                            },
                          }}
                          size="small"
                        >
                          <InputLabel id="demo-simple-select-label">
                            Unidade
                          </InputLabel>
                          <Select
                            labelId="demo-customized-select-label"
                            id="demo-customized-select"
                            value={Range?.unidade || ""}
                            onChange={(event) =>
                              setRange({
                                ...Range,
                                unidade: event.target.value,
                              })
                            }
                            label="Filtro de tempo"
                            sx={{ color: "#fff" }}
                          >
                            <MenuItem value={"bps"}>bps</MenuItem>
                            <MenuItem value={"pps"}>pps</MenuItem>
                          </Select>
                        </FormControl>

                        <FormControl
                          sx={{
                            width: "240px",
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#4a5057",
                              color: "#4a5057",
                            },
                            "& .MuiInputLabel-root": {
                              color: "#afb9b8",
                            },
                            svg: {
                              color: "#fff",
                            },
                            input: {
                              color: "#fff",
                            },
                          }}
                          size="small"
                        >
                          <InputLabel id="demo-simple-select-label">
                            Filtro de tempo
                          </InputLabel>
                          <Select
                            labelId="demo-customized-select-label"
                            id="demo-customized-select"
                            value={Range?.range || ""}
                            onChange={(event) =>
                              setRange({ ...Range, range: event.target.value })
                            }
                            label="Filtro de tempo"
                            sx={{ color: "#fff" }}
                          >
                            <MenuItem value=""></MenuItem>
                            <MenuItem value={"hour"}>1 Hora</MenuItem>
                            <MenuItem value={"2hours"}>2 Horas</MenuItem>
                            <MenuItem value={"4hours"}>4 Horas</MenuItem>
                            <MenuItem value={"6hours"}>6 Horas</MenuItem>
                            <MenuItem value={"12hours"}>12 Horas</MenuItem>
                            <MenuItem value={"day"}>Dia</MenuItem>
                            <MenuItem value={"week"}>Semana</MenuItem>
                            {/*  <MenuItem value={"month"}>Mês</MenuItem> */}
                          </Select>
                        </FormControl>
                      </>
                    )}
                  </>
                )}

                <Tooltip title="Abrir Opções">
                  <IconButton id="basic-button" onClick={handleClick}>
                    <Avatar src={baseImage} />
                  </IconButton>
                </Tooltip>
              </Box>

              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                disableScrollLock={true}
              >
                <MenuItem //</Menu>onClick={handlePerfil}
                  onClick={() => {
                    setOpenModalPerfil(true);
                    setAnchorEl(null);
                  }}
                >
                  <ListItemIcon>
                    <PersonOutline />
                  </ListItemIcon>
                  <ListItemText>Perfil</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => setColorMode((prev) => !prev)}>
                  {colorMode ? (
                    <>
                      <ListItemIcon>
                        <DarkModeOutlined />
                      </ListItemIcon>
                      <ListItemText>Modo Noturno</ListItemText>
                    </>
                  ) : (
                    <>
                      <ListItemIcon>
                        <LightModeOutlined />
                      </ListItemIcon>
                      <ListItemText>Modo Claro</ListItemText>
                    </>
                  )}
                </MenuItem>
                {administrador && (
                  <MenuItem onClick={handleAdm}>
                    <ListItemIcon>
                      <SupervisorAccountOutlined />
                    </ListItemIcon>
                    <ListItemText>Administração</ListItemText>
                  </MenuItem>
                )}

                <MenuItem onClick={handleLogout}>
                  <ListItemIcon>
                    <LogoutOutlined />
                  </ListItemIcon>
                  <ListItemText>Sair</ListItemText>
                </MenuItem>
              </Menu>
            </Toolbar>
          </Container>
          {windowWith < 1050 ? (
            <Box>
              {Range?.timerStatus && Range?.cliente?.id && (
                <LinearProgressWithLabel value={parseInt(Range?.timer) || 0} />
              )}
            </Box>
          ) : (
            <></>
          )}
          <Drawer
            open={openCollapsem}
            onClose={handleOpenCollapsem}
            anchor={"right"}
          >
            <Paper
              sx={{
                backgroundColor: "background.default",
                height: "100%",
                borderRadius: 0,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: 3,
                  margin: "20px 10px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  <IconButton
                    sx={{ marginRight: "auto" }}
                    onClick={() => setOpenCollapse(false)}
                  >
                    <ArrowBackIosNewOutlined fontSize="small" />
                  </IconButton>
                  <Typography
                    sx={{
                      position: "absolute",
                      marginRight: "auto",
                    }}
                    variant="mySubtitle2"
                  >
                    Filtrar cliente
                  </Typography>
                </Box>

                <Autocomplete
                  sx={{
                    width: "280px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "primary",
                      color: "primary",
                    },
                    "& .MuiInputLabel-root": {
                      color: "primary",
                    },
                    svg: {
                      color: "primary",
                    },
                    input: {
                      color: "primary",
                    },
                  }}
                  value={Range.cliente}
                  onChange={(event, newValue) => {
                    setRange({ ...Range, cliente: newValue });
                  }}
                  size="small"
                  options={clientes}
                  getOptionLabel={(option) => option.nomeFantasia}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Cliente"
                      variant="filled"
                      color="primary"
                    />
                  )}
                />
                <FormControl
                  sx={{
                    width: "280px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "primary",
                      color: "primary",
                    },
                    "& .MuiInputLabel-root": {
                      color: "primary",
                    },
                    svg: {
                      color: "primary",
                    },
                    input: {
                      color: "primary",
                    },
                  }}
                  size="small"
                >
                  <InputLabel
                    id="demo-simple-select-label"
                    sx={{ marginTop: "11px" }}
                  >
                    Unidade
                  </InputLabel>
                  <Select
                    labelId="demo-customized-select-label"
                    id="demo-customized-select"
                    value={Range?.unidade || ""}
                    onChange={(event) => {
                      setRange({
                        ...Range,
                        unidade: event.target.value,
                      });
                    }}
                    label="Unidade"
                    sx={{ color: "primary" }}
                    variant="filled"
                  >
                    <MenuItem value={"bps"}>bps</MenuItem>
                    <MenuItem value={"pps"}>pps</MenuItem>
                  </Select>
                </FormControl>

                <FormControl
                  sx={{
                    width: "280px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "primary",
                      color: "primary",
                    },
                    "& .MuiInputLabel-root": {
                      color: "primary",
                    },
                    svg: {
                      color: "primary",
                    },
                    input: {
                      color: "primary",
                    },
                  }}
                  size="small"
                >
                  <InputLabel
                    id="demo-simple-select-label"
                    sx={{ marginTop: "11px" }}
                  >
                    Filtro de tempo
                  </InputLabel>
                  <Select
                    labelId="demo-customized-select-label"
                    id="demo-customized-select"
                    value={Range?.range || ""}
                    onChange={(event) => {
                      setRange({
                        ...Range,
                        range: event.target.value,
                      });
                    }}
                    label="Filtro de tempo"
                    sx={{ color: "primary" }}
                    variant="filled"
                  >
                    <MenuItem value=""></MenuItem>
                    <MenuItem value={"hour"}>1 Hora</MenuItem>
                    <MenuItem value={"2hours"}>2 Horas</MenuItem>
                    <MenuItem value={"4hours"}>4 Horas</MenuItem>
                    <MenuItem value={"6hours"}>6 Horas</MenuItem>
                    <MenuItem value={"12hours"}>12 Horas</MenuItem>
                    <MenuItem value={"day"}>Dia</MenuItem>
                    <MenuItem value={"week"}>Semana</MenuItem>
                    {/* <MenuItem value={"month"}>Mês</MenuItem> */}
                  </Select>
                </FormControl>
              </Box>
            </Paper>
          </Drawer>
        </AppBar>

        <Box sx={styles.topContent} />

        <Stack direction={"row"}>
          {windowWith > 760 && <SliderBar />}
          {
            //drawerOpen && <MobileSliderBar />
          }
          <MobileSliderBar open={drawerOpen} setOpen={setDrawerOpen} />
          <Box component="main" sx={styles.main}>
            <Box mt={1}>
              <Outlet />
            </Box>
          </Box>
        </Stack>
      </Box>

      <ModalPerfil
        open={openModalPerfil}
        handleClose={handleCloseModalPerfil}
      />
    </Box>
  );
}

export default Layout;
