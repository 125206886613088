import React, { useState } from "react";
import useStyles from "./styles";
import { z } from "zod";
import {
  Paper,
  Box,
  Tab,
  TextField,
  Typography,
  MenuItem,
  Select,
  Button,
  IconButton,
  useTheme,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab/";
import InputMask from "react-input-mask";
import { Add, Delete } from "@mui/icons-material";
import validarIp from "../../../utils/validadorIp";

const configJuniperSchema = z.object({
  nomeClient: z.string().min(1, "Nome é obrigatório"),
  asn: z.string().min(1, "ASN é obrigatório"),
  ipSource: z.string().min(1, "IP Source é obrigatório"),
  ipDestination: z.string().min(1, "IP Destination é obrigatório"),
  tunnelSource: z.string().min(1, "Tunnel source é obrigatório"),
  ipTunnelSource: z.string().min(1, "IP Tunnel source é obrigatório"),
  plano: z.number().min(1, "Plano é obrigatório"),
  prefixos: z.string().min(1, "Plano é obrigatório"),
});

const ipSchema = z.object({
  ip: z.string().min(1, "IP é obrigatório"),
});

export default function JuniperScreen() {
  const theme = useTheme();
  const styles = useStyles(theme);
  const [value, setValue] = useState("1");
  const [plano, setPlano] = useState("");
  const [errors, setErrors] = useState({});

  const [formData, setFormData] = useState({
    nomeClient: "",
    asn: "",
    ipSource: "",
    ipDestination: "",
    tunnelSource: "",
    ipTunnelSource: "",
    plano: "",
    prefixos: [{ id: 1, value: "" }],
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangePlano = (event) => {
    setPlano(event.target.value);

    const newFormData = { ...formData };
    newFormData.plano = event.target.value;
    setFormData(newFormData);
  };

  function handleInputChange(e) {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  }

  const handleAddPrefixo = () => {
    let newForm = { ...formData };
    const newArray = formData.prefixos;
    newArray.push({ id: newArray.length + 1, value: "" });
    newForm.prefixos = newArray;
    setFormData(newForm);
  };

  const handlePrefixoChange = (index, value) => {
    setFormData((formData) => {
      const newPrefixo = [...formData.prefixos];
      newPrefixo[index].value = value;
      return {
        ...formData,
        prefixos: newPrefixo,
      };
    });
  };

  const handleDelete = (index) => {
    let newForm = { ...formData };
    const newArray = formData.prefixos;
    newArray.splice(index, 1);
    newForm.prefixos = newArray;
    setFormData(newForm);
  };

  function handleSubmit() {
    try {
      configJuniperSchema.parse({
        nomeClient: formData.nomeClient,
        asn: formData.asn,
        ipSource: formData.ipSource,
        ipDestination: formData.ipDestination,
        tunnelSource: formData.tunnelSource,
        ipTunnelSource: formData.ipTunnelSource,
        plano: formData.plano,
        prefixos: formData.prefixos,
      });
      setErrors({});
    } catch (error) {
      console.log(error);
      const newErrors = {};
      error?.errors?.forEach((err) => {
        newErrors[err.path[0]] = err.message;
      });
      setErrors(newErrors);
    }
  }

  function handleBlur(e) {
    const { name, value } = e.target;
    if (name === "ipSource") {
      if (value?.trim() === "") {
        setErrors((prev) => ({ ...prev, [name]: "IP obrigatório" }));
      } else {
        const isValid = validarIp(value);
        if (isValid === 1) {
          setErrors((prev) => ({ ...prev, [name]: "" }));
        } else {
          setErrors((prev) => ({ ...prev, [name]: "IP inválido" }));
        }
      }
    } else if (name === "nomeClient") {
      if (value?.trim() === "") {
        setErrors((prev) => ({ ...prev, [name]: "Nome obrigatório" }));
      } else {
        setErrors((prev) => ({ ...prev, [name]: "" }));
      }
    } else if (name === "asn") {
      if (value?.trim() === "") {
        setErrors((prev) => ({ ...prev, [name]: "ASN obrigatório" }));
      } else {
        setErrors((prev) => ({ ...prev, [name]: "" }));
      }
    } else if (name === "ipDestination") {
      if (value?.trim() === "") {
        setErrors((prev) => ({ ...prev, [name]: "IP obrigatório" }));
      } else {
        const isValid = validarIp(value);
        if (isValid === 1) {
          setErrors((prev) => ({ ...prev, [name]: "" }));
        } else {
          setErrors((prev) => ({ ...prev, [name]: "IP inválido" }));
        }
      }
    } else if (name === "tunnelSource") {
      if (value?.trim() === "") {
        setErrors((prev) => ({ ...prev, [name]: "IP obrigatório" }));
      } else {
        const isValid = validarIp(value);
        if (isValid === 1) {
          setErrors((prev) => ({ ...prev, [name]: "" }));
        } else {
          setErrors((prev) => ({ ...prev, [name]: "IP inválido" }));
        }
      }
    } else if (name === "ipTunnelSource") {
      if (value?.trim() === "") {
        setErrors((prev) => ({ ...prev, [name]: "IP obrigatório" }));
      } else {
        const isValid = validarIp(value);
        if (isValid === 1) {
          setErrors((prev) => ({ ...prev, [name]: "" }));
        } else {
          setErrors((prev) => ({ ...prev, [name]: "IP inválido" }));
        }
      }
    } else if (name === "prefixo") {
      if (value?.trim() === "") {
        setErrors((prev) => ({ ...prev, [name]: "IP obrigatório" }));
      } else {
        const isValid = validarIp(value, "justMask");
        if (isValid === 1) {
          setErrors((prev) => ({ ...prev, [name]: "" }));
        } else {
          setErrors((prev) => ({ ...prev, [name]: "IP inválido" }));
        }
      }
    }
  }

  return (
    <Box sx={styles.container}>
      <Paper elevation={3} sx={styles.containerPaper}>
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label="Configurações" value="1" />
              <Tab label="Prefixos" value="2" />
            </TabList>
          </Box>

          <TabPanel value="1">
            <Box sx={styles.tabPainel}>
              <Box sx={styles.boxLeft}>
                <Typography sx={{ fontWeight: "500" }} variant="subtitle">
                  Informações dos Clientes
                </Typography>

                <Box sx={styles.boxInforClients}>
                  <InputMask
                    name="nomeClient"
                    mask=""
                    size={"small"}
                    disabled={false}
                    maskChar=""
                    value={formData.nomeClient}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                  >
                    {(props) => (
                      <TextField
                        size="small"
                        sx={styles.textFieldNameClient}
                        autoComplete="off"
                        label="Nome do cliente"
                        variant="filled"
                        value={formData.nomeClient}
                        onChange={handleInputChange}
                        {...props}
                        error={Boolean(errors?.nomeClient)}
                        helperText={errors?.nomeClient}
                      />
                    )}
                  </InputMask>

                  <InputMask
                    name="asn"
                    mask="999999"
                    size={"small"}
                    disabled={false}
                    maskChar=""
                    value={formData.asn}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                  >
                    {(props) => (
                      <TextField
                        size="small"
                        sx={styles.textFieldcnpj}
                        label="ASN do cliente"
                        variant="filled"
                        autoComplete="off"
                        {...props}
                        error={Boolean(errors?.asn)}
                        helperText={errors?.asn}
                      />
                    )}
                  </InputMask>
                </Box>

                <Typography sx={{ fontWeight: "500" }} variant="subtitle">
                  Configurações de IP
                </Typography>

                <Box sx={styles.boxConfigIP}>
                  <InputMask
                    name="ipSource"
                    mask=""
                    size={"small"}
                    disabled={false}
                    maskChar=""
                    value={formData.ipSource}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                  >
                    {(props) => (
                      <TextField
                        size="small"
                        sx={{ width: "100%", marginBottom: "20px" }}
                        autoComplete="off"
                        label="IP source"
                        variant="filled"
                        value={formData.ipSource}
                        onChange={handleInputChange}
                        {...props}
                        error={Boolean(errors?.ipSource)}
                        helperText={errors?.ipSource}
                      />
                    )}
                  </InputMask>

                  <InputMask
                    name="ipDestination"
                    mask=""
                    size={"small"}
                    disabled={false}
                    maskChar=""
                    value={formData.ipDestination}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                  >
                    {(props) => (
                      <TextField
                        size="small"
                        sx={{ width: "100%" }}
                        autoComplete="off"
                        label="IP destination"
                        variant="filled"
                        value={formData.ipDestination}
                        onChange={handleInputChange}
                        {...props}
                        error={Boolean(errors?.ipDestination)}
                        helperText={errors?.ipDestination}
                      />
                    )}
                  </InputMask>
                </Box>
              </Box>

              <Box sx={styles.boxRight}>
                <Typography sx={{ fontWeight: "500" }} variant="subtitle">
                  Configurações de Túnel
                </Typography>

                <Box sx={styles.boxConfigTunel}>
                  <InputMask
                    name="tunnelSource"
                    mask=""
                    size={"small"}
                    disabled={false}
                    maskChar=""
                    value={formData.tunnelSource}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                  >
                    {(props) => (
                      <TextField
                        size="small"
                        sx={{ width: "100%", marginBottom: "20px" }}
                        autoComplete="off"
                        label="Tunnel source"
                        variant="filled"
                        value={formData.tunnelSource}
                        onChange={handleInputChange}
                        {...props}
                        error={Boolean(errors?.tunnelSource)}
                        helperText={errors?.tunnelSource}
                      />
                    )}
                  </InputMask>

                  <InputMask
                    name="ipTunnelSource"
                    mask=""
                    size={"small"}
                    disabled={false}
                    maskChar=""
                    value={formData.ipTunnelSource}
                    onChange={handleInputChange}
                    onBlur={handleBlur}
                  >
                    {(props) => (
                      <TextField
                        size="small"
                        sx={{ width: "100%" }}
                        autoComplete="off"
                        label="IP Tunnel source"
                        variant="filled"
                        value={formData.ipTunnelSource}
                        onChange={handleInputChange}
                        {...props}
                        error={Boolean(errors?.ipTunnelSource)}
                        helperText={errors?.ipTunnelSource}
                      />
                    )}
                  </InputMask>
                </Box>

                <Typography sx={{ fontWeight: "500" }} variant="subtitle">
                  Plano
                </Typography>

                <Box sx={styles.boxPlano}>
                  <Select
                    variant="filled"
                    value={plano}
                    onChange={handleChangePlano}
                    size="small"
                    sx={{ width: "100%" }}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    <MenuItem value={1}>Plano 1</MenuItem>
                    <MenuItem value={2}>Plano 2</MenuItem>
                    <MenuItem value={3}>Plano 3</MenuItem>
                  </Select>
                </Box>
              </Box>
            </Box>
          </TabPanel>

          <TabPanel value="2">
            <Box sx={styles.boxPrefixo}>
              <Box sx={{ width: "100%" }}>
                <InputMask
                  name="asn"
                  mask="999999"
                  size={"small"}
                  disabled={false}
                  maskChar=""
                  value={formData.asn}
                  onChange={handleInputChange}
                  onBlur={handleBlur}
                >
                  {(props) => (
                    <TextField
                      onBlur={handleBlur}
                      size="small"
                      sx={{ width: "100%" }}
                      label="ASN do cliente"
                      variant="filled"
                      autoComplete="off"
                      value={formData.asn}
                      onChange={handleInputChange}
                      {...props}
                      error={Boolean(errors?.asn)}
                      helperText={errors?.asn}
                    />
                  )}
                </InputMask>
              </Box>

              <Typography
                sx={{ fontWeight: "500", marginTop: "20px" }}
                variant="subtitle"
              >
                Digite abaixo todos os prefixos que devem ser liberados para o
                seu ASN:
              </Typography>

              <Box sx={styles.boxPrefixos}>
                {Array.isArray(formData?.prefixos) &&
                  formData.prefixos.map((prefixo, index) => (
                    <Box
                      key={`${prefixo.id}-${index}`}
                      sx={styles.containerPrefixo}
                    >
                      <TextField
                        size="small"
                        autoComplete="off"
                        label="Prefixo"
                        variant="filled"
                        sx={{ width: "85%" }}
                        value={prefixo.value}
                        onChange={(e) =>
                          handlePrefixoChange(index, e.target.value)
                        }
                        error={Boolean(errors?.prefixo)}
                        helperText={errors?.prefixo}
                      />

                      {index === formData.prefixos.length - 1 ? (
                        <IconButton
                          onClick={(e) => handleAddPrefixo(e.target.value)}
                        >
                          <Add sx={{ color: "primary.error" }} />
                        </IconButton>
                      ) : (
                        <IconButton
                          onClick={() => handleDelete(index)}
                          id="icon-add"
                        >
                          <Delete
                            sx={{ color: "primary.error" }}
                            color="primary"
                          />
                        </IconButton>
                      )}
                    </Box>
                  ))}
              </Box>
            </Box>
          </TabPanel>
        </TabContext>
        <Box sx={styles.boxButtons}>
          <Button size="small" sx={{ marginRight: "10px" }} variant="text">
            CANCELAR
          </Button>
          <Button onClick={handleSubmit} size="small" variant="contained">
            ADICIONAR AO JUMPER
          </Button>
        </Box>
      </Paper>
    </Box>
  );
}
