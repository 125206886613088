import axios from "axios";

const localStorageData = () => {
  let result = localStorage.getItem("userCliente");
  result = JSON.parse(result);
  if (result) {
    return result;
  } else {
    return "";
  }
};

export default async function tokenUpdate(token) {
  const url = `${
    window?.config?.REACT_APP_BASE_URL || process.env.REACT_APP_BASE_URL || ""
  }`;
  let userData = localStorageData();

  try {
    const response = await axios.post(
      `${url}/refreshTokenCliente`,
      { refresh_token: userData.refreshToken.usuarioCliente },
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    );
    if (response.data.token) {
      userData.token = response.data.token;
      userData.refreshToken = response.data.newRefreshToken;
      localStorage.setItem("user", JSON.stringify(userData));
    } else {
    }
  } catch (error) {
    if (error.response.status === 401) {
      localStorage.clear();
      window.location.replace("/login");
    } else {
      console.error(error);
    }
  }
}
