import { height, minHeight, width } from "@mui/system";

const styles = (props) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: "16px 24px 24px 24px",
    borderRadius: "8px",
    maxWidth: "748px",
    minHeight: "687.5px",
    maxHeight: "687.5px",
    overflow: "auto",
    width: "748px",
    bgcolor: "background.paper",
  },

  content: {
    display: "flex",
    flexDirection: "column",
    gap: 1,
    width: "100%",
  },
  form: { display: "flex", flexDirection: "row", gap: "64px" },
  textfield: {
    width: "340px",
    "@media (max-width: 920px)": {
      width: "100%",
    },
  },
  stack: {
    width: "100%",
  },
  boxForm: { display: "flex", flexDirection: "column", gap: 1, width: "100%" },
});

export default styles;
