const styles = (props) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "12px",
    bgcolor: "background.default",
    justifyContent: "center",
    padding: "16px",
    gap: 5,
    maxWidth: "60vw",
  },
  paperBox: {},
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    gap: 2,
  },
  headerIcon: {},
  headerText: {},
  content: {},
  contentText: { textAlign: "center" },
  boxButtons: {
    display: "flex",
    flexDirection: "row",
    width: 1,
    justifyContent: "flex-end",
    gap: 1,
  },
});

export default styles;
