import React, { useContext, useEffect, useState } from "react";

import {
  AppBar,
  Avatar,
  Box,
  CircularProgress,
  Container,
  CssBaseline,
  FormControl,
  Icon,
  IconButton,
  InputLabel,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Select,
  Stack,
  Toolbar,
  Tooltip,
  Typography,
  LinearProgress,
  Drawer,
  Paper,
  Fab,
} from "@mui/material";
import dayjs from "dayjs";
import "dayjs/locale/pt-br";

import MobileSliderBar from "./mobileDrawer";

import { Outlet, useNavigate } from "react-router-dom";
import useStyles from "./styles";
import { socketCliente as socket } from "../../../services/socket";

import logoComputizeDark from "../../../assets/imgs/logos/logocomputizeDark.webp";

import {
  DarkModeOutlined,
  LightModeOutlined,
  LogoutOutlined,
  PersonOutline,
  ArrowBackIosNewOutlined,
  FilterAltOutlined,
} from "@mui/icons-material";
import ModalPerfil from "../../../components/modalPerfil";
import { RangeContext } from "../range";
import SliderBar from "./sliderBar";

function CircularProgressWithLabel({ value = 0 }) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress
        variant={value <= 0 || value >= 60 ? "indeterminate" : "determinate"}
        value={value * (5 / 3)}
        sx={{ color: "#ffffff" }}
      />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="caption" component="div" color="#ffffff">
          {value <= 0 || value >= 60 ? 0 : `${Math.round(value)}`}
        </Typography>
      </Box>
    </Box>
  );
}

function LinearProgressWithLabel({ value = 0 }) {
  return (
    <Box>
      <LinearProgress
        variant={value <= 0 || value >= 60 ? "buffer" : "determinate"}
        value={value * (5 / 3)}
        sx={{ color: "#fff" }}
      />
    </Box>
  );
}

const maxWidth = 800;

export default function LayoutClient(params) {
  const { colorMode, setColorMode } = params;
  const [anchorEl, setAnchorEl] = useState(null);
  const [baseImage, setBaseImage] = useState(
    JSON.parse(localStorage.getItem("perfil"))?.foto || ""
  );
  const [windowWith, setWindowWith] = useState(window.innerWidth);
  const [openCollapsem, setOpenCollapse] = useState(false);
  const [openModalPerfil, setOpenModalPerfil] = useState(false);
  const { Range, setRange } = useContext(RangeContext);

  const [isConnected, setIsConnected] = useState(socket.connected);
  useEffect(() => {
    function onConnect() {
      setIsConnected(true);
      socket.emit(
        "first_connection",
        `Servidor juntou-se ao grupo id conection: ${socket.id}`
      );
    }
    function onDisconnect() {
      setIsConnected(false);
    }

    function connectErrorEvent(error) {
      console.log("Socket error - erro de conexão:", error);
    }
    function reconnectAttemptEvent(attempt) {
      console.log(
        `Socket warn - o servido esta tentando ser reconectar. N de tentativas: ${attempt}`
      );
    }
    function reconnectErrorEvent(error) {
      console.log("Socket error - erro de reeconexão:", error);
    }
    function reconnectEvent(attempt) {
      console.log("Socket success: servidor reeconectado com sucesso");
    }

    socket.connect();
    socket.on("connect", onConnect);
    socket.on("disconnect", onDisconnect);
    socket.on("connect_error", connectErrorEvent);
    socket.io.on("reconnect_attempt", reconnectAttemptEvent);
    socket.io.on("reconnect_error", reconnectErrorEvent);
    socket.io.on("reconnect", reconnectEvent);
    return () => {
      socket.off("connect", onConnect);
      socket.off("disconnect", onDisconnect);
      socket.off("connect_error", connectErrorEvent);
      socket.io.off("reconnect_attempt", reconnectAttemptEvent);
      socket.io.off("reconnect_error", reconnectErrorEvent);
      socket.io.off("reconnect", reconnectEvent);
    };
  }, []);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const styles = useStyles();
  const navigate = useNavigate();

  useEffect(() => {
    setBaseImage(
      localStorage.getItem("perfil") &&
        JSON.parse(localStorage.getItem("perfil")) != "undefined"
        ? JSON.parse(localStorage.getItem("perfil"))?.foto
        : ""
    );
  }, [localStorage.getItem("perfil")]);

  useEffect(() => {
    const handleResize = () => {
      setWindowWith(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
  }, []);

  const handleOpenCollapsem = () => {
    setOpenCollapse(!openCollapsem);
  };

  //funcoes de controle do menu
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate("/login");
  };

  const handleCloseModalPerfil = () => {
    setOpenModalPerfil(false);
  };

  return (
    <Box display={"flex"}>
      <CssBaseline />

      <Box>
        <AppBar
          position="fixed"
          elevation={0}
          // color="primary"
        >
          <Container maxWidth="100vw">
            <Toolbar sx={styles.toolbar}>
              <IconButton href={"/home"} sx={styles.IconButtonLogo}>
                <Icon style={styles.logo}>
                  <img
                    src={logoComputizeDark}
                    style={styles.logo}
                    alt="logo-computize-home"
                  />
                </Icon>
              </IconButton>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 1,
                  alignItems: "center",
                }}
              >
                {window.location.pathname === "/home" && (
                  <>
                    {windowWith < maxWidth ? (
                      <>
                        <Tooltip title={"Filtro"} placement="top-start">
                          <Fab
                            onClick={handleOpenCollapsem}
                            size="small"
                            fontSize="large"
                            color="primary"
                            sx={{
                              position: "fixed",
                              bottom: "30px",
                              right: "30px",
                            }}
                          >
                            <FilterAltOutlined />
                          </Fab>
                        </Tooltip>
                      </>
                    ) : (
                      <>
                        {Range?.timerStatus && (
                          <CircularProgressWithLabel
                            value={parseInt(Range?.timer) || 0}
                          />
                        )}
                        <FormControl
                          sx={{
                            width: "120px",
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#4a5057",
                              color: "#4a5057",
                            },
                            "& .MuiInputLabel-root": {
                              color: "#afb9b8",
                            },
                            svg: {
                              color: "#fff",
                            },
                            input: {
                              color: "#fff",
                            },
                          }}
                          size="small"
                        >
                          <InputLabel id="demo-simple-select-label">
                            Unidade
                          </InputLabel>
                          <Select
                            labelId="demo-customized-select-label"
                            id="demo-customized-select"
                            value={Range?.unidade || ""}
                            onChange={(event) =>
                              setRange({
                                ...Range,
                                unidade: event.target.value,
                              })
                            }
                            label="Filtro de tempo"
                            sx={{ color: "#fff" }}
                          >
                            <MenuItem value={"bps"}>bps</MenuItem>
                            <MenuItem value={"pps"}>pps</MenuItem>
                          </Select>
                        </FormControl>

                        <FormControl
                          sx={{
                            width: "240px",
                            "& .MuiOutlinedInput-notchedOutline": {
                              borderColor: "#4a5057",
                              color: "#4a5057",
                            },
                            "& .MuiInputLabel-root": {
                              color: "#afb9b8",
                            },
                            svg: {
                              color: "#fff",
                            },
                            input: {
                              color: "#fff",
                            },
                          }}
                          size="small"
                        >
                          <InputLabel id="demo-simple-select-label">
                            Filtro de tempo
                          </InputLabel>
                          <Select
                            labelId="demo-customized-select-label"
                            id="demo-customized-select"
                            value={Range?.range || ""}
                            onChange={(event) =>
                              setRange({ ...Range, range: event.target.value })
                            }
                            label="Filtro de tempo"
                            sx={{ color: "#fff" }}
                          >
                            <MenuItem value=""></MenuItem>
                            <MenuItem value={"hour"}>1 Hora</MenuItem>
                            <MenuItem value={"2hours"}>2 Horas</MenuItem>
                            <MenuItem value={"4hours"}>4 Horas</MenuItem>
                            <MenuItem value={"6hours"}>6 Horas</MenuItem>
                            <MenuItem value={"12hours"}>12 Horas</MenuItem>
                            <MenuItem value={"day"}>Dia</MenuItem>
                            <MenuItem value={"week"}>Semana</MenuItem>
                            {/*     <MenuItem value={"month"}>Mês</MenuItem> */}
                          </Select>
                        </FormControl>
                      </>
                    )}
                  </>
                )}
                <Tooltip title="Abrir Opções">
                  <IconButton id="basic-button" onClick={handleClick}>
                    <Avatar src={baseImage} />
                  </IconButton>
                </Tooltip>
              </Box>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                disableScrollLock={true}
              >
                <MenuItem
                  onClick={() => setOpenModalPerfil(true)} //</Menu>onClick={handlePerfil}]
                >
                  <ListItemIcon>
                    <PersonOutline />
                  </ListItemIcon>
                  <ListItemText>Perfil</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => setColorMode((prev) => !prev)}>
                  {colorMode ? (
                    <>
                      <ListItemIcon>
                        <DarkModeOutlined />
                      </ListItemIcon>
                      <ListItemText>Modo Noturno</ListItemText>
                    </>
                  ) : (
                    <>
                      <ListItemIcon>
                        <LightModeOutlined />
                      </ListItemIcon>
                      <ListItemText>Modo Claro</ListItemText>
                    </>
                  )}
                </MenuItem>

                <MenuItem onClick={handleLogout}>
                  <ListItemIcon>
                    <LogoutOutlined />
                  </ListItemIcon>
                  <ListItemText>Sair</ListItemText>
                </MenuItem>
              </Menu>
            </Toolbar>
          </Container>
          {windowWith < maxWidth ? (
            <Box>
              {Range?.timerStatus && (
                <LinearProgressWithLabel value={parseInt(Range?.timer) || 0} />
              )}
            </Box>
          ) : (
            <></>
          )}
          <Drawer
            open={openCollapsem}
            onClose={handleOpenCollapsem}
            anchor={"right"}
          >
            <Paper
              sx={{
                backgroundColor: "background.default",
                height: "100%",
                borderRadius: 0,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: 3,
                  margin: "20px 10px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "center",
                  }}
                >
                  <IconButton
                    sx={{ marginRight: "auto" }}
                    onClick={() => setOpenCollapse(false)}
                  >
                    <ArrowBackIosNewOutlined fontSize="small" />
                  </IconButton>
                  <Typography
                    sx={{
                      position: "absolute",
                      marginRight: "auto",
                    }}
                    variant="mySubtitle2"
                  >
                    Filtros
                  </Typography>
                </Box>

                <FormControl
                  sx={{
                    width: "280px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "primary",
                      color: "primary",
                    },
                    "& .MuiInputLabel-root": {
                      color: "primary",
                    },
                    svg: {
                      color: "primary",
                    },
                    input: {
                      color: "primary",
                    },
                  }}
                  size="small"
                >
                  <InputLabel
                    id="demo-simple-select-label"
                    sx={{ marginTop: "11px" }}
                  >
                    Unidade
                  </InputLabel>
                  <Select
                    labelId="demo-customized-select-label"
                    id="demo-customized-select"
                    value={Range?.unidade || ""}
                    onChange={(event) => {
                      setRange({
                        ...Range,
                        unidade: event.target.value,
                      });
                    }}
                    label="Unidade"
                    sx={{ color: "primary" }}
                    variant="filled"
                  >
                    <MenuItem value={"bps"}>bps</MenuItem>
                    <MenuItem value={"pps"}>pps</MenuItem>
                  </Select>
                </FormControl>

                <FormControl
                  sx={{
                    width: "280px",
                    "& .MuiOutlinedInput-notchedOutline": {
                      borderColor: "primary",
                      color: "primary",
                    },
                    "& .MuiInputLabel-root": {
                      color: "primary",
                    },
                    svg: {
                      color: "primary",
                    },
                    input: {
                      color: "primary",
                    },
                  }}
                  size="small"
                >
                  <InputLabel
                    id="demo-simple-select-label"
                    sx={{ marginTop: "11px" }}
                  >
                    Filtro de tempo
                  </InputLabel>
                  <Select
                    labelId="demo-customized-select-label"
                    id="demo-customized-select"
                    value={Range?.range || ""}
                    onChange={(event) => {
                      setRange({
                        ...Range,
                        range: event.target.value,
                      });
                    }}
                    label="Filtro de tempo"
                    sx={{ color: "primary" }}
                    variant="filled"
                  >
                    <MenuItem value=""></MenuItem>
                    <MenuItem value={"hour"}>1 Hora</MenuItem>
                    <MenuItem value={"2hours"}>2 Horas</MenuItem>
                    <MenuItem value={"4hours"}>4 Horas</MenuItem>
                    <MenuItem value={"6hours"}>6 Horas</MenuItem>
                    <MenuItem value={"12hours"}>12 Horas</MenuItem>
                    <MenuItem value={"day"}>Dia</MenuItem>
                    <MenuItem value={"week"}>Semana</MenuItem>
                    {/*  <MenuItem value={"month"}>Mês</MenuItem> */}
                  </Select>
                </FormControl>
              </Box>
            </Paper>
          </Drawer>
        </AppBar>
        <Box sx={{ height: "80px", width: "99vw" }} />
        <Stack direction={"row"} sx={{ backgroundColor: "drawer.main" }}>
          {windowWith > 760 && <SliderBar />}

          <MobileSliderBar open={drawerOpen} setOpen={setDrawerOpen} />
          <Box component="main" sx={styles.main}>
            <Box mt={1}>
              <Outlet />
            </Box>
          </Box>
        </Stack>
      </Box>

      <ModalPerfil
        open={openModalPerfil}
        handleClose={handleCloseModalPerfil}
      />
    </Box>
  );
}
