import {
  Box,
  Paper,
  Stack,
  Typography,
  TextField,
  IconButton,
  InputAdornment,
  Button,
  Tab,
  Tabs,
  Checkbox,
  Link,
  Snackbar,
  Alert,
  Avatar,
  Tooltip,
} from "@mui/material";
import { Visibility, VisibilityOff, PhotoCamera } from "@mui/icons-material";

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Resizer from "react-image-file-resizer";

import dayjs from "dayjs";
import "dayjs/locale/pt-br";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import api from "../../../services/api";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import axios from "axios";
import { border, padding } from "@mui/system";
import apiCliente from "../../../services/apiCliente";

const styles = {
  ContainerBox: {
    display: "flex",
    width: "100%",
    marginTop: "40px",
    justifyContent: "center",
    alignItems: " center",
    flexShrink: 0,
  },
  TextCampo: {
    width: "48%",
    minWidht: "220px",
  },
  TextCampoSecondary: {
    width: "100%",
  },
  Paper: {
    justifyContent: "flex-start",
    alignItems: " center",
    maxWidth: "980px",
    padding: "24px",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    gap: 2,
    borderRadius: "10px",
    position: "relative",
    //  margin: "200px",
  },
  Title: {
    fontWeight: "400",
    lineHeight: "123.5%",
    letterSpacing: "0.8px",

    textShadow: "0px 3px 3px rgba(0, 0, 0, 0.3)",
  },
  StackPage: {
    width: "460.5px",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "flex-start",
    gap: "16px",
  },
  ButtonFoto: {
    width: "150px",
    position: "absolute",
    display: " flex",
    alignItems: "center",
    margin: "90px",
    gap: "8px",
    fontSize: "15px",
  },
  StackFoto: {
    width: "100%",
    alignItems: "center",
    height: "130px",
  },
  Avatar: { width: 110, height: 110 },
  ButtonCadastrar: { width: "460px" },
};
const maskCPF = (value) => {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d{1,2})/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1");
};
const removeMask = (value) => {
  return value.replace(/\D/g, "");
};
const maskPhone = (value) => {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "($1) $2")
    .replace(/(\d{5})(\d)/, "$1-$2")
    .replace(/(-\d{4})(\d+?)$/, "$1");
};
const maskCellPhone = (value) => {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "($1) $2")
    .replace(/(\d{4})(\d)/, "$1-$2")
    .replace(/(-\d{4})(\d+?)$/, "$1");
};
const maskCnpj = (value) => {
  return value
    .replace(/\D/g, "")
    .replace(/(\d{2})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1.$2")
    .replace(/(\d{3})(\d)/, "$1/$2")
    .replace(/(\d{4})(\d)/, "$1-$2")
    .replace(/(-\d{2})\d+?$/, "$1");
};
const isValidCPF = (cpf) => {
  if (
    typeof cpf !== "string" ||
    cpf.length !== 11 ||
    !Array.from(cpf).filter((e) => e !== cpf[0]).length
  ) {
    return false;
  }
  let soma = 0;
  let resto;
  for (let i = 1; i <= 9; i++)
    soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(cpf.substring(9, 10))) return false;
  soma = 0;
  for (let i = 1; i <= 10; i++)
    soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) resto = 0;
  if (resto !== parseInt(cpf.substring(10, 11))) return false;
  return true;
};
const hasNumbersAndChars = (value) => {
  const hasNumber = /\d/.test(value); // Verifica se há pelo menos um número.
  const hasChar = /[a-zA-Z]/.test(value); // Verifica se há pelo menos um caractere (letra).
  return hasNumber && hasChar;
};

export default function Cadastro(props) {
  const [showPass, setShowPass] = useState(false);
  const [showPassConf, setShowPassConf] = useState(false);
  const [checkSenha, setCheckSenha] = useState(true);
  const [checkCaracterSenha, setCheckCaracterSenha] = useState(true);
  const [nome, setNome] = useState("");
  const [sobrenome, setSobrenome] = useState("");
  const [foto, setFoto] = useState("");
  const [telefone, setTelefone] = useState("");
  const [inputTelefone, setInputTelefone] = useState("");
  const [usuarioClienteId, setUsuarioClienteId] = useState("");
  const [senha, setSenha] = useState("");
  const [confirmarSenha, setConfirmarSenha] = useState("");
  const [alertSize, setAlertSize] = useState(false);
  const [showError, setShowError] = useState(false);
  const [checkCadastro, setCheckCadastro] = useState(true);
  const [logsLoginId, setLogsLoginId] = useState("");

  const navigate = useNavigate();
  const [snackbar, setSnackbar] = useState(null);

  const handleVoltar = () => {
    localStorage.clear();
    navigate("/login/interno");
    clearCampos();
  };
  useEffect(() => {
    let result = localStorage.getItem("userCliente");
    result = JSON.parse(result);
    setUsuarioClienteId(result.refreshToken.usuarioCliente);

    let resultPerfil = localStorage.getItem("perfil");
    if (resultPerfil && resultPerfil !== "undefined") {
      resultPerfil = JSON.parse(resultPerfil);
      if (resultPerfil?.status) {
        navigate("/interno/home");
      }
    }
    let resultLogLogin = localStorage.getItem("logs");
    if (resultLogLogin !== "undefined") {
      resultLogLogin = JSON.parse(resultLogLogin);
      setLogsLoginId(resultLogLogin.id);
    }
  }, []);
  useEffect(() => {
    if (confirmarSenha === senha) {
      setCheckSenha(false);
    } else {
      setCheckSenha(true);
    }
    if (senha.length >= 6 && hasNumbersAndChars(senha)) {
      setCheckCaracterSenha(false);
    } else {
      setCheckCaracterSenha(true);
    }
  }, [senha, confirmarSenha]);

  useEffect(() => {
    if (
      confirmarSenha === senha &&
      senha !== "" &&
      confirmarSenha !== "" &&
      nome !== "" &&
      sobrenome !== "" &&
      telefone !== ""
    ) {
      setCheckCadastro(false);
    } else {
      setCheckCadastro(true);
    }
  }, [senha, nome, sobrenome, confirmarSenha, telefone]);
  function clearCampos() {
    setNome("");
    setSobrenome("");
    setSenha("");
    setConfirmarSenha("");
    setTelefone("");
    setInputTelefone("");
    setShowError(false);
  }

  async function handleApi() {
    try {
      const response = await apiCliente.put("/cliente/update/perfil", {
        usuarioClienteId,
        foto,
        nome,
        sobrenome,
        telefone,
        status: true,
        novaSenha: senha,
        logsLoginId,
      });

      if (response.data.status === "Error") {
        setSnackbar({
          children: "Error: não foi possível salvar dados",
          severity: "error",
        });
      } else {
        localStorage.setItem("perfil", JSON.stringify(response.data));
        let jsonUser = JSON.parse(localStorage.getItem("user"));
        jsonUser["perfilAlreadyExists"] = true;
        localStorage.setItem("user", JSON.stringify(jsonUser));
        navigate("/home");
        setSnackbar({
          children: "Dados salvos com sucesso",
          severity: "success",
        });
      }
    } catch (error) {
      console.error(error);
      setSnackbar({
        children: "Error: Não foi possível se conectar com o servidor",
        severity: "error",
      });
    }
  }
  return (
    <>
      <Box sx={styles.ContainerBox}>
        <Paper sx={styles.Paper}>
          <Stack
            sx={{
              position: "absolute",
              left: 10,
              top: 10,
            }}
          >
            <Tooltip title={"Voltar"}>
              <IconButton
                aria-label="button-back"
                onClick={() => {
                  handleVoltar();
                }}
              >
                <ArrowBackIcon />
              </IconButton>
            </Tooltip>
          </Stack>

          <Typography variant="h5" sx={styles.Title}>
            Cadastro
          </Typography>

          <Stack direction={"column"} sx={styles.StackPage}>
            <Stack sx={styles.StackFoto}>
              <Avatar src={foto} color="secondary" sx={styles.Avatar} />
              <Button
                variant="contained"
                component="label"
                sx={styles.ButtonFoto}
                size="small"
              >
                <PhotoCamera fontSize="small" />
                Adicionar
                <input
                  hidden
                  accept=".png, .jpg, .jpeg"
                  type="file"
                  onChange={(e) => {
                    const file = e.target.files[0];
                    if (file?.size < 1024 * 300) {
                      //1024 byte = 1 kbyte
                      // 1 MB
                      Resizer.imageFileResizer(
                        file,
                        500,
                        500,
                        "JPEG",
                        80,
                        0,
                        (uri) => {
                          setFoto(uri);
                        },
                        "base64",
                        500,
                        500
                      );
                      const fileReader = new FileReader();
                      fileReader.readAsDataURL(file);
                      fileReader.onload = () => {};
                      fileReader.onerror = (error) => {
                        console.log("Error:", error);
                      };
                    } else {
                      setAlertSize(true);
                    }
                  }}
                />{" "}
              </Button>
            </Stack>

            <Stack
              direction={"row"}
              spacing={1}
              justifyContent={"space-between"}
            >
              <TextField
                required
                id="nome"
                size="small"
                label="Nome"
                variant="filled"
                value={nome}
                sx={styles.TextCampo}
                onChange={(event) => {
                  setNome(event.target.value);
                }}
              />
              <TextField
                required
                size="small"
                id="sobrenome"
                label="Sobrenome"
                variant="filled"
                value={sobrenome}
                sx={styles.TextCampo}
                onChange={(event) => {
                  setSobrenome(event.target.value);
                }}
              />
            </Stack>
            <TextField
              required
              size="small"
              id="telefone"
              label="Telefone"
              variant="filled"
              value={inputTelefone}
              sx={styles.TextCampoSecondary}
              onChange={(event) => {
                const value = event.target.value;
                setTelefone(removeMask(value));
                if (value.length <= 14) {
                  setInputTelefone(maskCellPhone(value));
                } else {
                  setInputTelefone(maskPhone(value));
                }
              }}
            />

            <TextField
              required
              error={checkCaracterSenha && showError}
              sx={styles.TextCampoSecondary}
              size="small"
              name="senha"
              label="Senha"
              variant="filled"
              color="primary"
              helperText={
                checkCaracterSenha &&
                showError &&
                "As senhas devem ter no mínimo 6 caracteres e conter pelo menos uma letra ou um número."
              }
              type={showPass ? "text" : "password"}
              value={senha}
              onChange={(event) => {
                setSenha(event.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={() => setShowPass((event) => !event)}
                    >
                      {showPass ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              required
              size="small"
              error={checkSenha}
              sx={styles.TextCampoSecondary}
              name="confirmarsenha"
              label="Confirmar nova senha"
              variant="filled"
              color="primary"
              helperText={checkSenha && "Senhas não correspondem"}
              type={showPassConf ? "text" : "password"}
              value={confirmarSenha}
              onChange={(event) => {
                setConfirmarSenha(event.target.value);
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={() => setShowPassConf((event) => !event)}
                    >
                      {showPassConf ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Stack>

          <Stack>
            <Button
              variant="contained"
              sx={styles.ButtonCadastrar}
              disabled={checkCadastro}
              onClick={() => {
                if (checkCaracterSenha) {
                  setShowError(true);
                } else {
                  handleApi();
                }
              }}
            >
              Cadastrar
            </Button>
          </Stack>
        </Paper>
      </Box>{" "}
      <Snackbar
        autoHideDuration={3000}
        open={alertSize}
        onClose={() => {
          setAlertSize(false);
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert severity="error">Tamanho máximo permitido: 300KB</Alert>
      </Snackbar>
      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={3000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
    </>
  );
}
