import axios from "axios";
import tokenUpdateCliente from "./tokenUpdateCliente";

const token = () => {
  let result = localStorage.getItem("userCliente");
  result = JSON.parse(result);
  //console.log(result);
  if (result) {
    return result.token;
  } else {
    return "";
  }
};
const usuarioId = () => {
  let result = localStorage.getItem("userCliente");
  result = JSON.parse(result);

  if (result) {
    //const codigos = ;
    return result?.usuarioCliente?.id || "";
  } else {
    return "";
  }
};
const apiCliente = axios.create({});

apiCliente.interceptors.request.use(async (config) => {
  const url = `${
    window?.config?.REACT_APP_BASE_URL || process.env.REACT_APP_BASE_URL || ""
  }`;

  if (token()) {
    try {
      const tokenAtual = token();
      const user = usuarioId();

      config.baseURL = url;
      config.headers.Authorization = tokenAtual ? `Bearer ${tokenAtual}` : "";
      config.headers["x-user-id"] = user ? `${user}` : "";
      // atualiza o token antes de continuar com a requisição
      await tokenUpdateCliente(token());
      return config;
    } catch (error) {
      console.error(error);
    }
  } else {
    localStorage.clear();
    window.location.replace("/login");
  }
});

export default apiCliente;
