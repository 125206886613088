import {
  Box,
  Button,
  Modal,
  Paper,
  Stack,
  TextField,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";
import { useEffect, useState } from "react";
import validarIp from "../../../../utils/validadorIp";
import useStyles from "./styles";
import apiCliente from "../../../../services/apiCliente";

export default function ModalEditar(params) {
  const {
    openEditar = false,
    setOpenEditar = () => {},
    setSnackbar = () => {},
    selectedCard = {},
    handleUpdated = () => {},
  } = params;
  const styles = useStyles();
  const [loading, setLoading] = useState(false);
  const [tunelValidado, setTunelValidado] = useState(false);
  const [errors, setErrors] = useState({});

  const [formData, setFormData] = useState({
    id: selectedCard?.id || "",
    nome: selectedCard?.tituloTunel || "",
    ipTunnelLocal: selectedCard?.ipTunnelLocal || "",
    ipTunnelCliente: selectedCard?.ipTunnelCliente || "",
    ipDestiny: selectedCard?.ipDestiny || "",
    ipSource: selectedCard?.ipSource || "",
    idTunnelCliente: selectedCard?.idTunnelCliente || "",
  });

  useEffect(() => {
    if (openEditar) {
      setFormData({
        id: selectedCard?.id || "",
        nome: selectedCard?.nome || "",
        ipTunnelLocal: selectedCard?.ipTunnelLocal || "",
        ipTunnelCliente: selectedCard?.ipTunnelCliente || "",
        ipDestiny: selectedCard?.ipDestiny || "",
        ipSource: selectedCard?.ipSource || "",
        idTunnelCliente: selectedCard?.idTunnelCliente || "",
      });
    }
  }, [openEditar]);

  function handleInputChange(e) {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
    if (errors[name]) setErrors((prev) => ({ ...prev, [name]: "" }));
  }
  function handleBlur(value, name) {
    if (name === "ipTunnelCliente") {
      if (value.trim() === "") {
        setTunelValidado(false);
        setErrors((prev) => ({
          ...prev,
          [name]: "IP obrigatório",
        }));
      } else {
        const isValid = validarIp(value, "justIpv4");

        if (isValid === 1) {
          setErrors((prev) => ({ ...prev, [name]: "" }));
          setTunelValidado(true);
        } else {
          setErrors((prev) => ({
            ...prev,
            [name]: "IP inválido",
          }));
        }
      }
    }
  }

  async function handleSubmit() {
    if (tunelValidado) {
      setLoading(true);
      try {
        let formError = false;
        let isValid;

        if (formData?.ipTunnelCliente.trim() === "") {
          setErrors((prev) => ({
            ...prev,
            ipTunnelCliente: "IP obrigatório",
          }));
          formError = true;
        } else {
          isValid = validarIp(formData?.ipTunnelCliente, "justIpv4");
          if (isValid === 1) {
            setErrors((prev) => ({ ...prev, ipTunnelCliente: "" }));
          } else {
            setErrors((prev) => ({
              ...prev,
              ipTunnelCliente: "IP inválido",
            }));
            formError = true;
          }
        }

        const response = await apiCliente.put("/solicitacoes/update/tuneis", {
          id: formData?.id,
          ipTunnelCliente: formData?.ipTunnelCliente,
        });

        // on success
        setErrors({});
        handleClose();
        handleUpdated(formData);
        setSnackbar({
          children: "Túnel editado com sucesso!",
          severity: "success",
        });
        handleClose();
      } catch (error) {
        console.log(error);
        const newErrors = {};
        error?.errors?.forEach((err) => {
          newErrors[err.path[0]] = err.message;
        });
        setErrors(newErrors);
        setSnackbar({
          children: "Error: Não foi possivel se conectar ao servidor",
          severity: "error",
        });
      } finally {
        setLoading(false);
      }
    }
  }

  const handleClose = () => {
    setOpenEditar(false);
    setFormData({
      nome: "",
      ipTunnelLocal: "",
      ipTunnelCliente: "",
      idTunnelCliente: "",
    });
  };

  return (
    <Modal open={openEditar} onClose={handleClose} sx={styles.container}>
      <Paper sx={styles.paper}>
        <Box sx={styles.boxPaper}>
          <Typography variant="mySubtitle">Editar Túnel</Typography>

          <Box sx={styles.boxContent}>
            <Stack direction={"row"} gap={1} flexWrap={"nowrap"}>
              <TextField
                autoComplete="off"
                sx={styles.textField}
                variant="filled"
                id="nome"
                name="nome"
                label="Título do Túnel"
                value={formData?.nome}
                onChange={handleInputChange}
                onBlur={(e) => handleBlur(e.target.value, e.target.name)}
                error={Boolean(errors?.nome)}
                helperText={errors?.nome}
                disabled={true}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    event.preventDefault();
                    document.getElementById("idTunnelCliente").focus();
                  }
                }}
              />
              <TextField
                disabled={true}
                autoComplete="off"
                sx={styles.textField}
                variant="filled"
                id="idTunnelCliente"
                name="idTunnelCliente"
                label="Tunnel ID"
                value={formData?.idTunnelCliente}
                onChange={handleInputChange}
                onBlur={(e) => handleBlur(e.target.value, e.target.name)}
                error={Boolean(errors?.idTunnelCliente)}
                helperText={errors?.idTunnelCliente}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    event.preventDefault();
                    document.getElementById("ipTunnelLocal").focus();
                  }
                }}
              />
            </Stack>

            <Stack direction={"row"} gap={1} flexWrap={"nowrap"}>
              <TextField
                disabled={true}
                autoComplete="off"
                sx={styles.textField}
                variant="filled"
                id="ipTunnelLocal"
                name="ipTunnelLocal"
                label="Tunnel Local"
                value={formData?.ipTunnelLocal}
                onChange={handleInputChange}
                onBlur={(e) => handleBlur(e.target.value, e.target.name)}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    event.preventDefault();
                    document.getElementById("ipTunnelCliente").focus();
                  }
                }}
                error={Boolean(errors?.ipTunnelLocal)}
                helperText={errors?.ipTunnelLocal}
              />
              <TextField
                autoComplete="off"
                sx={styles.textField}
                variant="filled"
                id="ipTunnelCliente"
                name="ipTunnelCliente"
                label="Tunnel Cliente"
                value={formData?.ipTunnelCliente}
                onChange={handleInputChange}
                onBlur={(e) => handleBlur(e.target.value, e.target.name)}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    event.preventDefault();
                    document.getElementById("ipSource").focus();
                  }
                }}
                error={Boolean(errors?.ipTunnelCliente)}
                helperText={errors?.ipTunnelCliente}
              />
            </Stack>
            <Stack direction={"row"} gap={1} flexWrap={"nowrap"}>
              <TextField
                disabled={true}
                autoComplete="off"
                sx={styles.textField}
                variant="filled"
                id="ipSource"
                name="ipSource"
                label="Source"
                value={formData?.ipSource}
                onChange={handleInputChange}
                onBlur={(e) => handleBlur(e.target.value, e.target.name)}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    event.preventDefault();
                    document.getElementById("ipDestiny").focus();
                  }
                }}
                error={Boolean(errors?.ipSource)}
                helperText={errors?.ipSource}
              />
              <TextField
                disabled={true}
                autoComplete="off"
                sx={styles.textField}
                variant="filled"
                id="ipDestiny"
                name="ipDestiny"
                label="Destination"
                value={formData?.ipDestiny}
                onChange={handleInputChange}
                onBlur={(e) => handleBlur(e.target.value, e.target.name)}
                error={Boolean(errors?.ipDestiny)}
                helperText={errors?.ipDestiny}
              />
            </Stack>
          </Box>
          <Stack
            direction={"row"}
            gap={1}
            justifyContent={"flex-end"}
            width={"100%"}
            flexWrap={"nowrap"}
          >
            <Button onClick={handleClose} disabled={loading}>
              Cancelar
            </Button>
            <Button
              variant="contained"
              onClick={handleSubmit}
              disabled={loading}
            >
              Salvar
            </Button>
          </Stack>
        </Box>
      </Paper>
    </Modal>
  );
}
