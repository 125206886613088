import { Add, Delete, Edit, MoreVert } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Snackbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import useStyles from "./styles";
import ModalAdicionar from "./modalAdicionar";
import ModalEditar from "./modalEditar";
import InternoModalDeleteCliente from "../../../../../components/internoModalDeleteCliente";
import api from "../../../../../services/api";
import validarIp from "../../../../../utils/validadorIp";

export default function Vlan(params) {
  const { clienteInfo } = params;
  const styles = useStyles();
  const [vlanIndex, setVlanIndex] = useState(0);
  const [errors, setErrors] = useState({});
  const [snackbar, setSnackbar] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [openAdicionar, setOpenAdicionar] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteLoad, setDeleteLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const deleteBody =
    "Se você prosseguir com a operação, o item e todas as informações associadas serão excluídas permanentemente!";
  const confirmDelete = "EXCLUIR";
  const open = Boolean(anchorEl);

  //vlan
  const [data, setData] = useState([]);
  const [selectedCard, setSelectedCard] = useState(null);

  //fetch Data
  useEffect(() => {
    handleApiGetVlans();
  }, []);
  async function handleApiGetVlans() {
    try {
      setLoading(true);
      const response = await api.post("/interno/find/vlans", {
        clienteId: clienteInfo?.id || "",
      });
      setData(response?.data);
    } catch (error) {
      setSnackbar({
        children: "Error: Não foi possivel se conectar ao servidor",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  }

  function handleInputChange(e) {
    const { name, value } = e.target;

    if (name === "vlan") {
      setVlanIndex(value);
    } else {
      let newArray = [...data];
      newArray[vlanIndex] = { ...newArray[vlanIndex], [name]: value };
      setData(newArray);
    }
    if (errors[name]) setErrors((prev) => ({ ...prev, [name]: "" }));
  }

  function handleAddVlan(formData) {
    let newForm = [...data];
    const newArray = data;
    newArray.push({
      id: data?.length + 1, //Substituir pelo retorno de api.create
      titulo: formData?.titulo,
      vlanId: formData?.vlanId,
      ipLocal: formData?.ipLocal,
      ipCliente: formData?.ipCliente,
      asnVlan: formData?.asnVlan,
    });
    newForm = newArray;
    setData(newForm);
  }

  function handleUpdated(formData) {
    let newArray = data?.map((data) => {
      if (data?.id === formData?.id) {
        return formData;
      }
      return data;
    });
    setData(newArray);
  }

  async function handleDelete(id) {
    try {
      setDeleteLoad(true);

      const response = await api.post("/interno/delete/vlan", {
        id: selectedCard.id,
      });
      // on success

      setErrors({});

      setOpenDeleteModal(false);
      let newForm = { ...data };
      const newArray = data;
      newArray.splice(id, 1);
      newForm = newArray;
      setData(newForm);
      setSnackbar({
        children: "Vlan removida com sucesso!",
        severity: "success",
      });
      handleClose();
    } catch (error) {
      console.log(error);
      const newErrors = {};
      error?.errors?.forEach((err) => {
        newErrors[err.path[0]] = err.message;
      });
      setErrors(newErrors);
      setSnackbar({
        children: "Error: Não foi possivel se conectar ao servidor",
        severity: "error",
      });
    } finally {
      setDeleteLoad(false);
    }
  }

  //anchor/menu
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCopy = (text) => {
    navigator.clipboard.writeText(text);
    setSnackbar({
      children: "Campo copiado!",
      severity: "info",
    });
  };

  return (
    <Box sx={styles.container}>
      <Box sx={styles.header}>
        <Typography variant="mySubtitle">Configurações de VLAN</Typography>

        <Button
          variant="contained"
          startIcon={<Add />}
          size="medium"
          onClick={() => {
            setOpenAdicionar(true);
          }}
          disabled={loading}
        >
          NOVA VLAN
        </Button>
      </Box>
      <Grid container spacing={2}>
        {Array.isArray(data) &&
          data?.map(
            (vlan, index) =>
              validarIp(vlan?.ipLocal, "justIpv4") === 1 &&
              validarIp(vlan?.ipCliente, "justIpv4") === 1 && (
                <Grid item lg={12} xl={6}>
                  {
                    //vlan.titulo
                  }
                  <Paper
                    variant="outlined"
                    key={vlan}
                    value={index}
                    sx={styles.paper}
                  >
                    <Box sx={styles.cardHeader}>
                      <Box>
                        <Typography variant="caption" sx={styles.cardSubTitle}>
                          Título da Vlan
                        </Typography>
                        <Typography>{vlan?.titulo}</Typography>
                      </Box>
                      <IconButton
                        onClick={(event) => {
                          setAnchorEl(event.currentTarget);
                          setSelectedCard(vlan);
                        }}
                        disabled={loading}
                      >
                        <MoreVert />
                      </IconButton>
                    </Box>
                    <Box sx={styles.cardRow}>
                      <Box sx={styles.cardItem}>
                        <Typography variant="caption" sx={styles.cardSubTitle}>
                          IP Local
                        </Typography>

                        <Tooltip title={vlan?.ipLocal}>
                          <span
                            onClick={() => {
                              handleCopy(vlan?.ipLocal);
                            }}
                          >
                            <Typography sx={styles.cardText}>
                              {vlan?.ipLocal}
                            </Typography>
                          </span>
                        </Tooltip>
                      </Box>
                      <Box sx={styles.cardItem}>
                        <Typography variant="caption" sx={styles.cardSubTitle}>
                          IP Cliente
                        </Typography>

                        <Tooltip title={vlan?.ipCliente}>
                          <span
                            onClick={() => {
                              handleCopy(vlan?.ipCliente);
                            }}
                          >
                            <Typography sx={styles.cardText}>
                              {vlan?.ipCliente}
                            </Typography>
                          </span>
                        </Tooltip>
                      </Box>
                    </Box>
                    <Box sx={styles.cardRow}>
                      <Box sx={styles.cardItem}>
                        <Typography variant="caption" sx={styles.cardSubTitle}>
                          ASN Vlan
                        </Typography>
                        <Tooltip title={vlan?.asnVlan}>
                          <span
                            onClick={() => {
                              handleCopy(vlan?.asnVlan);
                            }}
                          >
                            <Typography sx={styles.cardText}>
                              {vlan?.asnVlan}
                            </Typography>
                          </span>
                        </Tooltip>
                      </Box>
                      <Box sx={styles.cardItem}>
                        <Typography variant="caption" sx={styles.cardSubTitle}>
                          Vlan ID
                        </Typography>
                        <Tooltip title={vlan?.vlanId}>
                          <span
                            onClick={() => {
                              handleCopy(vlan?.vlanId);
                            }}
                          >
                            <Typography sx={styles.cardText}>
                              {vlan?.vlanId}
                            </Typography>
                          </span>
                        </Tooltip>
                      </Box>
                    </Box>
                  </Paper>
                </Grid>
              )
          )}
      </Grid>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem
          onClick={() => {
            setEditMode(true);
            setAnchorEl(null);
          }}
        >
          <Edit sx={{ marginRight: "16px" }}></Edit> Editar
        </MenuItem>
        <Divider />
        <MenuItem
          onClick={() => {
            setOpenDeleteModal(true);
            setAnchorEl(null);
          }}
        >
          <Delete sx={{ marginRight: "16px" }}></Delete> Deletar
        </MenuItem>
      </Menu>
      <ModalAdicionar
        openAdicionar={openAdicionar}
        setOpenAdicionar={setOpenAdicionar}
        handleAddVlan={handleAddVlan}
        setSnackbar={setSnackbar}
        clienteId={clienteInfo?.id}
        suggestedTitle={data[data?.length - 1]?.titulo || ""}
      />
      <ModalEditar
        openEditar={editMode}
        setOpenEditar={setEditMode}
        selectedCard={selectedCard}
        setSnackbar={setSnackbar}
        handleUpdated={handleUpdated}
        clienteId={clienteInfo?.id}
      />
      <InternoModalDeleteCliente
        openDeleteModal={openDeleteModal}
        deleteLoad={deleteLoad}
        setOpenDeleteModal={setOpenDeleteModal}
        execute={handleDelete}
        severity={"error"}
        contentText={deleteBody}
        confirmText={confirmDelete}
      />
      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2500}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
    </Box>
  );
}

const initialData = [
  {
    id: 1,
    titulo: "1",
    vlanId: "13456789", //{0-9}
    ipLocal: "2804:404:404", //{ipv4}
    ipCliente: "198.19.232.5", //{ipv4}
    asnVlan: "268659",
  },
  {
    id: 2,
    titulo: "2",
    vlanId: "987654321",
    ipLocal: "198.19.232.6",
    ipCliente: "198.19.232.5",
    asnVlan: "268659",
  },
  {
    id: 3,
    titulo: "1",
    vlanId: "13456789", //{0-9}
    ipLocal: "2804:404:404", //{ipv4}
    ipCliente: "198.19.232.5", //{ipv4}
    asnVlan: "268659",
  },
  {
    id: 4,
    titulo: "2",
    vlanId: "987654321",
    ipLocal: "198.19.232.6",
    ipCliente: "198.19.232.5",
  },
  {
    id: 5,
    titulo: "1",
    vlanId: "13456789", //{0-9}
    ipLocal: "2804:404:404", //{ipv4}
    ipCliente: "198.19.232.5", //{ipv4}
    asnVlan: "268659",
  },
  {
    id: 6,
    titulo: "2",
    vlanId: "987654321",
    ipLocal: "198.19.232.6",
    ipCliente: "198.19.232.5",
  },
  {
    id: 7,
    titulo: "1",
    vlanId: "13456789", //{0-9}
    ipLocal: "2804:404:404", //{ipv4}
    ipCliente: "198.19.232.5", //{ipv4}
    asnVlan: "268659",
  },
  {
    id: 8,
    titulo: "2",
    vlanId: "987654321",
    ipLocal: "198.19.232.6",
    ipCliente: "198.19.232.5",
    asnVlan: "268659",
  },
  {
    id: 9,
    titulo: "1",
    vlanId: "13456789", //{0-9}
    ipLocal: "2804:404:404", //{ipv4}
    ipCliente: "198.19.232.5", //{ipv4}
    asnVlan: "268659",
  },
  {
    id: 10,
    titulo: "2",
    vlanId: "987654321",
    ipLocal: "198.19.232.6",
    ipCliente: "198.19.232.5",
  },
  {
    id: 11,
    titulo: "1",
    vlanId: "13456789", //{0-9}
    ipLocal: "2804:404:404", //{ipv4}
    ipCliente: "198.19.232.5", //{ipv4}
    asnVlan: "268659",
  },
  {
    id: 12,
    titulo: "2",
    vlanId: "987654321",
    ipLocal: "198.19.232.6",
    ipCliente: "198.19.232.5",
    asnVlan: "268659",
  },
];
