import { React, useState } from "react";
import { Box, Tab } from "@mui/material";

import TuneisCliente from "./tuneis";
import VlanCliente from "./vlans";
import PrefixosCliente from "./prefixos";
import SessoesCliente from "./sessoes";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

export default function ConfiguracoesCliente(params) {
  const [valueTabs, setValueTabs] = useState("1");

  const handleChangeTabs = (event, newValue) => {
    setValueTabs(newValue);
  };

  ////////////////////////////////////////////////////////////////////////////

  /*  const statusPrefixosLabel = {
    0: "Pendente",
    1: "Em liberação",
    2: "Concluido",
  };

  const statusPrefixosColor = {
    0: "error",
    1: "warning",
    2: "success",
  }; */

  return (
    <Box sx={{ width: "100%" }}>
      <TabContext value={valueTabs}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={handleChangeTabs}
            aria-label="lab API tabs example"
          >
            <Tab label="PREFIXOS" value="1" />
            <Tab label="Tuneis" value="2" />
            <Tab label="VLAN" value="3" />
            <Tab label="Sessões" value="4" />
          </TabList>
        </Box>

        <TabPanel value="1">
          <PrefixosCliente />
        </TabPanel>
        <TabPanel value="2">
          <TuneisCliente />
        </TabPanel>
        <TabPanel value="3">
          <VlanCliente />
        </TabPanel>
        <TabPanel value="4">
          <SessoesCliente />
        </TabPanel>
      </TabContext>

      <Box sx={{ width: "100%", height: "50px" }}></Box>
    </Box>
  );
}
