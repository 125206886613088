const styles = (props) => ({
  modal: { display: "flex", justifyContent: "center", alignItems: "center" },
  container: {
    bgcolor: "background.default",
    display: "flex",
    padding: "24px",
    borderRadius: "8px",
    maxWidth: "748px",
    width: "80%",
    maxHeight: "89vh",
    overflow: "auto",
    flexDirection: "column",
    gap: 2,
  },
  body: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
    gap: "24px",
  },
  content: {
    display: "flex",
    flexDirection: "column",
    gap: 1,
    width: "100%",
  },
  form: { display: "flex", flexDirection: "row", gap: "64px" },
  textfield: {
    width: "340px",
    "@media (max-width: 920px)": {
      width: "100%",
    },
  },
  asTextField: {
    width: "340px",
    "@media (max-width: 920px)": {
      width: "100%",
    },
    "& .MuiInputLabel-root.Mui-error": {
      color: "secondary.main",
    },
    "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
      border: "3px solid",
      borderColor: "secondary.main",
    },
    "& .MuiFormHelperText-root.Mui-error": {
      color: "secondary.main",
    },
  },
  stack: {
    width: "100%",
  },
  boxForm: { display: "flex", flexDirection: "column", gap: 1, width: "100%" },
  button: { "@media (max-width: 340px)": { width: "100%" } },
});

export default styles;
