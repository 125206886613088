import { Add, Delete } from "@mui/icons-material";
import {
  Alert,
  Box,
  Button,
  IconButton,
  Paper,
  Snackbar,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";

import { useEffect, useState } from "react";
import api from "../../../../services/api";
import InternoModalDeleteCliente from "../../../../components/internoModalDeleteCliente";

export default function Comunidades(params) {
  const { setChanged = () => {} } = params;
  const [editComunidade, setEditComunidade] = useState(false);
  const [comunidades, setComunidades] = useState([]);
  const [comunidadesManipulation, setComunidadesManipulation] = useState([]);
  const [snackbar, setSnackbar] = useState(null);
  const [errors, setErrors] = useState({});
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteLoad, setDeleteLoad] = useState(false);

  const theme = useTheme();

  useEffect(() => {
    if (comunidades) {
      setComunidadesManipulation(JSON.parse(JSON.stringify(comunidades)));
    }
  }, [comunidades]);

  useEffect(() => {
    handleApiGetComunidades();
  }, []);

  async function handleApiGetComunidades() {
    try {
      const response = await api.get("/findMany/comunidades");

      setComunidades(response.data);
    } catch (error) {
      setSnackbar({
        children: `Error:${
          error?.message || "Não foi possível se buscar as comunidades"
        } `,
        severity: "error",
      });
    }
  }

  async function handleApiUpdateComunidades() {
    try {
      setDeleteLoad(true);
      const response = await api.put("/editMany/comunidades", {
        comunidades: comunidadesManipulation,
      });
      setComunidades(response.data);
      setEditComunidade(false);
      setSnackbar({
        children: "Comunidades atualizadas com sucesso",
        severity: "success",
      });
    } catch (error) {
      setSnackbar({
        children: `Error:${
          error?.message || "Não foi possível atualizar as comunidades"
        } `,
        severity: "error",
      });
    } finally {
      setDeleteLoad(false);
      setOpenDeleteModal(false);
    }
  }

  function deleteComunidade(index) {
    setChanged(true);
    const array = [...comunidadesManipulation];
    array.splice(index, 1);
    setComunidadesManipulation(array);
  }

  function addComunidade() {
    const array = [...comunidadesManipulation];
    array.push({ id: parseInt(Math.random() * 1000000), nome: "" });
    setComunidadesManipulation(array);
  }

  function validateFields() {
    const newErrors = {};
    comunidadesManipulation.forEach((comunidade, index) => {
      if (comunidade.nome.trim() === "") {
        newErrors[index] = true;
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  }

  function handleSave() {
    if (validateFields()) {
      // Obter os IDs das comunidades que foram manipuladas
      const comunidadesManipulationIds = comunidadesManipulation?.map(
        (com) => com.id
      );

      // Filtrar as comunidades que foram removidas
      const comunidadesRemovidas = comunidades?.map(
        (com) => !comunidadesManipulationIds.includes(com.id)
      );

      // Se tiver alguma removida
      if (comunidadesRemovidas.includes(true)) {
        setOpenDeleteModal(true);
      } else {
        handleApiUpdateComunidades(comunidadesManipulation);
      }
    }
  }

  return (
    <Paper
      sx={{
        padding: "21px",
        display: "flex",
        flexDirection: "column",
        gap: "13px",
        bgcolor:
          theme.palette.mode === "dark" ? "transparent" : "background.paper",
      }}
      variant={theme.palette.mode === "dark" ? "outlined" : "elevation"}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography sx={{ fontSize: "21px" }}>
          Configurações de comunidades
        </Typography>
        {editComunidade && (
          <Button variant="outlined" endIcon={<Add />} onClick={addComunidade}>
            Adicionar Comunidade
          </Button>
        )}
      </Box>
      <Paper
        sx={{
          padding: "21px",
          display: "flex",
          flexDirection: "column",
          gap: "13px",
          bgcolor: "transparent",
        }}
        variant="outlined"
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "13px",
            flexWrap: "wrap",
            alignItems: "flex-start",
          }}
        >
          {comunidadesManipulation.map((comunidade, index) => (
            <Box
              key={comunidade.id}
              sx={{ display: "flex", alignItems: "center" }}
            >
              <TextField
                variant="filled"
                label="Comunidade"
                value={comunidade.nome}
                onChange={(e) => {
                  setChanged(true);
                  const array = [...comunidadesManipulation];
                  array[index].nome = e.target.value;
                  setComunidadesManipulation(array);
                  setErrors((prev) => ({ ...prev, [index]: false }));
                }}
                size="small"
                autoComplete="off"
                error={!!errors[index]}
                helperText={errors[index] ? "Este campo é obrigatório" : ""}
                disabled={!editComunidade}
                sx={{ width: "250px" }}
              />
              {editComunidade ? (
                <IconButton onClick={() => deleteComunidade(index)}>
                  <Delete color="primary" />
                </IconButton>
              ) : (
                <Box sx={{ width: "40px" }}></Box>
              )}
            </Box>
          ))}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: "13px",
            justifyContent: "flex-end",
          }}
        >
          {!editComunidade ? (
            <Button
              variant="contained"
              size="small"
              onClick={() => {
                setEditComunidade(true);
              }}
            >
              Editar
            </Button>
          ) : (
            <>
              <Button
                variant="outlined"
                size="small"
                onClick={() => {
                  setChanged(false);
                  setEditComunidade(false);
                  setComunidadesManipulation(
                    JSON.parse(JSON.stringify(comunidades))
                  );
                  setErrors({});
                }}
              >
                Cancelar
              </Button>
              <Button variant="contained" size="small" onClick={handleSave}>
                Salvar
              </Button>
            </>
          )}
        </Box>
      </Paper>
      {!!snackbar && (
        <Snackbar
          open
          onClose={() => setSnackbar(null)}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert {...snackbar} onClose={() => setSnackbar(null)} />
        </Snackbar>
      )}
      <InternoModalDeleteCliente
        openDeleteModal={openDeleteModal}
        load={deleteLoad}
        setOpenDeleteModal={setOpenDeleteModal}
        execute={() => {
          handleApiUpdateComunidades(comunidadesManipulation);
        }}
        severity={"error"}
        contentText={
          "Uma ou mais comunidades foram removidas, se você prosseguir com a operação, as informações serão excluídas permanentemente!"
        }
        confirmText={"CONFIRMAR"}
      />
    </Paper>
  );
}
