import { display, padding } from "@mui/system";

const styles = (theme) => ({
  paperContent: {
    // padding: "24px",
    borderRadius: "16px",
    backgroundColor: theme.palette.mode === "dark" && "transparent",
    display: "flex",
    flexDirection: "column",
    gap: "16px",

    "@media(max-width:640px)": { padding: "0 0 12px 0" },
  },
  listContentMobile: {
    width: "100%",
    padding: 0,
  },
  boxList: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    padding: "5px",
  },
  chip: {
    padding: "5px",
    fontSize: "10px",
    textAlign: "center",
    width: "100px",
    position: "absolute",
    marginLeft: "50%",
    height: "auto",
    "& .MuiChip-label": {
      display: "block",
      whiteSpace: "normal",
    },
  },
  notificacoesPaper: { borderRadius: "10px" },
  notificacoesBox: {
    display: "flex",
    flexDirection: "column",
    maxHeight: "287.6px",
    overflow: "auto",
    padding: 1,
    gap: 1,
  },
  notificacoesMobileBadge: { zIndex: "0" },
});

export default styles;
