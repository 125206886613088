import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  Typography,
  Stack,
  TextField,
  FormControl,
  Radio,
  FormControlLabel,
  RadioGroup,
  IconButton,
  Button,
  Snackbar,
  Alert,
} from "@mui/material";
import { Add, Delete, Edit } from "@mui/icons-material";
import { z } from "zod";
import useStyles from "./styles";
import validarIp from "../../utils/validadorIp";

// Regex para validação de prefixos de rede em formato CIDR para IPv4 e IPv6

// Esquemas de validação usando Zod
const businessDataSchema = z.object({
  IRR: z.string().min(1, "IRR é obrigatório"),
  prefixos: z.string().ip("Prefixos inválido"),
});

const ipSchema = z.object({
  ip: z.string().min(1, "IP é obrigatório"),
});

export default function EditAtivacaoCliente(params) {
  const { selectedRow } = params;
  const styles = useStyles();
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);

  // Estado para dados do formulário e erros de validação
  const [formData, setFormData] = useState({
    prefixos: [{ id: 1, value: "" }],
    IRR: "",
  });
  const [errors, setErrors] = useState({});
  const [alert, setAlert] = useState(false);

  // Função para lidar com mudanças nos campos de texto
  function handleInputChange(e) {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  }

  // Uso de prefixos
  function changeEditMode() {
    setEditMode(!editMode);
  }

  const handleAddPrefixo = () => {
    let newForm = { ...formData };
    const newArray = formData.prefixos;
    newArray.push({ id: newArray.length + 1, value: "" });
    newForm.prefixos = newArray;
    setFormData(newForm);
  };

  const handlePrefixoChange = (index, value) => {
    setFormData((formData) => {
      const newPrefixo = [...formData.prefixos];
      newPrefixo[index].value = value;
      return {
        ...formData,
        prefixos: newPrefixo,
      };
    });
  };

  const handleDelete = (index) => {
    let newForm = { ...formData };
    const newArray = formData.prefixos;
    newArray.splice(index, 1);
    newForm.prefixos = newArray;
    setFormData(newForm);
  };

  function handleBlur(e) {
    const { name, value } = e.target;
    try {
      if (name === "prefixos") {
        if (value?.trim() === "") {
          setErrors((prev) => ({ ...prev, [name]: "IP obrigatório" }));
        } else {
          const isValid = validarIp(value, "justMask");
          if (isValid === 1) {
            setErrors((prev) => ({ ...prev, [name]: "" }));
          } else {
            setErrors((prev) => ({ ...prev, [name]: "IP inválido" }));
          }
        }
      }
      setErrors((prev) => ({ ...prev, [name]: "" }));
    } catch (err) {
      setErrors((prev) => ({ ...prev, [name]: err.errors[0].message }));
    }
  }

  // Função para submeter o formulário
  function handleSubmitAtivacao() {
    setLoading(true);
    try {
      // Validação de dados empresariais
      businessDataSchema.parse({
        prefixos: formData.prefixos,
        IRR: formData.IRR,
      });
      // Validação de prefixos

      // Validação de IRR

      console.log("Todos os dados são válidos!");
      setErrors({}); // Limpar erros anteriores
      // Aqui você pode proceder com a lógica de submissão do formulário
    } catch (error) {
      console.log(error);
      // Atualizar o estado de erros com todos os erros de validação encontrados
      const newErrors = {};
      error?.errors?.forEach((err) => {
        newErrors[err.path[0]] = err.message;
      });
      setErrors(newErrors);
      setAlert(true);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Box>
      <Box sx={styles.container}>
        {editMode ? (
          <>
            <Box>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="mySubtitle">
                  Formulário de Ativação
                </Typography>
                <Box
                  sx={{ display: "flex", flexDirection: "row", gap: "64px" }}
                >
                  <Box sx={styles.boxForm}>
                    <Typography variant="mySubtitle2">
                      Dados empresariais
                    </Typography>
                    <Typography variant="mySubtitle2">Prefixos</Typography>
                    {Array.isArray(formData?.prefixos) &&
                      formData.prefixos.map((prefixo, index) => (
                        <Stack
                          direction={"row"}
                          gap={1}
                          key={`${prefixo.id}-${index}`}
                        >
                          <TextField
                            id="prefixos"
                            name="prefixos"
                            label="Prefixos"
                            variant="filled"
                            autoComplete="off"
                            size="small"
                            fullWidth
                            onKeyDown={(event) => {
                              if (event.key === "Enter") {
                                event.preventDefault();
                                document.getElementById("irr").focus();
                              }
                            }}
                            value={prefixo.value}
                            onChange={(e) =>
                              handlePrefixoChange(index, e.target.value)
                            }
                            onBlur={handleBlur}
                            error={Boolean(errors?.prefixo)}
                            helperText={errors?.prefixo}
                          />
                          {index === formData.prefixos.length - 1 ? (
                            <IconButton
                              onClick={(e) => handleAddPrefixo(e.target.value)}
                              sx={{ height: "40px", width: "40px", mt: "8px" }}
                            >
                              <Add color="primary" />
                            </IconButton>
                          ) : (
                            <IconButton
                              onClick={() => handleDelete(index)}
                              sx={{ height: "40px", width: "40px", mt: "8px" }}
                            >
                              <Delete color="primary" />
                            </IconButton>
                          )}
                        </Stack>
                      ))}

                    <Typography variant="mySubtitle2">
                      Internet Routing Registry(IRR)
                    </Typography>

                    <TextField
                      id="irr"
                      name="IRR"
                      label="IRR"
                      variant="filled"
                      autoComplete="off"
                      fullWidth
                      size="small"
                      value={formData.IRR}
                      onChange={handleInputChange}
                      error={!!errors.IRR}
                      helperText={errors.IRR}
                      sx={{ width: "calc(100% - 48px)" }}
                    />
                    <Typography variant="mySubtitle2">
                      Possui RPKI válido?
                    </Typography>
                    <FormControl>
                      <RadioGroup defaultValue="sim" name="radio-buttons-group">
                        <FormControlLabel
                          value="sim"
                          control={<Radio />}
                          label="Sim"
                        />
                        <FormControlLabel
                          value="nao"
                          control={<Radio />}
                          label="Não"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                </Box>
              </Box>
            </Box>
          </>
        ) : (
          <>
            {" "}
            <Box>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Typography variant="mySubtitle">
                  Formulário de Ativação
                </Typography>
                <Box
                  sx={{ display: "flex", flexDirection: "row", gap: "64px" }}
                >
                  <Box sx={styles.boxForm}>
                    <Typography variant="mySubtitle2">
                      Dados empresariais
                    </Typography>
                    <Typography variant="mySubtitle2">Prefixos</Typography>
                    {Array.isArray(formData?.prefixos) &&
                      formData.prefixos.map((prefixo, index) => (
                        <Stack
                          direction={"row"}
                          gap={1}
                          key={`${prefixo.id}-${index}`}
                        >
                          <TextField
                            name="prefixos"
                            label="Prefixos"
                            variant="filled"
                            fullWidth
                            size="small"
                            value={prefixo.value}
                            disabled={true}
                            sx={{ width: "calc(100% - 48px)" }}
                          />
                        </Stack>
                      ))}
                    <Typography variant="mySubtitle2">
                      Internet Routing Registry(IRR)
                    </Typography>

                    <TextField
                      name="IRR"
                      label="IRR"
                      variant="filled"
                      autoComplete="off"
                      fullWidth
                      size="small"
                      value={formData.IRR}
                      disabled={true}
                      sx={{ width: "calc(100% - 48px)" }}
                    />
                    <Typography variant="mySubtitle2">
                      Possui RPKI válido?
                    </Typography>
                    <FormControl>
                      <RadioGroup defaultValue="sim" name="radio-buttons-group">
                        <FormControlLabel
                          value="sim"
                          control={<Radio />}
                          label="Sim"
                          disabled={true}
                        />
                        <FormControlLabel
                          value="nao"
                          control={<Radio />}
                          label="Não"
                          disabled={true}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                </Box>
              </Box>
            </Box>
          </>
        )}
        <Box>
          <Stack
            direction={"row"}
            gap={1}
            justifyContent={"flex-end"}
            width={"100%"}
            flexWrap={"wrap"}
          >
            {editMode ? (
              <>
                <Button
                  sx={styles.button}
                  onClick={changeEditMode}
                  disabled={loading}
                >
                  Cancelar
                </Button>
                <Button
                  variant="contained"
                  sx={styles.button}
                  onClick={handleSubmitAtivacao}
                  disabled={loading}
                >
                  Salvar
                </Button>
              </>
            ) : (
              <Button onClick={changeEditMode} color="primary">
                Editar
              </Button>
            )}
          </Stack>
        </Box>
      </Box>
      <Snackbar
        autoHideDuration={6000}
        open={alert}
        onClose={() => {
          setAlert(false);
        }}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert severity="error">Erro ao editar</Alert>
      </Snackbar>
    </Box>
  );
}
