const styles = (theme) => ({
  container: {
    zIndex: -1,
    height: "100vh",
    width: "98vw",
    position: "absolute",
    top: 0,
  },
  paper: {
    minWidth: "350px",
    padding: "20px 50px",
    width: { xs: "90vw", sm: "500px" },
    margin: "auto",
    marginTop: "15vh",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    display: "flex",
  },
  content: { padding: "0px", width: "100%", gap: "12px" },
  textField: { width: "90%", marginTop: "10px" },
  logo: { height: "auto", width: "280px" },
  button: {
    width: "90%",
    borderRadius: "5px",
    marginTop: "10px",
    height: "40px",
    marginBottom: "20px",
  },
  title: { fontSize: "1em", textAlign: "center", fontWeight: 500 },
  feedBackText: {
    fontSize: "1.2em",
    textAlign: "center",
    marginBottom: "20px",
    padding: "10px",
    backgroundColor: "rgba(0, 0, 0,0.1)",
  },
  feedBackButton: {
    width: "90%",
    borderRadius: "5px",
    marginTop: "10px",
    height: "40px",
    marginBottom: "20px",
    textTransform: "none",
  },
  divider: { borderWidth: "1px" },
  helperText: {
    textAlign: "center",
    fontSize: 16,
  },
  contato: {
    fontSize: 14,
  },
  email: {
    fontSize: 14,
    fontWeight: 500,
  },
});

export default styles;
