import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";

import { Collapse, Paper, Tab, Tabs, createSvgIcon } from "@mui/material";

import {
  AttachMoney,
  BarChart,
  HomeOutlined,
  ManageAccounts,
  MapOutlined,
  MemoryOutlined,
  PictureAsPdfOutlined,
  PlaylistAdd,
  SupportAgentOutlined,
  PermIdentityOutlined,
  ManageAccountsOutlined,
  SupervisorAccountOutlined,
  Settings,
} from "@mui/icons-material";
import { useTheme } from "@mui/material";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useEffect } from "react";

import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";

import { useState } from "react";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));
///teste
const styles = {
  tab: {
    minWidth: "60px",
    minHeight: "48px",
    maxWidth: "200px",
    justifyContent: "start",
    color: "drawer.text",
    //textTransform: "none",
    fontSize: "0.875 rem",
    "&:hover": {
      backgroundColor: "#00000021",
    },
  },
  icons: { fontSize: "22px" },
  smallIcons: { fontSize: "20px", width: "22px" },
};

export default function SliderBar() {
  const [open, setOpen] = useState(true);
  const [value, setValue] = useState(0);
  const [administrador, setAdministrador] = useState(false);
  useEffect(() => {
    let result = localStorage.getItem("user");
    result = JSON.parse(result);

    const pageName = window.location.pathname;
    const pages = [
      "/interno/home",
      "/interno/dash",
      "/interno/configuracoes",
      "/interno/administracao",
    ];
    pages.map((page, index) => {
      if (pageName === page) {
        setValue(index);
      }
    });
    const handleUsuarioId = () => {
      // Verifica se a propriedade rlPermissoesUsuario existe e é um array
      if (Array.isArray(result?.usuario?.rlPermissoesUsuario)) {
        // Itera sobre o array para verificar se algum objeto contém a propriedade codigoPermissao com valor "ADM"
        for (let permissao of result?.usuario?.rlPermissoesUsuario) {
          if (permissao?.permissao?.codigoPermissao.toUpperCase() === "ADM") {
            setAdministrador(true);
          }
        }
        return false;
      }
    };

    handleUsuarioId();
  }, []);

  const theme = useTheme();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const navigate = useNavigate();

  return (
    <Box display={"flex"}>
      <Paper
        elevation={0}
        sx={{
          backgroundColor: "drawer.main",
          height: "calc(100vh - 80px)",
          position: "sticky",
          top: "80px",
        }}
      >
        <Collapse in={open} orientation="horizontal" collapsedSize={60}>
          <Box width={!open ? "60px" : "180px"} margin={0} padding={0}>
            <DrawerHeader>
              {!open ? (
                <IconButton onClick={handleDrawerOpen}>
                  <ChevronRightIcon sx={{ color: "drawer.text" }} />
                </IconButton>
              ) : (
                <IconButton onClick={handleDrawerClose}>
                  <ChevronLeftIcon sx={{ color: "drawer.text" }} />
                </IconButton>
              )}
            </DrawerHeader>
            <Divider />
            <Tabs
              orientation="vertical"
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
              textColor={"secondary"}
              TabIndicatorProps={{
                style: {
                  background: theme.palette.secondary.main,
                },
              }}
            >
              <Tab
                icon={<PermIdentityOutlined />}
                label={open ? "CLIENTES" : ""}
                iconPosition="start"
                value={0}
                sx={styles.tab}
                onClick={(event) => {
                  navigate("/interno/home");
                }}
              />

              <Tab
                icon={<BarChart />}
                label={open ? "DASHBOARD" : ""}
                iconPosition="start"
                value={1}
                sx={styles.tab}
                onClick={() => {
                  navigate("/interno/dash");
                }}
              />

              <Tab
                icon={<Settings />}
                label={open ? "Configurações" : ""}
                iconPosition="start"
                value={2}
                sx={styles.tab}
                onClick={() => {
                  navigate("/interno/configuracoes");
                }}
              />

              {administrador && (
                <Tab
                  icon={<SupervisorAccountOutlined />}
                  label={open ? "ADMINISTRAÇÃO" : ""}
                  iconPosition="start"
                  value={3}
                  sx={styles.tab}
                  onClick={() => {
                    navigate("/interno/administracao");
                  }}
                />
              )}
            </Tabs>
          </Box>
        </Collapse>
      </Paper>
    </Box>
  );
}
