import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Stack,
  TextField,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
  CircularProgress,
  InputAdornment,
  Button,
  Autocomplete,
} from "@mui/material";
import { NumericFormat } from "react-number-format";
import { useOutletContext } from "react-router-dom";
import PropTypes from "prop-types";
import { z } from "zod";
import axios from "axios";
import InputMask from "react-input-mask";
import useStyles from "./styles";
import api from "../../../../services/api";

function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator={"."}
      decimalSeparator={","}
    />
  );
}

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

const businessDataSchema = z.object({
  cnpj: z.string().min(1, "CNPJ é obrigatório"),
  razaoSocial: z.string().min(1, "Razão Social é obrigatório"),
  nomeFantasia: z.string().min(1, "Nome Fantasia é obrigatório"),
  as: z.string().min(1, "ASN é obrigatório"),
});

const cnpjSchema = z.string().regex(/^\d{14}$/, "CNPJ inválido");
const cepSchema = z.string().regex(/^\d{5}-\d{3}$/, "CEP inválido");
const telefoneSchema = z
  .string()
  .regex(/^\(\d{2}\) \d{4}-\d{4}$/, "Telefone inválido");
const celularSchema = z
  .string()
  .regex(/^\(\d{2}\) \d{5}-\d{4}$/, "Telefone inválido");
const emailSchema = z.string().email("Email inválido");

export default function InternoPerfilCliente() {
  const [setSnackbar, clienteInfo, setClienteInfo] = useOutletContext();
  const styles = useStyles();
  const [errors, setErrors] = useState({});
  const [loadingGetCEP, setLoadingGetCEP] = useState();
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingASN, setLoadingASN] = useState(false);
  const [checouAs, setChecouAs] = useState(true);

  const [formData, setFormData] = useState({
    cnpj: clienteInfo?.cnpj || "",
    razaoSocial: clienteInfo?.razaoSocial || "",
    nomeFantasia: clienteInfo?.nomeFantasia || "",
    as: clienteInfo?.as || "",
    cep: clienteInfo?.ClientesCadastro?.cep || "",
    estado: clienteInfo?.ClientesCadastro?.estado || "",
    cidade: clienteInfo?.ClientesCadastro?.cidade || "",
    bairro: clienteInfo?.ClientesCadastro?.bairro || "",
    logradouro: clienteInfo?.ClientesCadastro?.logradouro || "",
    numero: clienteInfo?.ClientesCadastro?.numero || "",
    complemento: clienteInfo?.ClientesCadastro?.complemento || "",
    telefone: clienteInfo?.ClientesCadastro?.telefone || "",
    email: clienteInfo?.ClientesCadastro?.email || "",
    plano: clienteInfo?.ClientesCadastro?.plano || "",
    diaDePagamento: clienteInfo?.ClientesCadastro?.diaDePagamento || "",
    valor: clienteInfo?.ClientesCadastro?.valor || "",
    reajuste: clienteInfo?.ClientesCadastro?.reajuste || "",
  });

  useEffect(() => {
    setFormData({
      cnpj: clienteInfo?.cnpj || "",
      razaoSocial: clienteInfo?.razaoSocial || "",
      nomeFantasia: clienteInfo?.nomeFantasia || "",
      as: clienteInfo?.as || "",
      cep: clienteInfo?.ClientesCadastro?.cep || "",
      estado: clienteInfo?.ClientesCadastro?.estado || "",
      cidade: clienteInfo?.ClientesCadastro?.cidade || "",
      bairro: clienteInfo?.ClientesCadastro?.bairro || "",
      logradouro: clienteInfo?.ClientesCadastro?.logradouro || "",
      numero: clienteInfo?.ClientesCadastro?.numero || "",
      complemento: clienteInfo?.ClientesCadastro?.complemento || "",
      telefone: clienteInfo?.ClientesCadastro?.telefone || "",
      email: clienteInfo?.ClientesCadastro?.email || "",
      plano: clienteInfo?.ClientesCadastro?.plano || "",
      diaDePagamento: clienteInfo?.ClientesCadastro?.diaDePagamento || "",
    });
    setValues({
      valor: clienteInfo?.ClientesCadastro?.valor || "",
      reajuste: clienteInfo?.ClientesCadastro?.reajuste || "",
    });
  }, [clienteInfo]);

  const [values, setValues] = useState({
    valor: clienteInfo?.ClientesCadastro?.valor || "",
    reajuste: clienteInfo?.ClientesCadastro?.reajuste || "",
  });

  async function getCEP(cep) {
    try {
      setLoadingGetCEP(!editMode);
      const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
      const { uf, localidade, bairro, logradouro } = response.data;
      setFormData((prev) => ({
        ...prev,
        estado: uf,
        cidade: localidade,
        bairro,
        logradouro,
      }));
      handleBlur({ target: { name: "estado", value: uf } });
      handleBlur({ target: { name: "cidade", value: localidade } });
      handleBlur({ target: { name: "bairro", value: bairro } });
      handleBlur({ target: { name: "logradouro", value: logradouro } });
    } catch (error) {
      console.error(error);
    } finally {
      setLoadingGetCEP(false);
    }
  }

  async function validarAs(as) {
    try {
      setLoadingASN(!editMode);
      //Checa se é vazio
      if (as?.trim() === "") {
        throw new Error("ASN não encontrado");
      }
      const response = await axios.get(
        "https://stat.ripe.net/data/as-overview/data.json",
        {
          params: {
            resource: as,
          },
        }
      ); //268659

      if (!response?.data?.data?.announced) {
        throw new Error("ASN não encontrado");
      }
      //sucesso
    } catch (error) {
      console.error(error);
      setErrors((prev) => ({
        ...prev,
        as:
          error?.message === "ASN não encontrado"
            ? error?.message
            : error?.message === "ASN é obrigatório"
            ? error?.message
            : "ASN inválido",
      }));
    } finally {
      setLoadingASN(false);
      setChecouAs(true);
    }
  }

  function handleInputChange(e) {
    const { name, value } = e.target;
    if (name === "as") {
      // impede submit sem checar validade do as
      setChecouAs(false);
      // permite apenas numeros
      const regexNums = /\d+/g;
      const nums = value?.match(regexNums)?.join("") || "";
      setFormData((prev) => ({ ...prev, [name]: nums }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
    if (errors[name]) setErrors((prev) => ({ ...prev, [name]: "" }));
  }

  function handleBlur(e) {
    const { name, value } = e.target;
    try {
      if (name === "cnpj") {
        cnpjSchema.parse(value.replace(/[^\d]/g, ""));
      } else if (name === "cep") {
        cepSchema.parse(value);
        getCEP(value);
      } else if (name === "telefone") {
        value?.replace(/\s+/g, "")?.length === 13
          ? telefoneSchema.parse(value.trim())
          : celularSchema.parse(value);
      } else if (name === "email") {
        emailSchema.parse(value);
      } else if (name === "as") {
        validarAs(value);
      }
      setErrors((prev) => ({ ...prev, [name]: "" }));
    } catch (err) {
      setErrors((prev) => ({ ...prev, [name]: err.errors[0].message }));
    }
  }

  function changeEditMode() {
    setEditMode(!editMode);
  }

  async function handleUpdateCadastro() {
    setLoading(true);

    try {
      //Validação zod
      businessDataSchema.parse({
        cnpj: formData?.cnpj,
        razaoSocial: formData?.razaoSocial,
        nomeFantasia: formData?.nomeFantasia,
        as: formData?.as,
      });
      //checa validade do as
      handleBlur({ target: { name: "as", value: formData?.as } });

      if (checouAs) {
        const response = await api.put("/interno/updateCliente", {
          id: clienteInfo?.id,
          cnpj: formData?.cnpj,
          razaoSocial: formData?.razaoSocial,
          nomeFantasia: formData?.nomeFantasia,
          as: formData?.as,
          cep: formData?.cep,
          estado: formData?.estado,
          cidade: formData?.cidade,
          bairro: formData?.bairro,
          logradouro: formData?.logradouro,
          numero: formData?.numero,
          complemento: formData?.complemento,
          telefone: formData?.telefone,
          email: formData?.email,
          plano: formData?.plano,
          diaDePagamento: formData?.diaDePagamento?.toString() || "",
          valor: parseFloat(values?.valor),
          reajuste: parseFloat(values?.reajuste),
        });

        //on success
        setClienteInfo(response?.data);

        setEditMode(false);

        setErrors({});
        setSnackbar({
          children: "Cadastro editado com sucesso!",
          severity: "success",
        });
      }
    } catch (error) {
      console.log(error);
      const newErrors = {};
      error?.errors?.forEach((err) => {
        newErrors[err.path[0]] = err.message;
      });
      setErrors(newErrors);
      setSnackbar({
        children: "Error: Não foi possivel se conectar ao servidor",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  }

  const handleChange = (event) => {
    setValues({
      ...values,
      valor: event.target.value,
    });
  };

  return (
    <Box sx={styles.container}>
      <Box>
        <Box sx={styles.content}>
          <Box>
            <Box sx={styles.boxForm}>
              <Typography variant="mySubtitle2">Dados empresariais</Typography>

              <Stack direction={"row"} gap={1} flexWrap={"wrap"}>
                {editMode ? (
                  <InputMask
                    name="cnpj"
                    mask="99.999.999/9999-99"
                    value={formData.cnpj}
                    onChange={handleInputChange}
                    size={"small"}
                    onBlur={handleBlur}
                    disabled={false}
                    maskChar=" "
                  >
                    {(props) => (
                      <TextField
                        label="CNPJ"
                        variant="filled"
                        autoComplete="off"
                        sx={styles.textfield}
                        {...props}
                        error={Boolean(errors?.cnpj)}
                        helperText={errors?.cnpj}
                        onKeyDown={(event) => {
                          if (event.key === "Enter") {
                            event.preventDefault();
                            document.getElementById("razaoSocial").focus();
                          }
                        }}
                      />
                    )}
                  </InputMask>
                ) : (
                  <TextField
                    label="CNPJ"
                    variant="filled"
                    autoComplete="off"
                    value={formData.cnpj}
                    size="small"
                    sx={styles.textfield}
                    error={Boolean(errors?.cnpj)}
                    disabled={true}
                    helperText={errors?.cnpj}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        event.preventDefault();
                        document.getElementById("razaoSocial").focus();
                      }
                    }}
                  />
                )}

                <TextField
                  id="razaoSocial"
                  name="razaoSocial"
                  label="Razão Social"
                  variant="filled"
                  autoComplete="off"
                  sx={styles.textfield}
                  value={formData.razaoSocial}
                  onChange={handleInputChange}
                  size={"small"}
                  error={Boolean(errors?.razaoSocial)}
                  helperText={errors?.razaoSocial}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      event.preventDefault();
                      document.getElementById("nomeFantasia").focus();
                    }
                  }}
                  disabled={!editMode}
                />
                <TextField
                  id="nomeFantasia"
                  name="nomeFantasia"
                  label="Nome Fantasia"
                  variant="filled"
                  autoComplete="off"
                  sx={styles.textfield}
                  value={formData.nomeFantasia}
                  onChange={handleInputChange}
                  size={"small"}
                  error={Boolean(errors?.nomeFantasia)}
                  helperText={errors?.nomeFantasia}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      event.preventDefault();
                      document.getElementById("as").focus();
                    }
                  }}
                  disabled={!editMode}
                />
                <TextField
                  id="as"
                  name="as"
                  label="ASN"
                  variant="filled"
                  autoComplete="off"
                  sx={styles.asTextField}
                  value={formData.as}
                  onChange={handleInputChange}
                  size={"small"}
                  error={Boolean(errors?.as)}
                  helperText={errors?.as}
                  onBlur={handleBlur}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      event.preventDefault();
                      document.getElementById("cep").focus();
                    }
                  }}
                  disabled={loadingASN || !editMode}
                />
              </Stack>
              <Typography variant="mySubtitle2">Endereço</Typography>
              <Stack direction={"row"} gap={1} flexWrap={"wrap"}>
                {editMode ? (
                  <InputMask
                    name="cep"
                    mask="99999-999"
                    value={formData.cep}
                    onChange={handleInputChange}
                    size={"small"}
                    onBlur={handleBlur}
                    disabled={false}
                    maskChar=" "
                  >
                    {(props) => (
                      <TextField
                        id="cep"
                        label="CEP"
                        variant="filled"
                        autoComplete="off"
                        sx={styles.textfield}
                        {...props}
                        error={Boolean(errors?.cep)}
                        helperText={errors?.cep}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              {loadingGetCEP ? (
                                <CircularProgress size={20} />
                              ) : (
                                <></>
                              )}
                            </InputAdornment>
                          ),
                        }}
                      />
                    )}
                  </InputMask>
                ) : (
                  <TextField
                    id="cep"
                    label="CEP"
                    variant="filled"
                    autoComplete="off"
                    sx={styles.textfield}
                    value={formData.cep}
                    error={Boolean(errors?.cep)}
                    helperText={errors?.cep}
                    disabled={!editMode}
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {loadingGetCEP ? (
                            <CircularProgress size={20} />
                          ) : (
                            <></>
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                )}

                <FormControl variant="filled" sx={styles.textfield}>
                  <InputLabel id="estado-label">Estado</InputLabel>
                  <Select
                    labelId="estado-label"
                    name="estado"
                    label="Estado"
                    value={formData.estado}
                    defaultValue={formData.estado}
                    onChange={handleInputChange}
                    size={"small"}
                    error={Boolean(errors?.estado)}
                    disabled={loadingGetCEP || !editMode}
                  >
                    {brazilianStates.map((state) => (
                      <MenuItem key={state.sigla} value={state.sigla}>
                        {state.nome}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <TextField
                  name="cidade"
                  label="Cidade"
                  variant="filled"
                  autoComplete="off"
                  sx={styles.textfield}
                  value={formData.cidade}
                  onChange={handleInputChange}
                  size={"small"}
                  error={Boolean(errors?.cidade)}
                  helperText={errors?.cidade}
                  disabled={loadingGetCEP || !editMode}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      event.preventDefault();
                      document.getElementById("bairro").focus();
                    }
                  }}
                />
                <TextField
                  id="bairro"
                  name="bairro"
                  label="Bairro"
                  variant="filled"
                  autoComplete="off"
                  sx={styles.textfield}
                  value={formData.bairro}
                  onChange={handleInputChange}
                  size={"small"}
                  error={Boolean(errors?.bairro)}
                  helperText={errors?.bairro}
                  disabled={loadingGetCEP || !editMode}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      event.preventDefault();
                      document.getElementById("logradouro").focus();
                    }
                  }}
                />
                <TextField
                  id="logradouro"
                  name="logradouro"
                  label="Logradouro"
                  variant="filled"
                  autoComplete="off"
                  sx={styles.textfield}
                  value={formData.logradouro}
                  onChange={handleInputChange}
                  size={"small"}
                  error={Boolean(errors?.logradouro)}
                  helperText={errors?.logradouro}
                  disabled={loadingGetCEP || !editMode}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      event.preventDefault();
                      document.getElementById("numero").focus();
                    }
                  }}
                />
                <TextField
                  id="numero"
                  name="numero"
                  label="Número"
                  variant="filled"
                  autoComplete="off"
                  sx={styles.textfield}
                  value={formData.numero}
                  onChange={handleInputChange}
                  disabled={!editMode}
                  size={"small"}
                  error={Boolean(errors?.numero)}
                  helperText={errors?.numero}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      event.preventDefault();
                      document.getElementById("complemento").focus();
                    }
                  }}
                />
                <TextField
                  disabled={!editMode}
                  id="complemento"
                  name="complemento"
                  label="Complemento"
                  variant="filled"
                  autoComplete="off"
                  sx={styles.textfield}
                  value={formData.complemento}
                  onChange={handleInputChange}
                  size={"small"}
                  error={Boolean(errors?.complemento)}
                  helperText={errors?.complemento}
                  onKeyDown={(event) => {
                    if (event.key === "Enter") {
                      event.preventDefault();
                      document.getElementById("telefone").focus();
                    }
                  }}
                />
              </Stack>
              <Typography variant="mySubtitle2">Contato</Typography>
              <Stack direction={"row"} gap={1} flexWrap={"wrap"}>
                {editMode ? (
                  <InputMask
                    name="telefone"
                    mask={
                      formData.telefone?.replace(/\s+/g, "")?.length <= 13
                        ? "(99) 9999-99999"
                        : "(99) 99999-9999"
                    }
                    value={formData.telefone}
                    onChange={handleInputChange}
                    size={"small"}
                    onBlur={handleBlur}
                    disabled={false}
                    maskChar=" "
                  >
                    {(props) => (
                      <TextField
                        id="telefone"
                        label="Telefone"
                        variant="filled"
                        autoComplete="off"
                        sx={styles.textfield}
                        {...props}
                        error={Boolean(errors?.telefone)}
                        helperText={errors?.telefone}
                        onKeyDown={(event) => {
                          if (event.key === "Enter") {
                            event.preventDefault();
                            document.getElementById("email").focus();
                          }
                        }}
                      />
                    )}
                  </InputMask>
                ) : (
                  <TextField
                    disabled={!editMode}
                    size="small"
                    id="telefone"
                    label="Telefone"
                    variant="filled"
                    autoComplete="off"
                    sx={styles.textfield}
                    value={formData.telefone}
                    error={Boolean(errors?.telefone)}
                    helperText={errors?.telefone}
                    onKeyDown={(event) => {
                      if (event.key === "Enter") {
                        event.preventDefault();
                        document.getElementById("email").focus();
                      }
                    }}
                  />
                )}
                <TextField
                  id="email"
                  name="email"
                  label="Email"
                  disabled={!editMode}
                  variant="filled"
                  autoComplete="off"
                  type="email"
                  sx={styles.textfield}
                  value={formData.email}
                  onChange={handleInputChange}
                  size={"small"}
                  onBlur={handleBlur}
                  error={Boolean(errors?.email)}
                  helperText={errors?.email}
                />
              </Stack>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box>
        <Stack
          direction={"row"}
          gap={1}
          justifyContent={"flex-end"}
          width={"100%"}
          flexWrap={"wrap"}
        >
          {editMode ? (
            <>
              <Button
                // Desfaz alterações
                onClick={() => {
                  changeEditMode();
                  setFormData({
                    cnpj: clienteInfo?.cnpj || "",
                    razaoSocial: clienteInfo?.razaoSocial || "",
                    nomeFantasia: clienteInfo?.nomeFantasia || "",
                    as: clienteInfo?.as || "",
                    cep: clienteInfo.ClientesCadastro?.cep || "",
                    estado: clienteInfo.ClientesCadastro?.estado || "",
                    cidade: clienteInfo.ClientesCadastro?.cidade || "",
                    bairro: clienteInfo.ClientesCadastro?.bairro || "",
                    logradouro: clienteInfo.ClientesCadastro?.logradouro || "",
                    numero: clienteInfo.ClientesCadastro?.numero || "",
                    complemento:
                      clienteInfo.ClientesCadastro?.complemento || "",
                    telefone: clienteInfo.ClientesCadastro?.telefone || "",
                    email: clienteInfo.ClientesCadastro?.email || "",
                    plano: clienteInfo.ClientesCadastro?.plano || "",
                    diaDePagamento:
                      clienteInfo.ClientesCadastro?.diaDePagamento || "",
                  });
                  setValues({
                    valor: clienteInfo.ClientesCadastro?.valor || "",
                    reajuste: clienteInfo.ClientesCadastro?.reajuste || "",
                  });
                  setErrors({});
                }}
                disabled={loading}
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                onClick={handleUpdateCadastro}
                disabled={loading}
              >
                Salvar
              </Button>
            </>
          ) : (
            <Button onClick={changeEditMode} color="primary">
              Editar
            </Button>
          )}
        </Stack>
      </Box>
    </Box>
  );
}

const paydays = [1, 10, 20];

const planos = [
  "500 MB - PROTECT/22",
  "1 GB - PROTECT/22",
  "2 GB - PROTECT/21",
  "4GB - PROTECT/21",
  "6GB - PROTECT/20",
  "8GB - PROTECT/20",
  "10GB - PROTECT/19",
];

const brazilianStates = [
  { nome: "Acre", sigla: "AC" },
  { nome: "Alagoas", sigla: "AL" },
  { nome: "Amapá", sigla: "AP" },
  { nome: "Amazonas", sigla: "AM" },
  { nome: "Bahia", sigla: "BA" },
  { nome: "Ceará", sigla: "CE" },
  { nome: "Distrito Federal", sigla: "DF" },
  { nome: "Espírito Santo", sigla: "ES" },
  { nome: "Goiás", sigla: "GO" },
  { nome: "Maranhão", sigla: "MA" },
  { nome: "Mato Grosso", sigla: "MT" },
  { nome: "Mato Grosso do Sul", sigla: "MS" },
  { nome: "Minas Gerais", sigla: "MG" },
  { nome: "Pará", sigla: "PA" },
  { nome: "Paraíba", sigla: "PB" },
  { nome: "Paraná", sigla: "PR" },
  { nome: "Pernambuco", sigla: "PE" },
  { nome: "Piauí", sigla: "PI" },
  { nome: "Rio de Janeiro", sigla: "RJ" },
  { nome: "Rio Grande do Norte", sigla: "RN" },
  { nome: "Rio Grande do Sul", sigla: "RS" },
  { nome: "Rondônia", sigla: "RO" },
  { nome: "Roraima", sigla: "RR" },
  { nome: "Santa Catarina", sigla: "SC" },
  { nome: "São Paulo", sigla: "SP" },
  { nome: "Sergipe", sigla: "SE" },
  { nome: "Tocantins", sigla: "TO" },
];
