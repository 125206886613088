import React, { createContext, useState } from "react";

export const RangeContext = createContext();

export const RangeProvider = ({ children }) => {
  const [Range, setRange] = useState({
    range: "hour",
    unidade: "bps",
    cliente: null,
    timer: 0,
    timerStatus: false,
  });

  return (
    <RangeContext.Provider value={{ Range, setRange }}>
      {children}
    </RangeContext.Provider>
  );
};
