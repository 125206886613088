import { DataGrid } from "@mui/x-data-grid";
import { Box, LinearProgress, Tooltip, Typography } from "@mui/material";

export default function CountriesTable({
  rows = [],
  tableSelected = "",
  load,
}) {
  const columns = [
    { field: "id", headerName: "ID", width: 100 },
    ...(tableSelected === "country"
      ? [
          {
            field: "pais",
            headerName: "País",
            flex: 1,
            renderCell: (params) => {
              return (
                <Box
                  key={params.id}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <img
                    loading="lazy"
                    width="20"
                    srcSet={`https://flagcdn.com/w40/${
                      rows[params.id]?.code?.toLowerCase() || ""
                    }.png 2x`}
                    src={`https://flagcdn.com/w20/${
                      rows[params.id]?.code?.toLowerCase() || ""
                    }.png`}
                    alt=""
                  />
                  <Typography
                    sx={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {rows[params.id]?.pais || ""}
                  </Typography>
                </Box>
              );
            },
          },
          {
            field: "porcentagem",
            headerName: "Porcentagem",
            flex: 1,
            renderCell: (params) => {
              return (
                <Box
                  key={params.id}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    minWidth: "100px",
                    height: "20px",
                  }}
                >
                  <LinearProgress
                    variant="determinate"
                    value={parseInt(rows[params.id].porcentagem)}
                    sx={{ width: "100px", height: 10, borderRadius: 5 }}
                  />

                  <Typography sx={{ fontSize: "14px" }}>
                    {rows[params.id]?.porcentagem?.toFixed(2) || "0"}%
                  </Typography>
                </Box>
              );
            },
          },
          {
            field: "quantidade",
            headerName: "Quantidade",
            flex: 1,
          },
        ]
      : tableSelected === "as_num"
      ? [
          { field: "as", headerName: "ASN", flex: 1 },
          { field: "org", headerName: "Organização", flex: 1 },
          {
            field: "porcentagem",
            headerName: "Porcentagem",
            flex: 1,
            renderCell: (params) => {
              return (
                <Box
                  key={params.id}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    minWidth: "100px",
                    height: "20px",
                  }}
                >
                  <LinearProgress
                    variant="determinate"
                    value={parseInt(rows[params.id].porcentagem)}
                    sx={{ width: "100px", height: 10, borderRadius: 5 }}
                  />

                  <Typography sx={{ fontSize: "14px" }}>
                    {rows[params.id]?.porcentagem?.toFixed(2) || "0"}%
                  </Typography>
                </Box>
              );
            },
          },
          { field: "quantidade", headerName: "Quantidade", flex: 1 },
        ]
      : tableSelected === "ip"
      ? [
          { field: "ip", headerName: "IP", flex: 1 },
          {
            field: "pais",
            headerName: "País",
            flex: 1,
            renderCell: (params) => {
              return (
                <Box
                  key={params.id}
                  sx={{ display: "flex", alignItems: "center", gap: 1 }}
                >
                  <img
                    loading="lazy"
                    width="20"
                    srcSet={`https://flagcdn.com/w40/${
                      rows[params.id]?.code?.toLowerCase() || ""
                    }.png 2x`}
                    src={`https://flagcdn.com/w20/${
                      rows[params.id]?.code?.toLowerCase() || ""
                    }.png`}
                    alt=""
                  />
                  {rows[params.id]?.pais || ""}
                </Box>
              );
            },
          },
          { field: "org", headerName: "Organização", flex: 1 },
          {
            field: "porcentagem",
            headerName: "Porcentagem",
            flex: 1,
            renderCell: (params) => {
              return (
                <Box
                  key={params.id}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    minWidth: "100px",
                    height: "20px",
                  }}
                >
                  <LinearProgress
                    variant="determinate"
                    value={parseInt(rows[params.id].porcentagem)}
                    sx={{ width: "100px", height: 10, borderRadius: 5 }}
                  />

                  <Typography sx={{ fontSize: "14px" }}>
                    {rows[params.id]?.porcentagem?.toFixed(2) || "0"}%
                  </Typography>
                </Box>
              );
            },
          },
          { field: "quantidade", headerName: "Quantidade", flex: 1 },
        ]
      : [
          {
            field: "proto",
            headerName: "Protocolo",
            flex: 1,
            renderCell: (params) => {
              return <>{rows[params.id]?.proto?.toUpperCase() || ""}</>;
            },
          },

          {
            field: "porcentagem",
            headerName: "Porcentagem",
            flex: 1,
            renderCell: (params) => {
              return (
                <Box
                  key={params.id}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                    minWidth: "100px",
                    height: "20px",
                  }}
                >
                  <LinearProgress
                    variant="determinate"
                    value={parseInt(rows[params.id].porcentagem)}
                    sx={{ width: "100px", height: 10, borderRadius: 5 }}
                  />

                  <Typography sx={{ fontSize: "14px" }}>
                    {rows[params.id]?.porcentagem?.toFixed(2) || "0"}%
                  </Typography>
                </Box>
              );
            },
          },
          { field: "quantidade", headerName: "Quantidade", flex: 1 },
        ]),
  ];

  return (
    <>
      <DataGrid
        rows={rows}
        columns={columns}
        autoHeight
        getRowHeight={() => "auto"}
        loading={load}
        autosizeOptions={{ includeOutliers: true }}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 5,
            },
          },
          columns: {
            columnVisibilityModel: {
              id: false,
            },
          },
        }}
        sx={{
          display: "grid",
          "&.MuiDataGrid-root": {
            borderStyle: "none",
          },
          "& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus": {
            outline: "none !important",
          },
          "&.MuiDataGrid-root--densityCompact .MuiDataGrid-cell": {
            py: "8px",
          },
          "&.MuiDataGrid-root--densityStandard .MuiDataGrid-cell": {
            py: "15px",
          },
          "&.MuiDataGrid-root--densityComfortable .MuiDataGrid-cell": {
            py: "22px",
          },

          '&.MuiDataGrid-root .MuiDataGrid-container--top [role="row"], & .MuiDataGrid-root .MuiDataGrid-container--bottom [role="row"]':
            {
              backgroundColor: "background.paper", // Mudando o background para vermelho
            },
        }}
        density="compact"
        disableColumnMenu
        disableRowSelectionOnClick
      />
    </>
  );
}
