import { TextField } from "@mui/material";
import { height, padding } from "@mui/system";

const styles = (props) => ({
  modal: { display: "flex", alignItems: "center", justifyContent: "center" },
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    // bgcolor: "primary.main",
    gap: "16px",
    padding: "8px",
    borderRadius: "12px",
    position: "relative",
    "@media(max-width: 600px)": { width: "300px" },
    bgcolor: "#212B36",
  },
  title: { marginTop: "8px", color: "#FFFFFF" },
  iconButton: {
    position: "absolute",
    right: 16,
    top: 16,
    color: "white",
    padding: 0,
  },
  avatar: {
    position: "absolute",
    left: 30,
    top: 15,
    height: "100px",
    width: "100px",
    border: `solid 4px`,
    borderColor: "#212B36",
    "@media(max-width: 600px)": {
      height: "70px",
      width: "70px",
      top: 35,
    },
  },
  cameraButton: {
    color: "text.textContrast",
    backgroundColor: props.palette.mode === "dark" ? "#949494" : "#979797",
    "&:hover": {
      backgroundColor: props.palette.mode === "dark" ? "#aaaaaa" : "#7a7a7a",
    },
    position: "absolute",
    zIndex: "99",
    left: 84,
    top: 84,
    "@media(max-width: 600px)": {
      height: "30px",
      width: "30px",
      left: 70,
      top: 80,
    },
  },
  cameraIcon: {
    height: "22px",
    "@media(max-width: 600px)": {
      height: "19px",
    },
  },

  content: {
    bgcolor: "background.default",
    display: "flex",
    flexDirection: "column",
    padding: "8px 10px 20px 10px",
    gap: "16px",
    borderRadius: "10px",
    "@media(max-width: 600px)": {
      width: 1,
      alignItems: "center",
    },
  },
  row: {
    display: "flex",
    flexDirection: "row",
    gap: "40px",
    "@media(max-width: 600px)": { flexDirection: "column" },
  },
  textField: {
    width: "240px",
  },
});

export default styles;
