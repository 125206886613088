import React, { useEffect, useState } from "react";

import {
  AppBar,
  Avatar,
  Box,
  Container,
  CssBaseline,
  Icon,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Tooltip,
} from "@mui/material";

import { Outlet, useNavigate } from "react-router-dom";
import useStyles from "./styles";

import logoComputizeDark from "../../../assets/imgs/logos/logocomputizeDark.webp";
import { useTheme } from "@emotion/react";

import {
  DarkModeOutlined,
  LightModeOutlined,
  LogoutOutlined,
  PersonOutline,
  SupervisorAccountOutlined,
} from "@mui/icons-material";

export default function LayoutCadastroCliente(params) {
  const { colorMode, setColorMode } = params;
  const [anchorEl, setAnchorEl] = useState(null);
  const [baseImage, setBaseImage] = useState("");
  const [administrador, setAdministrador] = useState(false);

  useEffect(() => {
    const handleUsuarioId = () => {
      let result = localStorage.getItem("user");
      result = JSON.parse(result);
      // Verifica se a propriedade rlPermissoesUsuario existe e é um array
      if (Array.isArray(result?.usuario?.rlPermissoesUsuario)) {
        // Itera sobre o array para verificar se algum objeto contém a propriedade codigoPermissao com valor "ADM"
        for (let permissao of result.usuario.rlPermissoesUsuario) {
          if (permissao?.permissao?.codigoPermissao === "ADM") {
            setAdministrador(true);
          }
        }
        return false;
      }

      if (result) {
        setBaseImage(result.foto);
      } else {
        return "";
      }
    };
    handleUsuarioId();
  });

  const styles = useStyles();
  const navigate = useNavigate();

  //funcoes de controle do menu
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    navigate("/login");
  };

  const handleAdm = () => {
    navigate("/administracao");
  };

  return (
    <Box display={"flex"}>
      <CssBaseline />

      <Box>
        <AppBar
          position="fixed"
          elevation={0}
          // color="primary"
        >
          <Container maxWidth="100vw">
            <Toolbar sx={styles.toolbar}>
              <IconButton href={"/interno/login"} sx={styles.IconButtonLogo}>
                <Icon style={styles.logo}>
                  <img
                    src={logoComputizeDark}
                    style={styles.logo}
                    alt="logo-computize-home"
                  />
                </Icon>
              </IconButton>

              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                disableScrollLock={true}
              >
                <MenuItem //</Menu>onClick={handlePerfil}
                >
                  <ListItemIcon>
                    <PersonOutline />
                  </ListItemIcon>
                  <ListItemText>Perfil</ListItemText>
                </MenuItem>
                <MenuItem onClick={() => setColorMode((prev) => !prev)}>
                  {colorMode ? (
                    <>
                      <ListItemIcon>
                        <DarkModeOutlined />
                      </ListItemIcon>
                      <ListItemText>Modo Noturno</ListItemText>
                    </>
                  ) : (
                    <>
                      <ListItemIcon>
                        <LightModeOutlined />
                      </ListItemIcon>
                      <ListItemText>Modo Claro</ListItemText>
                    </>
                  )}
                </MenuItem>
                {administrador && (
                  <MenuItem onClick={handleAdm}>
                    <ListItemIcon>
                      <SupervisorAccountOutlined />
                    </ListItemIcon>
                    <ListItemText>Administração</ListItemText>
                  </MenuItem>
                )}

                <MenuItem onClick={handleLogout}>
                  <ListItemIcon>
                    <LogoutOutlined />
                  </ListItemIcon>
                  <ListItemText>Sair</ListItemText>
                </MenuItem>
              </Menu>
            </Toolbar>
          </Container>
        </AppBar>
        <Box sx={{ height: "80px", width: "99vw" }} />
        <Stack direction={"row"} sx={{ backgroundColor: "drawer.main" }}>
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              p: 3,
              borderTopLeftRadius: 8,
              backgroundColor: "background.default",
            }}
          >
            <Box mt={1}>
              <Outlet />
            </Box>
          </Box>
        </Stack>
      </Box>
    </Box>
  );
}
