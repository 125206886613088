import {
  Box,
  Button,
  Modal,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import validarIp from "../../../../../../utils/validadorIp";
import useStyles from "./styles";
import api from "../../../../../../services/api";
import axios from "axios";

export default function ModalEditar(params) {
  const {
    openEditar = false,
    setOpenEditar = () => {},
    handleUpdated = () => {},
    selectedCard = {},
    setSnackbar = () => {},
    clienteId = "",
  } = params;
  const styles = useStyles();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [checouAs, setChecouAs] = useState(true);

  const [loadingASN, setLoadingASN] = useState(false);
  const [formData, setFormData] = useState({
    id: selectedCard?.id || "",
    titulo: selectedCard?.titulo || "",
    ipLocal: selectedCard?.ipLocal || "",
    ipCliente: selectedCard?.ipCliente || "",
    asnVlan: selectedCard?.asnVlan || "",
    vlanId: selectedCard?.vlanId || "",
  });
  useEffect(() => {
    if (openEditar) {
      setFormData({
        id: selectedCard?.id || "",
        titulo: selectedCard?.titulo || "",
        ipLocal: selectedCard?.ipLocal || "",
        ipCliente: selectedCard?.ipCliente || "",
        asnVlan: selectedCard?.asnVlan || "",
        vlanId: selectedCard?.vlanId || "",
      });
      setErrors({});
    }
  }, [openEditar]);

  function handleInputChange(e) {
    const { name, value } = e.target;
    if (name === "asnVlan") {
      // impede submit sem checar validade do as
      setChecouAs(false);
      // permite apenas numeros
      const regexNums = /\d+/g;
      const nums = value?.match(regexNums)?.join("") || "";
      setFormData((prev) => ({ ...prev, [name]: nums }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
    if (errors[name]) setErrors((prev) => ({ ...prev, [name]: "" }));
  }

  async function validarAs(asnVlan) {
    try {
      setLoadingASN(true);
      //Checa se é vazio
      if (asnVlan?.trim() === "") {
        throw new Error("ASN não encontrado");
      }
      const response = await axios.get(
        "https://stat.ripe.net/data/as-overview/data.json",
        {
          params: {
            resource: asnVlan,
          },
        }
      ); //268659

      if (!response?.data?.data?.announced) {
        throw new Error("ASN não encontrado");
      }
      //sucesso
    } catch (error) {
      console.error(error);
      setErrors((prev) => ({
        ...prev,
        asnVlan:
          error?.message === "ASN não encontrado"
            ? error?.message
            : error?.message === "ASN é obrigatório"
            ? error?.message
            : "ASN inválido",
      }));
    } finally {
      setLoadingASN(false);
      setChecouAs(true);
    }
  }

  function handleBlur(value, name) {
    //
    if (name === "titulo") {
      if (value?.trim() === "") {
        setErrors((prev) => ({
          ...prev,
          [name]: "Título obrigatório",
        }));
      }
    } else if (name === "ipLocal") {
      if (value?.trim() === "") {
        setErrors((prev) => ({
          ...prev,
          [name]: "IP obrigatório",
        }));
      } else {
        const isValid = validarIp(value, "justIpv6");

        if (isValid === 1) {
          setErrors((prev) => ({ ...prev, [name]: "" }));
        } else {
          setErrors((prev) => ({
            ...prev,
            [name]: "IP inválido",
          }));
        }
      }
    } else if (name === "ipCliente") {
      if (value?.trim() === "") {
        setErrors((prev) => ({
          ...prev,
          [name]: "IP obrigatório",
        }));
      } else {
        const isValid = validarIp(value, "justIpv6");

        if (isValid === 1) {
          setErrors((prev) => ({ ...prev, [name]: "" }));
        } else {
          setErrors((prev) => ({
            ...prev,
            [name]: "IP inválido",
          }));
        }
      }
    } else if (name === "vlanId") {
      if (value?.trim() === "") {
        setErrors((prev) => ({
          ...prev,
          [name]: "ID obrigatório",
        }));
      }
    } else if (name === "asnVlan") {
      if (value?.trim() === "") {
        setErrors((prev) => ({
          ...prev,
          [name]: "ASN obrigatório",
        }));
      } else {
        validarAs(value);
      }
    }
  }

  async function handleSubmit() {
    try {
      setLoading(true);
      let formError = false;
      let isValid;

      if (formData?.titulo?.trim() === "") {
        setErrors((prev) => ({
          ...prev,
          titulo: "Título obrigatório",
        }));
        formError = true;
      }

      if (formData?.vlanId?.trim() === "") {
        setErrors((prev) => ({
          ...prev,
          vlanId: "ID obrigatório",
        }));
        formError = true;
      }

      if (formData?.asnVlan?.trim() === "") {
        setErrors((prev) => ({
          ...prev,
          asnVlan: "ASN obrigatório",
        }));
        formError = true;
      } else {
        validarAs(formData?.asnVlan);
      }

      if (formData?.ipLocal?.trim() === "") {
        setErrors((prev) => ({
          ...prev,
          ipLocal: "IP obrigatório",
        }));
        formError = true;
      } else {
        isValid = validarIp(formData?.ipLocal, "justIpv6");
        if (isValid === 1) {
          setErrors((prev) => ({ ...prev, ipLocal: "" }));
        } else {
          setErrors((prev) => ({
            ...prev,
            ipLocal: "IP inválido",
          }));
          formError = true;
        }
      }

      if (formData?.ipCliente?.trim() === "") {
        setErrors((prev) => ({
          ...prev,
          ipCliente: "IP obrigatório",
        }));
        formError = true;
      } else {
        isValid = validarIp(formData?.ipCliente, "justIpv6");
        if (isValid === 1) {
          setErrors((prev) => ({ ...prev, ipCliente: "" }));
        } else {
          setErrors((prev) => ({
            ...prev,
            ipCliente: "IP inválido",
          }));
          formError = true;
        }
      }

      if (formError || !checouAs) {
        return;
      }

      const response = await api.put("/interno/update/vlan", {
        id: formData?.id,
        titulo: formData?.titulo,
        ipLocal: formData?.ipLocal,
        ipCliente: formData?.ipCliente,
        vlanId: formData?.vlanId,
        asnVlan: formData?.asnVlan,
        clienteId: clienteId,
      });

      // on success
      setErrors({});
      handleUpdated(formData);
      handleClose();
      setSnackbar({
        children: "Sessão editada com sucesso!",
        severity: "success",
      });
      handleClose();
    } catch (error) {
      console.log(error);
      const newErrors = {};
      error?.errors?.forEach((err) => {
        newErrors[err.path[0]] = err.message;
      });
      setErrors(newErrors);
      setSnackbar({
        children: "Error: Não foi possivel se conectar ao servidor",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  }

  const handleClose = () => {
    setErrors({});
    setOpenEditar(false);
    setFormData({
      id: "",
      titulo: "",
      ipLocal: "",
      ipCliente: "",
      tunnelId: "",
      vlanId: "",
    });
  };
  // nome
  // 2804:391c:13:750::18
  // 2804:404:404
  // 198.19.232.5
  // 198.19.232.6

  return (
    <Modal open={openEditar} onClose={handleClose} sx={styles.container}>
      <Paper sx={styles.paper}>
        <Box sx={styles.boxPaper}>
          <Typography variant="mySubtitle">Editar Sessão</Typography>

          <Box sx={styles.boxContent}>
            <Stack direction={"row"} gap={1} flexWrap={"nowrap"}>
              <TextField
                autoComplete="off"
                sx={styles.textField}
                variant="filled"
                id="titulo"
                name="titulo"
                label="Título da Sessão"
                value={formData?.titulo}
                onChange={handleInputChange}
                fullWidth
                onBlur={(e) => handleBlur(e.target.value, e.target.name)}
                error={Boolean(errors?.titulo)}
                helperText={errors?.titulo}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    event.preventDefault();
                    document.getElementById("VlanId").focus();
                  }
                }}
              />
              <TextField
                autoComplete="off"
                sx={styles.textField}
                variant="filled"
                id="VlanId"
                name="vlanId"
                label="Vlan ID"
                value={formData?.vlanId}
                fullWidth
                onChange={handleInputChange}
                onBlur={(e) => handleBlur(e.target.value, e.target.name)}
                error={Boolean(errors?.vlanId)}
                helperText={errors?.vlanId}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    event.preventDefault();
                    document.getElementById("ipLocal").focus();
                  }
                }}
              />
            </Stack>

            <Stack direction={"row"} gap={1} flexWrap={"nowrap"}>
              <TextField
                autoComplete="off"
                sx={styles.textField}
                variant="filled"
                id="ipLocal"
                name="ipLocal"
                label="Source"
                value={formData?.ipLocal}
                fullWidth
                onChange={handleInputChange}
                onBlur={(e) => handleBlur(e.target.value, e.target.name)}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    event.preventDefault();
                    document.getElementById("ipCliente").focus();
                  }
                }}
                error={Boolean(errors?.ipLocal)}
                helperText={errors?.ipLocal}
              />
              <TextField
                autoComplete="off"
                sx={styles.textField}
                variant="filled"
                id="ipCliente"
                name="ipCliente"
                label="Destination"
                value={formData?.ipCliente}
                fullWidth
                onChange={handleInputChange}
                onBlur={(e) => handleBlur(e.target.value, e.target.name)}
                onKeyDown={(event) => {
                  if (event.key === "Enter") {
                    event.preventDefault();
                    document.getElementById("asnVlan").focus();
                  }
                }}
                error={Boolean(errors?.ipCliente)}
                helperText={errors?.ipCliente}
              />
            </Stack>
            <Stack direction={"row"} gap={1} flexWrap={"nowrap"}>
              <TextField
                autoComplete="off"
                sx={styles.asTextField}
                variant="filled"
                id="asnVlan"
                name="asnVlan"
                label="ASN Vlan"
                value={formData?.asnVlan}
                fullWidth
                onChange={handleInputChange}
                onBlur={(e) => handleBlur(e.target.value, e.target.name)}
                error={Boolean(errors?.asnVlan)}
                helperText={errors?.asnVlan}
                disabled={loadingASN}
              />
            </Stack>
          </Box>
          <Stack
            direction={"row"}
            gap={1}
            justifyContent={"flex-end"}
            width={"100%"}
            flexWrap={"nowrap"}
          >
            <Button
              onClick={() => {
                handleClose();
              }}
              disabled={loading}
            >
              Cancelar
            </Button>
            <Button
              variant="contained"
              onClick={handleSubmit}
              disabled={loading}
            >
              Salvar
            </Button>
          </Stack>
        </Box>
      </Paper>
    </Modal>
  );
}
