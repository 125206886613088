import { Add, Delete } from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonGroup,
  IconButton,
  Paper,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import Comunidades from "./comunidades";
import Planos from "./planos";
import InternoModalConfirmacaoSaida from "../../../components/modalSaindoDaPagina";

export default function Configuracoes() {
  const [value, setValue] = useState(0);
  const [changed, setChanged] = useState(false);
  const [openConfirmacao, setOpenConfirmacao] = useState(false);
  const [newTabValue, setNewTabValue] = useState(null);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "21px" }}>
      <Tabs
        value={value}
        onChange={(event, newValue) => {
          if (changed) {
            setNewTabValue(newValue);
            setOpenConfirmacao(true);
          } else {
            setValue(newValue);
          }
        }}
      >
        <Tab label="Comunidades" value={0} />
        <Tab label="Planos" value={1} />
      </Tabs>
      {value === 0 && <Comunidades setChanged={setChanged} />}
      {value === 1 && <Planos setChanged={setChanged} />}
      <InternoModalConfirmacaoSaida
        openConfirmacao={openConfirmacao}
        confirmLoad={false}
        setOpenConfirmacao={setOpenConfirmacao}
        execute={() => {
          // Mudança de aba a partir da confirmação
          setValue(newTabValue);
          setChanged(false);
          setOpenConfirmacao(false);
        }}
        severity={"warning"}
        contentText={
          "As alterações não salvas serão perdidas, deseja continuar?"
        }
        headerText={"Você está saíndo da página."}
        confirmText={"Continuar"}
      />
    </Box>
  );
}
