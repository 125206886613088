import { AddAPhoto, Close, Save } from "@mui/icons-material";
import {
  Modal,
  Box,
  Typography,
  TextField,
  Divider,
  Button,
  Paper,
  Avatar,
  useTheme,
  IconButton,
  Snackbar,
  Alert,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Resizer from "react-image-file-resizer";
import CloseIcon from "@mui/icons-material/Close";
import useStyles from "./styles";
import { useEffect, useState } from "react";
import { z } from "zod";
import ReactInputMask from "react-input-mask";
import api from "../../services/api";
import apiCliente from "../../services/apiCliente";
const profileSchema = z.object({
  nome: z.string().min(1, "Nome obrigatório"),
  sobrenome: z.string().min(1, "Sobrenome obrigatório"),
  telefone: z.string(),
  email: z.string().min(1, "E-mail obrigatório"),
});

const telefoneMovelSchema = z
  .string()
  .trim()
  .regex(/^\(\d{2}\) \d{5}-\d{4}$/, "Telefone inválido");
const telefoneFixoSchema = z
  .string()
  .trim()
  .regex(/^\(\d{2}\) \d{4}-\d{4}$/, "Telefone inválido");
const emailSchema = z.string().email("Email inválido");

export default function ModalPerfil({ open = false, handleClose = () => {} }) {
  const theme = useTheme();
  const styles = useStyles(theme);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    nome: "",
    sobrenome: "",
    nomeFantasia: "",
    asn: "",
    telefone: "",
    email: "",
  });
  const [maskTelefone, setMaskTelefone] = useState("(99) 9999-9999");
  const [foto, setFoto] = useState(
    localStorage.getItem("perfil") &&
      JSON.parse(localStorage.getItem("perfil")) != "undefined"
      ? JSON.parse(localStorage.getItem("perfil"))?.foto
      : ""
  );

  const [alertSize, setAlertSize] = useState(false);
  const [errors, setErrors] = useState({});
  const [editMode, setEditMode] = useState(false);

  const perfil = JSON.parse(localStorage.getItem("perfil"));
  const user = JSON.parse(localStorage.getItem("user"));
  const [snackbar, setSnackbar] = useState(null);
  useEffect(() => {
    if (open) {
      setFormData({
        nome: perfil?.nome || "",
        sobrenome: perfil?.sobrenome || "",
        nomeFantasia: user?.usuarioCliente?.cliente?.nomeFantasia || "",
        asn: user?.usuarioCliente?.cliente?.as || "",
        email: user?.usuarioCliente?.email || "",
        telefone: perfil?.telefone || "",
      });
    }
  }, [open]);

  let countTelefoneReplaced = formData.telefone?.replace(/[^\d.]+/g, "");

  function changeEditMode(params) {
    setEditMode(!editMode);
  }

  function handleInputChange(event) {
    const { name, value } = event.target;
    if (name === "telefone") {
      if (value.replace(/[^\d.]+/g, "").length > 10) {
        setMaskTelefone("(99) 99999-9999");
      } else {
        setMaskTelefone("(99) 9999-99999");
      }
    }

    setFormData((prev) => ({ ...prev, [name]: value }));
    if (errors[name]) setErrors((prev) => ({ ...prev, [name]: "" }));
  }

  async function handlePutClient(data) {
    setLoading(true);

    try {
      const response = await apiCliente.put("/update/perfilCliente", {
        id: perfil?.id,
        foto: foto,
        nome: data.nome,
        sobrenome: data.sobrenome,
        telefone: data.telefone,
        email: data.email,
      });

      localStorage.setItem(
        "perfil",
        JSON.stringify({
          id: perfil?.id,
          foto: foto,
          nome: data.nome,
          sobrenome: data.sobrenome,
          telefone: data.telefone,
          usuarioClienteId: data.usuarioClienteId,
        })
      );
      changeEditMode();
      setErrors({});
      setSnackbar({
        children: "Perfil atualizado com sucesso!",
        severity: "success",
      });
      return response;
    } catch (error) {
      console.log(error);
      const newErrors = {};
      error?.errors?.forEach((err) => {
        newErrors[err.path[0]] = err.message;
      });
      setErrors(newErrors);
      setSnackbar({
        children: "Error: Não foi possivel se conectar ao servidor",
        severity: "error",
      });
    } finally {
      setLoading(false);
    }
  }

  function handleBlur(e) {
    const { name, value } = e.target;
    try {
      if (name === "email") {
        emailSchema.parse(value);
      } else if (name === "telefone") {
        if (countTelefoneReplaced.length === 11) {
          telefoneMovelSchema.parse(value);
        } else {
          telefoneFixoSchema.parse(value);
        }
      }
      setErrors((prev) => ({ ...prev, [name]: "" }));
    } catch (err) {
      setErrors((prev) => ({ ...prev, [name]: err.errors[0].message }));
    }
  }

  function handleSubmit() {
    try {
      profileSchema.parse({
        nome: formData.nome,
        sobrenome: formData.sobrenome,
        email: formData.email,
        telefone: formData.telefone,
      });

      handlePutClient(formData);
      setErrors({});
    } catch (error) {
      console.log(error);
      const newErrors = {};
      error?.errors?.forEach((err) => {
        newErrors[err.path[0]] = err.message;
      });
      setErrors(newErrors);
    }
  }

  return (
    <Modal
      open={open}
      onClose={() => {
        handleClose();
        setEditMode(false);
        setFoto(
          localStorage.getItem("perfil") &&
            JSON.parse(localStorage.getItem("perfil")) != "undefined"
            ? JSON.parse(localStorage.getItem("perfil"))?.foto
            : ""
        );
        setFormData({
          nome: perfil?.nome || "",
          sobrenome: perfil?.sobrenome || "",
          nomeFantasia: user?.usuarioCliente?.cliente?.nomeFantasia || "",
          asn: user?.usuarioCliente?.cliente?.as || "",
          email: user?.usuarioCliente?.email || "",
          telefone: perfil?.telefone || "",
        });
      }}
      sx={styles.modal}
    >
      <Paper
        sx={styles.container}
        elevation={theme.palette.mode === "dark" ? 0 : 3}
      >
        <Box sx={styles.title}>
          <Typography variant="title" color={"text.textContrast"}>
            Meu Perfil
          </Typography>

          <IconButton
            onClick={() => {
              handleClose();
              setEditMode(false);
            }}
            sx={styles.iconButton}
          >
            <CloseIcon />
          </IconButton>
        </Box>
        <Avatar sx={styles.avatar} src={foto || ""} />
        {editMode && (
          <Tooltip title={"adicionar imagem"}>
            <IconButton
              sx={styles.cameraButton}
              component="label"
              color="primary"
            >
              <AddAPhoto sx={styles.cameraIcon} />
              <input
                hidden
                accept=".png, .jpg, .jpeg"
                type="file"
                onChange={(e) => {
                  const file = e?.target?.files[0];
                  if (file?.size < 1024 * 300) {
                    //1024 byte = 1 kbyte
                    // 1 MB
                    Resizer.imageFileResizer(
                      file,
                      500,
                      500,
                      "JPEG",
                      80,
                      0,
                      (uri) => {
                        setFoto(uri);
                      },
                      "base64",
                      500,
                      500
                    );
                    const fileReader = new FileReader();
                    fileReader.readAsDataURL(file);
                    fileReader.onload = () => {};
                    fileReader.onerror = (error) => {
                      console.log("Error:", error);
                    };
                  } else {
                    setAlertSize(true);
                  }
                }}
              />
            </IconButton>
          </Tooltip>
        )}
        <Box sx={styles.content}>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            {editMode ? (
              <>
                <IconButton disabled={loading} onClick={handleSubmit}>
                  <Save color="primary" />
                </IconButton>
                <IconButton
                  onClick={() => {
                    changeEditMode();
                    setFoto(
                      localStorage.getItem("perfil") &&
                        JSON.parse(localStorage.getItem("perfil")) !=
                          "undefined"
                        ? JSON.parse(localStorage.getItem("perfil"))?.foto
                        : ""
                    );
                    setFormData({
                      nome: perfil?.nome || "",
                      sobrenome: perfil?.sobrenome || "",
                      nomeFantasia:
                        user?.usuarioCliente?.cliente?.nomeFantasia || "",
                      asn: user?.usuarioCliente?.cliente?.as || "",
                      email: user?.usuarioCliente?.email || "",
                      telefone: perfil?.telefone || "",
                    });
                  }}
                >
                  <Close color="error" />
                </IconButton>
              </>
            ) : (
              <Button onClick={changeEditMode} color="primary" size="large">
                Editar
              </Button>
            )}
          </Box>
          <Box sx={styles.row}>
            <Box sx={styles.textField}>
              <TextField
                name="nome"
                label="Nome"
                fullWidth
                size="small"
                id="standard-basic"
                variant="filled"
                value={formData.nome}
                onChange={handleInputChange}
                onBlur={handleBlur}
                error={Boolean(errors?.nome)}
                helperText={errors?.nome}
                disabled={!editMode}
              />
            </Box>
            <Box sx={styles.textField}>
              <TextField
                name="sobrenome"
                label="Sobrenome"
                fullWidth
                size="small"
                id="standard-basic"
                variant="filled"
                value={formData.sobrenome}
                onChange={handleInputChange}
                onBlur={handleBlur}
                error={Boolean(errors?.sobrenome)}
                helperText={errors?.sobrenome}
                disabled={!editMode}
              />
            </Box>
          </Box>

          <Divider />

          <Box sx={styles.row}>
            <Box sx={styles.textField}>
              <TextField
                fullWidth
                size="small"
                id="standard-basic"
                variant="filled"
                label="Nome Fantasia"
                value={formData.nomeFantasia}
                disabled={true}
              />
            </Box>
            <Box sx={styles.textField}>
              <TextField
                fullWidth
                size="small"
                id="standard-basic"
                variant="filled"
                label="Asn"
                value={formData.asn}
                disabled={true}
              />
            </Box>
          </Box>
          <Divider />

          <Box sx={styles.row}>
            <Box sx={styles.textField}>
              {editMode ? (
                <ReactInputMask
                  name="telefone"
                  mask={maskTelefone}
                  value={formData.telefone}
                  onChange={handleInputChange}
                  maskChar=" "
                  size="small"
                  onBlur={handleBlur}
                  error={Boolean(errors?.telefone)}
                  helperText={errors?.telefone}
                >
                  {(props) => (
                    <TextField
                      fullWidth
                      id="standard-basic"
                      variant="filled"
                      label="Telefone"
                      {...props}
                    />
                  )}
                </ReactInputMask>
              ) : (
                <TextField
                  fullWidth
                  name="telefone"
                  id="standard-basic"
                  variant="filled"
                  label="Telefone"
                  value={formData.telefone}
                  disabled={true}
                />
              )}
            </Box>
            <Box sx={styles.textField}>
              {editMode ? (
                <TextField
                  name="email"
                  fullWidth
                  size="small"
                  id="standard-basic"
                  variant="filled"
                  label="Email"
                  onChange={handleInputChange}
                  value={formData.email}
                  onBlur={handleBlur}
                  error={Boolean(errors?.email)}
                  helperText={errors?.email}
                />
              ) : (
                <TextField
                  name="email"
                  fullWidth
                  size="small"
                  id="standard-basic"
                  variant="filled"
                  label="Email"
                  onChange={handleInputChange}
                  value={formData.email}
                  onBlur={handleBlur}
                  error={Boolean(errors?.email)}
                  helperText={errors?.email}
                  disabled={true}
                />
              )}
            </Box>
          </Box>
          <Snackbar
            autoHideDuration={3000}
            open={alertSize}
            onClose={() => {
              setAlertSize(false);
            }}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          >
            <Alert severity="error">Tamanho máximo permitido: 300KB</Alert>
          </Snackbar>
          {!!snackbar && (
            <Snackbar
              open
              onClose={() => setSnackbar(null)}
              autoHideDuration={3000}
              anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            >
              <Alert {...snackbar} onClose={() => setSnackbar(null)} />
            </Snackbar>
          )}
        </Box>
      </Paper>
    </Modal>
  );
}
