const styles = (theme) => ({
  container: {
    padding: "1vw",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    gap: "40px",
    position: "relative",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
    padding: "24px 24px 26px",
    bgcolor: theme.palette.mode === "dark" ? "transparent" : "none",
    gap: "16px",
    borderRadius: "10px",
    width: "310px",
    position: "sticky",
    top: 130,
    height: "80vh",
    minHeight: "420px",
  },
  avatar: { width: "64px", height: "64px" },

  Icon: {
    fontSize: "26px",
  },

  configBadge: {
    fontSize: "18px",
    position: "absolute",
    bottom: 4,
    right: -8,
  },
  selectedList: {
    color: theme.palette.secondary.main,
    "&.Mui-selected": {
      backgroundColor: "transparent",
    },
  },
  listIconWanguard: { fontSize: "32px", ml: -0.5 },
});

export default styles;
