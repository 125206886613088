import { Box, Paper, Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import { useOutletContext } from "react-router-dom";
import Prefixos from "./prefixos";
import Tunnel from "./tunnel";
import Vlan from "./vlan";
import Outros from "./outros";
import Sessoes from "./sessoes";
import GerarConfig from "./gerarConfig";
import Solicitacoes from "./solicitacoes";
import InternoModalConfirmacaoSaida from "../../../../components/modalSaindoDaPagina";

export default function JuniperConfig() {
  const [setSnackbar, clienteInfo] = useOutletContext();
  const [value, setValue] = useState(0);
  const [changed, setChanged] = useState(false);
  const [openConfirmacao, setOpenConfirmacao] = useState(false);
  const [newTabValue, setNewTabValue] = useState(null);

  useEffect(() => {
    // confirmation ao fechar ou atualizar o site
    if (window.location.pathname.includes("/interno/home/perfil/juniper")) {
      const unloadCallback = (event) => {
        event.preventDefault(); // necessario pro trigger
        event.returnValue = "";
        return "";
      };
      window.addEventListener("beforeunload", unloadCallback);

      return () => {
        //   window.addEventListener("popstate", confirm());
        window.removeEventListener("beforeunload", unloadCallback);
      };
    }
  }, []);
  //^(.*)\/[^\/]*$

  //Change tabs handler
  const handleChange = (event, newValue) => {
    if (changed) {
      setNewTabValue(newValue);
      setOpenConfirmacao(true);
    } else {
      setValue(newValue);
    }
  };

  function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && <Box sx={{ p: 0 }}>{children}</Box>}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <Box
      sx={{ display: "flex", flexDirection: "column", gap: 3, width: "76%" }}
    >
      <Box>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basics tabs config"
        >
          <Tab label="CONFIGURAR" {...a11yProps(0)} sx={{ fontSize: "14px" }} />
          <Tab label="PREFIXOS" {...a11yProps(1)} sx={{ fontSize: "14px" }} />
          <Tab
            label="SOLICITAÇÕES"
            {...a11yProps(2)}
            sx={{ fontSize: "14px" }}
          />
          <Tab label="TÚNEL" {...a11yProps(3)} sx={{ fontSize: "14px" }} />
          <Tab label="SESSÕES" {...a11yProps(4)} sx={{ fontSize: "14px" }} />
          <Tab label="VLAN" {...a11yProps(5)} sx={{ fontSize: "14px" }} />
          <Tab label="OUTROS" {...a11yProps(6)} sx={{ fontSize: "14px" }} />
        </Tabs>
      </Box>

      {value === 0 && <GerarConfig />}
      {value === 1 && (
        <Prefixos
          setSnackbar={setSnackbar}
          changed={changed}
          setChanged={setChanged}
          clienteInfo={clienteInfo}
        />
      )}
      {value === 2 && <Solicitacoes />}
      {value === 3 && (
        <Tunnel clienteInfo={clienteInfo} setSnackbar={setSnackbar} />
      )}
      {value === 4 && <Sessoes clienteInfo={clienteInfo} />}
      {value === 5 && (
        <Vlan setSnackbar={setSnackbar} clienteInfo={clienteInfo} />
      )}
      {value === 6 && (
        <Outros
          clienteInfo={clienteInfo}
          setSnackbar={setSnackbar}
          changed={changed}
          setChanged={setChanged}
        />
      )}
      <InternoModalConfirmacaoSaida
        openConfirmacao={openConfirmacao}
        confirmLoad={false}
        setOpenConfirmacao={setOpenConfirmacao}
        execute={() => {
          // Mudança de aba a partir da confirmação
          setValue(newTabValue);
          setChanged(false);
          setOpenConfirmacao(false);
        }}
        severity={"warning"}
        contentText={
          "As alterações não salvas serão perdidas, deseja continuar?"
        }
        headerText={"Você está saíndo da página."}
        confirmText={"Continuar"}
      />
    </Box>
  );
}
